import {
  Header,
  ManageRoles,
  ManageSponsors,
  SideMenu,
} from "../../Components";
import ManageUser from "../../Components/Management/ManageUsers/ManageUsers";
import { useParams } from 'react-router-dom';
import * as Styles from "./UserManagementStyles";

function UserManagement() {
  
  const { pageName } = useParams();

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          {pageName === "sponsors" && <ManageSponsors />}
          {pageName === "roles" && <ManageRoles />}
          {pageName === "users" && <ManageUser />}
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default UserManagement;