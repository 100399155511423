import { Button, Drawer, Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { createProduct } from "../../../slices/Product/Helpers/CreateProduct";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { product } from "../../../API/Masters/Product/Product";
import { getAllIngredient } from "../../../slices/ActiveIngredient/Helpers/getAllIngredients";
import { getAllCountries } from "../../../slices/ControlledVocabularies/Helpers/getAllCountries";
import { getAllSponsors } from "../../../slices/Sponsors/Helpers/GetAllSponsors";
import { getAllDosageForms } from "../../../slices/ControlledVocabularies/Helpers/getAllDosageForms";
import { getAllUnits } from "../../../slices/ControlledVocabularies/Helpers/getAllUnits";
import { getAllRouteOfAdministration } from "../../../slices/ControlledVocabularies/Helpers/getAllRouteOfAdministartion";
import { GetAllProducts } from "../../../slices/Product/Helpers/GetAllProducts";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../../unity-ui-common/Components/ReFormFields/ReSelect";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function ProductDrawer(props: {
  visibility: boolean;
  setVisibility: Function;
  drawerType: string;
  currEditingProduct: any;
  setCurrEditingProduct: Function;
}) {
  const { visibility, setVisibility, drawerType, currEditingProduct } = props;

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { allCountries, allUnits, allDosageForm, allRouteOfAdministration } =
    useAppSelector((store) => store.controlledVocabularies);
  const { allIngredient } = useAppSelector((store) => store.ingredient);
  const { allSponsors } = useAppSelector((store) => store.sponsor);
  const [switchStatus, setSwitchStatus] = useState(1);
  
  const handleDrawerClose = () => {
    setVisibility({
      visibility: false,
      type: "create",
    });
    form.resetFields();
  };

  const handleFormSubmitClicks = async (values: any) => {
    let payload: any = {
      name: values.name,
      activeIngredients: values.activeIngredients,
      activeIngredientAliases: values.activeIngredientAliases,
      countries: values.countries,
      dosageForm: values.dosageForm,
      routeOfAdministration: values.routeOfAdministration,
      strength: values.strength,
      unitOfMeasurement: values.unitOfMeasurement,
      status: switchStatus,
    };
    if (drawerType === "create") {
      payload.sponsor = values.sponsor;
      await dispatch(createProduct(payload));
      ReNotification({
        description: "Product Created Successfully",
        type: "success",
      });
    } else if (drawerType === "edit") {
      await product.update(currEditingProduct.uniqueID, payload);
      ReNotification({
        description: "Product Updated Successfully",
        type: "success",
      });
    }
    dispatch(GetAllProducts());
    handleDrawerClose();
  };

  useEffect(() => {
    if (drawerType === "edit") {
      form.setFieldsValue({
        name: currEditingProduct.name,
        activeIngredients: currEditingProduct.activeIngredients,
        activeIngredientAliases: currEditingProduct.activeIngredientAliases,
        countries: currEditingProduct.countries,
        sponsor: currEditingProduct.sponsor,
        dosageForm: currEditingProduct.dosageForm,
        routeOfAdministration: currEditingProduct.routeOfAdministration,
        strength: currEditingProduct.strength,
        unitOfMeasurement: currEditingProduct.unitOfMeasurement,
      });
      setSwitchStatus(currEditingProduct?.status);
    }
  }, [drawerType]);

  useEffect(() => {
    dispatch(getAllIngredient());
    dispatch(getAllCountries());
    dispatch(getAllSponsors());
    dispatch(getAllDosageForms());
    dispatch(getAllUnits());
    dispatch(getAllRouteOfAdministration());
  }, []);

  return (
    <div>
      <Drawer
        placement="right"
        onClose={handleDrawerClose}
        open={visibility}
        width={"500"}
        title={drawerType === "edit" ? "Edit Product" : "Create Product"}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmitClicks}
          submitBtn
          className="jsonForm"
          submitBtnText={drawerType === "edit" ? "Update" : "Submit"}
        >
          <ReInput label="Name" name="name" type="simple" required />
          <ReSelect
            label="Active Ingredient"
            name="activeIngredients"
            type="multiple"
            items={allIngredient?.map((data) => {
              return {
                title: data.name,
                value: data.name,
                disable: data.status === 0 || false,
              };
            })}
            searchable
          />
          <ReInput
            label="Active Ingredient Aliases"
            name="activeIngredientAliases"
            type="simple"
          />
          <ReSelect
            label="Country of Authorization"
            name="countries"
            type="multiple"
            items={allCountries?.map((data) => {
              return { title: data.name, value: data.name };
            })}
            required
            searchable
          />
          <ReSelect
            label="Dosage Form"
            name="dosageForm"
            items={allDosageForm?.map((data) => {
              return {
                value: data.name,
                title: data.name,
              };
            })}
            required
            searchable
          />
          <ReSelect
            label="Route of Administration"
            name="routeOfAdministration"
            items={allRouteOfAdministration?.map((data) => {
              return {
                value: data.label,
                title: data.label,
              };
            })}
            required
            searchable
          />
          <ReInput label="Strength" name="strength" type="number" required />
          <ReSelect
            label="Unit of Measurement"
            name="unitOfMeasurement"
            items={allUnits?.map((data) => {
              return {
                value: data.label,
                title: data.label,
              };
            })}
            required
            searchable
          />
          <ReSelect
            label="Sponsor/MAH Name"
            name="sponsor"
            items={allSponsors?.map((data) => {
              return {
                value: data.name,
                title: data.name,
              };
            })}
            required
            searchable
          />
          <Form.Item label="Status" rules={[{ required: true }]}>
            <Switch
              checked={switchStatus === 1}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => {
                setSwitchStatus(checked ? 1 : 0);
              }}
            />
          </Form.Item>
        </ReusableForm>
        <Form
          className="jsonForm"
        >
          <Button type="primary" htmlType="submit"></Button>
        </Form>
      </Drawer>
    </div>
  );
}

export default ProductDrawer;
