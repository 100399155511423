import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Header, ProductDrawer, SideMenu } from "../../../Components";
import * as Styles from "./ProductStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../../../Components/ControlledVocabularies/common";
import { API_URL } from "../../../API/Config";

function ProductManagement() {
  const [currEditingProduct, setCurrEditingProduct] = useState({});
  const [productDrawer, setProductDrawer] = useState({
    visibility: false,
    type: "",
  });

  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(0);

  const ProductTableColumns = [
    {
      title: "",
      width: 40,
      render: (record: any) => {
        return (
          <Tooltip title="Edit Product">
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrEditingProduct(record);
                setProductDrawer({
                  visibility: true,
                  type: "edit",
                });
              }}
            />
          </Tooltip>
        );
      },
    },

    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sponsor/MAH Name",
      dataIndex: "sponsor",
      key: "sponsor",
    },
    {
      title: "Active Ingredient",
      dataIndex: "activeIngredients",
      key: "activeIngredients",
    },

    {
      title: "Route of Administration",
      dataIndex: "routeOfAdministration",
      key: "routeOfAdministration",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (productDrawer.visibility === false) {
      setFlag(prevValue=>!prevValue);
    }
  }, [productDrawer]);

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ProductDrawer
            visibility={productDrawer.visibility}
            setVisibility={setProductDrawer}
            drawerType={productDrawer.type}
            currEditingProduct={currEditingProduct}
            setCurrEditingProduct={setCurrEditingProduct}
          />
          <ReTable
            refresh={flag}
            name="productDictionary"
            title="Product Dictionary"
            columns={ProductTableColumns}
            scroll={{
              x: 800,
              y: 500,
            }}
            rowSelection={{
              selectedRowKeys: selectedTableRow,
              type: "checkbox",
              setSelectedRowKeys: setSelectedTableRow,
              rowKey: "id",
            }}
            columnOptions={{
              sorting: {
                columns: [
                  "name",
                  "sponsor",
                  "activeIngredients",
                  "routeOfAdministration",
                ],
              },
              filter: {
                columns: [
                  "name",
                  "sponsor",
                  "activeIngredients",
                  "routeOfAdministration",
                ],
              },
            }}
            headerButtons={[
              {
                title: "Add New",
                type: "primary",
                icon: <PlusOutlined />,
                onClick: () => {
                  setProductDrawer({
                    visibility: true,
                    type: "create",
                  });
                },
              },
            ]}
            uniView={{
              uniView: "masterData",
              viewName: "product",
              apiUrl: API_URL,
              option: {
                orderBy: {
                  key: "name",
                  order: "ASC",
                },
                limit: 10
              },
            }}
            exportOption={{
              csv: {},
            }}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default ProductManagement;
