import { useEffect } from "react";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";

interface IModalBody {
  response: any;
  userList: any;
}

function WorkAllocationConfirmationModalBody(props: IModalBody) {
  const { response, userList } = props;

  const tableTitleMapper: any = {
    workflowOneUnAssigned: "Workflow One",
    workflowTwoUnAssigned: "Workflow Two",
    qcUnAssigned: "Quality Check"
  };

  const columnsData = [
    {
      title: "User Name",
      dataIndex: "id",
      key: "id",
      render: (userId: string) => {
        return userList?.[userId] || userId;
      }
    },
    {
      title: "Assigned Records",
      dataIndex: "records",
      key: "records",
      render: (records: string[]) => {
        return records.join(", ");
      }
    },
  ];

  return (
    <>
      {response?.map((wfAssignmentData: any) => {
        if (wfAssignmentData && wfAssignmentData?.data?.length) {
          return (
            <>
              <ReTable
                columns={columnsData}
                data={wfAssignmentData?.data}
                name={tableTitleMapper?.[wfAssignmentData.viewName]}
                title={tableTitleMapper?.[wfAssignmentData.viewName]}
              />
              <br />
            </>
          )
        }
        else {
          return (<></>);
        }

      })}
    </>
  )


}

export default WorkAllocationConfirmationModalBody