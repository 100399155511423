import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    margin-top: 18rem;
    img {
        width: 10rem;
    }
    .des {
        text-align: center;
    }
`;

export const SubContainer = styled.div`
  padding: 10px;
  background-color:#f5f5f5;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 10rem;
  }
`;