import { useState, useEffect } from "react";
import * as Styles from "./DashboardStyles";
import { Header, SideMenu } from "../../Components";
import ReEChart from "../../unity-ui-common/Components/ReEChart/ReEChart";
import { Avatar, Card, Skeleton, Space } from "antd";
import { importEngine } from "../../API/ImportEngine";
import { colors, datePickerDropDownItems } from "./Constant";
import { convertDateInReadableFormat } from "../../Healper/Methods";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { auth } from "../../API/Auth";
import {
  FileDoneOutlined,
  FileExclamationOutlined,
  FileTextOutlined,
  SnippetsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "../../app/hooks";
import { getAllCategorization } from "../../slices/Categorization/Helpers/getAllCategorization";
import { SecureDataService } from "../../unity-ui-common/Classes/SecureData";
import ReDatePickerDropDown from "../../unity-ui-common/Components/ReFormFields/ReDatePickerDropDown";

function Dashboard() {
  const navigate = useNavigate();
  // const { userPermissions } = useAppSelector((store) => store.user);
  const { titleToValueMapper } = useAppSelector(
    (store) => store.categorization
  );
  const { userPermissions } = useAppSelector((store) => store.session);
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);
  const [tilesData, setTilesData] = useState<any>({});
  const [importStatusData, setImportStatusData] = useState<any>({});
  const [importBySourceData, setImportBySourceData] = useState<any>({});
  const [workStatusData, setWorkStatusData] = useState<any>({});
  const [assignmentStatusData, setAssignmentStatusData] = useState<any>({});
  const [cateAssignmentStatusData, setCateAssignmentStatusData] = useState<any>(
    {}
  );
  const [workflowCompletionStatusData, setWorkflowCompletionStatusData] =
    useState<any>({});
  const [workflowUserStatus, setWorkflowUserStatus] = useState<any>({});
  const [cateWorkflowStatus, setCateWorkflowStatus] = useState<any>({});
  const [assignmentByUserData, setAssignmentByUserData] = useState<any>({});
  const [assignmentByWorkflowData, setAssignmentByWorkflowData] = useState<any>(
    {}
  );
  const [sourceSpecificData, setSourceSpecificData] = useState<any>({});
  const [workflowSourceSpecificData, setWorkflowSourceSpecificData] =
    useState<any>({});
  const [selectedDate, setSelectedDate] = useState<any>([
    moment(new Date()).subtract(1, "month").date(new Date().getDate()),
    moment(new Date()),
  ]);
  const [selectedDateFilterOption, setSelectedDateFilterOption] =
    useState<string>("lastMonth");
  const [userToIdMapping, setUserToIdMapping] = useState<any>({});

  const getDashboardData = async () => {
    setDashboardLoading(true);
    let payload;
    payload = {
      startDate: moment(selectedDate[0]._d).format('YYYY-MM-DD') + 'T00:00:00.000Z',
      endDate: moment(selectedDate[1]._d).format('YYYY-MM-DD') + 'T23:59:59.999Z',
      queryStartDate: moment(selectedDate[0]._d).format('YYYY-MM-DD'),
      queryEndDate: moment(selectedDate[1]._d).format('YYYY-MM-DD'),
      rangeType: selectedDateFilterOption,
    };

    const res = await importEngine.getDashboardData(payload);
    if (res.status) {
      const { data } = res;
      const { importStatus, allocation, workflow, categorization } = data;
      setTilesData(data?.dashboardTiles);

      setImportStatusData(importStatus?.importStatus);
      setImportBySourceData(importStatus?.importsBySource);

      setAssignmentByUserData(allocation?.assignmentUser);
      setAssignmentStatusData(allocation?.assignmentStatusAssignedVsUnassigned);
      setAssignmentByWorkflowData(allocation?.assignmentWorkflow);
      setUserToIdMapping(allocation?.assignmentUser?.mapping);

      setWorkStatusData(workflow?.workStatus);
      setWorkflowSourceSpecificData(workflow?.sourceSpecificWorkflow);
      setWorkflowCompletionStatusData(workflow?.workflowCompletionStatus);

      setCateAssignmentStatusData(
        categorization?.assignmentStatusProcessedVsPending
      );
      setSourceSpecificData(categorization?.sourceSpecific);
      setCateWorkflowStatus(categorization?.workFlowStatus);
    }
    setDashboardLoading(false);

    const userStatusDataRes =
      await importEngine.getDashboardUserAssignnmentChartData(payload);
    if (userStatusDataRes.status) {
      setWorkflowUserStatus(userStatusDataRes?.data?.userStatus);
    }
  };

  const statCardData = [
    {
      key: 0,
      icon: <FileTextOutlined />,
      number: tilesData?.caseCount,
      label: "All Records",
      path: "/imports/all-records",
    },
    {
      key: 1,
      icon: <FileExclamationOutlined />,
      number: tilesData?.unassignedCount,
      label: "Unassigned Records",
      path: "/imports/all-records",
    },
    {
      key: 2,
      icon: <FileDoneOutlined />,
      number: tilesData?.assignedCount,
      label: "Assigned Records",
      path: "/imports/all-records",
    },
    {
      key: 3,
      icon: <UserOutlined />,
      number: tilesData?.assignedToMECount,
      label: "My Work",
      path: "/imports/my-work",
    },
    {
      key: 4,
      icon: <SnippetsOutlined />,
      number: tilesData?.duplicateCount,
      label: "Total Duplicates",
      path: "/imports/all-records",
    },
  ];

  const handleTilesClicks = (card: any) => {
    const { key, path } = card;
    let filterQuery: any = {};

    switch (key) {
      case 1:
        filterQuery["stage"] = [null, 0, 1, 3];
        filterQuery["isAssigned"] = [false];
        break;
      case 2:
        filterQuery["isAssigned"] = [true];
        break;
      case 4:
        filterQuery["duplicate"] = [1, 2];
        break;
      default:
        break;
    }

    filterQuery["createdAt"] = {
      ">": moment(selectedDate[0]._d).format('YYYY-MM-DD') + 'T00:00:00.000Z',
      "<": moment(selectedDate[1]._d).format('YYYY-MM-DD') + 'T23:59:59.999Z',
    };
    const secureDataService = new SecureDataService();
    // console.log(filterQuery)
    const chartFilterData = secureDataService.encrypt({ filters: filterQuery });
    const endPoint = `/imports/${key === 3 ? "my-work?mywork" : "all-records?allrecords"}PaginationData=${chartFilterData}`;
    navigate(endPoint);
  };

  const getImportChartsFilterQuery = (chartName: string, params: any) => {
    const { dataIndex, seriesName, name } = params;
    let filter: any = {};
    switch (chartName) {
      case "importStatus":
        if (dataIndex === 1) {
          filter["duplicate"] = [1];
        } else if (dataIndex === 2) {
          filter["duplicate"] = [2];
        } else {
          filter = {};
        }
        break;
      case "importBySource":
        let query = {
          "=": "",
        };
        switch (seriesName) {
          case "Embase":
            query["="] = "embase";
            break;
          case "Pubmed":
            query["="] = "pubmed";
            break;
          case "Local Literature":
            query["="] = "localLiterature";
            break;
          default:
            break;
        }
        filter["source"] = query;
        console.log(name)
        filter.createdAt = {
          ">": moment(name).format('YYYY-MM-DD') + 'T00:00:00.000Z',
          "<": moment(name).format('YYYY-MM-DD') + 'T23:59:59.999Z'
        };
        break;
      default:
        break;
    }
    return filter;
  };
  const getWorkAllocationChartsFilterQuery = (
    chartName: string,
    params: any
  ) => {
    const { dataIndex, seriesName, name, seriesIndex } = params;
    let filter: any = {};
    switch (chartName) {
      case "assignmentStatus":
        if (dataIndex === 0) {
          filter["isAssigned"] = [true];
        } else if (dataIndex === 1) {
          filter["stage"] = [null, 0, 1, 3];
          filter["isAssigned"] = [false];
        }
        break;
      case "assignmentByWorkflow":
        const assignMapping: any = {
          Assigned: true,
          Unassigned: false,
        };
        filter["isAssigned"] = [assignMapping?.[seriesName]];
        if (dataIndex === 0) {
          filter["stage"] = seriesName === "Assigned" ? [0] : [null];
        } else if (dataIndex === 1) {
          filter["stage"] = [1];
        } else {
          filter["stage"] = [3];
        }
        break;
      case "assignmentByUser":
        if (seriesIndex === 1) {
          filter["duplicate"] = [1, 2];
        }
        filter["assignedUser"] = [userToIdMapping[name]];
        filter["isAssigned"] = [true];
        break;
      default:
        break;
    }
    return filter;
  };
  const getWorkflowManagementChartsFilterQuery = (
    chartName: string,
    params: any
  ) => {
    const { dataIndex, seriesName } = params;

    let filter: any = {};
    const sourceMapping: any = {
      Embase: "embase",
      PubMed: "pubmed",
      "Local Literature": "localLiterature",
    };
    const stageMapping: any = {
      0: "0",
      1: "1",
      2: "3",
    };
    switch (chartName) {
      case "workflowVsSource":
        filter["source"] = [sourceMapping?.[seriesName]];
        filter["stage"] = [stageMapping?.[dataIndex]];
        break;
      default:
        break;
    }
    return filter;
  };
  const getCategorizationChartsFilterQuery = (
    chartName: string,
    params: any
  ) => {
    const { dataIndex, name, seriesIndex } = params;
    let filter: any = {};

    const sourceMapping: any = {
      Embase: "embase",
      PubMed: "pubmed",
      "Local Literature": "localLiterature",
    };
    switch (chartName) {
      case "assignmentStatus":
        if (dataIndex === 1) {
          filter["stage"] = [null, 0, 1, 3];
        } else {
          filter["stage"] = [2];
        }
        break;
      case "categorisationStatus":
        filter["categorization"] = [titleToValueMapper[name]];
        break;
      case "sourceSpecificStatus":
        if (seriesIndex === 0) {
          filter["stage"] = [2];
        } else {
          filter["stage"] = [null, 0, 1, 3];
        }
        filter["source"] = [sourceMapping?.[name]];
        break;
      default:
        break;
    }
    return filter;
  };
  const handleChartsClicks = (
    params: any,
    section:
      | "import"
      | "workAllocation"
      | "workflowManagement"
      | "categorization",
    chartName: string
  ) => {
    let filterQuery: any = {};
    let temp: any;
    filterQuery["createdAt"] = {
      ">": moment(selectedDate[0]._d).format('YYYY-MM-DD') + 'T00:00:00.000Z',
      "<": moment(selectedDate[1]._d).format('YYYY-MM-DD') + 'T23:59:59.999Z',
    };

    switch (section) {
      case "import":
        temp = getImportChartsFilterQuery(chartName, params);
        break;
      case "workAllocation":
        temp = getWorkAllocationChartsFilterQuery(chartName, params);
        break;
      case "workflowManagement":
        temp = getWorkflowManagementChartsFilterQuery(chartName, params);
        break;
      case "categorization":
        temp = getCategorizationChartsFilterQuery(chartName, params);
        break;
      default:
        break;
    }

    if (chartName === "importBySource" && section === "import") {
      filterQuery = {
        ...filterQuery,
        ...temp,
      };
    } else {
      filterQuery = {
        ...temp,
        ...filterQuery,
      };
    }
    const secureDataService = new SecureDataService();
    // console.log(filterQuery)
    const chartFilterData = secureDataService.encrypt({ filters: filterQuery });
    const endPoint = `/imports/all-records?allrecordsPaginationData=${chartFilterData}`;
    navigate(endPoint);
  };

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      navigate("/login");
    }
    getDashboardData();
  }, [selectedDate]);

  useEffect(() => {
    getAllCategorization();
  }, []);

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <div className="filters">
            <ReDatePickerDropDown
              date={selectedDate}
              setDate={setSelectedDate}
              selectedOption={selectedDateFilterOption}
              setSelectedOption={setSelectedDateFilterOption}
              dropDownItems={datePickerDropDownItems}
              defaultValue="month_-1"
            />
          </div>
          <Styles.StatsCards>
            {statCardData.map((card) => {
              const { icon, label, number, key } = card;
              return (
                <Card
                  key={key}
                  onClick={() => {
                    handleTilesClicks(card);
                  }}
                  bodyStyle={{
                    cursor: "pointer",
                    padding: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    borderLeft: `5px solid ${colors[Math.floor(Math.random() * colors.length)]
                      }`,
                  }}
                >
                  {dashboardLoading ? (
                    <>
                      <Skeleton.Avatar size={55} active />
                      <Space direction="vertical">
                        <Skeleton.Button
                          active
                          style={{ width: "5rem", height: "15px" }}
                        />
                        <Skeleton.Button
                          active
                          style={{
                            width: "8rem",
                            height: "18px",
                          }}
                        />
                      </Space>
                    </>
                  ) : (
                    <>
                      <Avatar size={50} icon={icon} />
                      <div>
                        <h3>{number || 0}</h3>
                        <p style={{ margin: 0 }}>{label}</p>
                      </div>
                    </>
                  )}
                </Card>
              );
            })}
          </Styles.StatsCards>
          <Styles.Charts>
            {userPermissions?.includes("0") && (
              <div className="import section">
                <h2>Import</h2>
                <div className="twoColumn">
                  <ReEChart
                    config={{
                      type: "doughnut",
                      title: {
                        text: "Import Status",
                      },
                      legend: {
                        orient: "vertical",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      name: "Import Status",
                      data: importStatusData?.data || [],
                    }}
                    loading={dashboardLoading}
                    events={{
                      click: (params: any) => {
                        handleChartsClicks(params, "import", "importStatus");
                      },
                    }}
                  />
                  {/* import by source */}
                  <ReEChart
                    config={{
                      type: "stackLine",
                      title: {
                        text: "Import By Source",
                      },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: importBySourceData?.labels || [],
                        data: importBySourceData?.data || [],
                      },
                    }}
                    events={{
                      click: (params: any) => {
                        handleChartsClicks(params, "import", "importBySource");
                      },
                    }}
                    loading={dashboardLoading}
                  />
                </div>
              </div>
            )}
            {userPermissions?.includes("6") && (
              <div className="allocation section">
                <h2>Work Allocation</h2>
                <div className="twoColumn">
                  <ReEChart
                    config={{
                      type: "doughnut",
                      title: {
                        text: "Assignment Status",
                      },
                      legend: {
                        orient: "vertical",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      name: "Assignment Status",
                      data: assignmentStatusData?.data || [],
                    }}
                    loading={dashboardLoading}
                    events={{
                      click: (params: any) => {
                        handleChartsClicks(
                          params,
                          "workAllocation",
                          "assignmentStatus"
                        );
                      },
                    }}
                  />
                  {/* assignment by user */}
                  <ReEChart
                    config={{
                      type: "VStackBar",
                      title: {
                        text: "Assignment by User",
                      },
                      xAxis: { rotate: 16 },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: assignmentByUserData?.labels || [],
                        data: assignmentByUserData?.data || [],
                      },
                    }}
                    loading={dashboardLoading}
                    events={{
                      click: (params: any) => {
                        handleChartsClicks(
                          params,
                          "workAllocation",
                          "assignmentByUser"
                        );
                      },
                    }}
                  />
                  {/* assignment by workflow */}
                  <ReEChart
                    config={{
                      type: "VStackBar",
                      title: {
                        text: "Assignment by Workflow",
                      },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: assignmentByWorkflowData?.labels || [],
                        data: assignmentByWorkflowData?.data || [],
                      },
                    }}
                    loading={dashboardLoading}
                    events={{
                      click: (params: any) => {
                        handleChartsClicks(
                          params,
                          "workAllocation",
                          "assignmentByWorkflow"
                        );
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {userPermissions?.includes("2") && (
              <div className="workflow section">
                <h2>Workflow Management</h2>
                <div className="twoColumn">
                  {/* workflow status */}
                  <ReEChart
                    config={{
                      type: "VBar",
                      title: {
                        text: "Workflow Status",
                      },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: cateWorkflowStatus?.labels || [],
                        data: cateWorkflowStatus?.data || [],
                      },
                    }}
                    loading={dashboardLoading}
                  />
                  {/* user status */}
                  <ReEChart
                    config={{
                      type: "VStackBar",
                      title: {
                        text: "User Status",
                      },
                      xAxis: { rotate: 16 },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: workflowUserStatus?.labels || [],
                        data: workflowUserStatus?.data || [],
                      },
                    }}
                    loading={dashboardLoading}
                  />
                  {/* source-specific status */}
                  <ReEChart
                    config={{
                      type: "VStackBar",
                      title: {
                        text: "Workflow vs Source",
                      },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: workflowSourceSpecificData?.labels || [],
                        data: workflowSourceSpecificData?.data || [],
                      },
                    }}
                    loading={dashboardLoading}
                    events={{
                      click: (params: any) => {
                        handleChartsClicks(
                          params,
                          "workflowManagement",
                          "workflowVsSource"
                        );
                      },
                    }}
                  />
                  {/* Workflow Completion Status */}
                  <ReEChart
                    config={{
                      type: "VStackBar",
                      title: {
                        text: "Workflow Completion Status",
                      },
                      legend: {
                        orient: "horizontal",
                        align: "left",
                        right: 0,
                      },
                      toolTip: true,
                      data: {
                        labels: workflowCompletionStatusData?.labels || [],
                        data: workflowCompletionStatusData?.data || [],
                      },
                    }}
                    loading={dashboardLoading}
                  />
                </div>
              </div>
            )}
            {userPermissions?.some((permission) =>
              ["7", "8", "9"].includes(permission)
            ) && (
                <div className="Categorization section">
                  <h2>Categorization</h2>
                  <div className="twoColumn">
                    {/* assignment status */}
                    <ReEChart
                      config={{
                        type: "pie",
                        title: {
                          text: "Assignment Status",
                        },
                        legend: {
                          orient: "vertical",
                          align: "left",
                          right: 0,
                        },
                        toolTip: true,
                        name: "Assignment Status",
                        data: cateAssignmentStatusData?.data || [],
                      }}
                      loading={dashboardLoading}
                      events={{
                        click: (params: any) => {
                          handleChartsClicks(
                            params,
                            "categorization",
                            "assignmentStatus"
                          );
                        },
                      }}
                    />
                    {/* categoisation status */}
                    <ReEChart
                      config={{
                        type: "VStackBar",
                        title: {
                          text: "Categorisation Status",
                        },
                        xAxis: { rotate: 16 },
                        legend: {
                          orient: "horizontal",
                          align: "left",
                          right: 0,
                        },
                        toolTip: true,
                        data: {
                          labels: workStatusData?.labels || [],
                          data: workStatusData?.data || [],
                        },
                      }}
                      loading={dashboardLoading}
                      events={{
                        click: (params: any) => {
                          handleChartsClicks(
                            params,
                            "categorization",
                            "categorisationStatus"
                          );
                        },
                      }}
                    />
                    {/* Source-specific Status */}
                    <ReEChart
                      config={{
                        type: "VStackBar",
                        title: {
                          text: "Source Specific Status",
                        },
                        legend: {
                          orient: "horizontal",
                          align: "left",
                          right: 0,
                        },
                        toolTip: true,
                        data: {
                          labels: sourceSpecificData?.labels || [],
                          data: sourceSpecificData?.data || [],
                        },
                      }}
                      loading={dashboardLoading}
                      events={{
                        click: (params: any) => {
                          handleChartsClicks(
                            params,
                            "categorization",
                            "sourceSpecificStatus"
                          );
                        },
                      }}
                    />
                  </div>
                </div>
              )}
          </Styles.Charts>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default Dashboard;
