import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  /* box-shadow: 0px 1px 4px -1px grey; */
  background-color: #ffffff;
`;
export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    font-size: 2.5rem;
    padding: 1rem 1.5rem;
    background-color: rgb(245, 245, 245);
    color: #dd2515;
  }
`;
export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const Id = styled.div`
  h1 {
    margin: 0;
    color: #dd2515;
    span {
      color: gray;
      font-size: 30px;
    }
  }
  h4 {
    color: gray;
    margin: 0;
  }
`;
