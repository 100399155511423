const totalTimeMinutes = 15;
const warningTimeMinutes = 10; //starts on what minute
class TimerManager {
  private static instance: TimerManager;
  private interval: any;
  private seconds: number;
  private timerRunning: boolean;
  private modalVisible: boolean;
  private lastMouseMoveTime: number;
  private showModalCallback: () => void;
  private makeAPICallCallback: () => void;
  private constructor(
    showModalCallback?: () => void,
    makeAPICallCallback?: () => void,
    initialMinutes: number = totalTimeMinutes
  ) {
    this.interval = null;
    this.seconds = initialMinutes * 60;
    this.timerRunning = false;
    this.lastMouseMoveTime = Date.now();
    this.modalVisible = false;
    this.showModalCallback = showModalCallback || (() => { });
    this.makeAPICallCallback = makeAPICallCallback || (() => { });
    this.startTimer();

    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange.bind(this)
    );

    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
  }
  public static getInstance(
    showModalCallback?: () => void,
    makeAPICallCallback?: () => void,
    initialMinutes: number = totalTimeMinutes
  ): TimerManager {
    if (!TimerManager.instance) {
      TimerManager.instance = new TimerManager(
        showModalCallback ? showModalCallback : () => { },
        makeAPICallCallback ? makeAPICallCallback : () => { },
        initialMinutes
      );
    }
    return TimerManager.instance;
  }

  public getSecondsLeft(): number {
    return this.seconds;
  }
  public getModalVisible(): boolean {
    return this.modalVisible;
  }
  public isTimerRunning(): boolean {
    return this.timerRunning;
  }

  private startTimer(): void {
    clearInterval(this.interval);
    this.timerRunning = true;
    this.interval = setInterval(() => {

      if (this.timerRunning) {
        if (this.seconds > 0 && this.lastMouseMoveTime) {
          this.seconds--;
          if (
            this.seconds === (totalTimeMinutes - warningTimeMinutes) * 60 ||
            (this.seconds < (totalTimeMinutes - warningTimeMinutes) * 60 &&
              this.seconds > 2)
          ) {
            if (
              Date.now() >
              this.lastMouseMoveTime + warningTimeMinutes * 60000
            ) {
              this.showModalCallback();
              if (!this.modalVisible) this.modalVisible = true;
            }
          } else if (this.seconds === 1 || this.seconds < 1) {
            clearInterval(this.interval);
            this.makeAPICallCallback();
          }
        } else {
          this.clearTimer();
        }
      }
    }, 1000);
  }
  private handleVisibilityChange(): void {
    if (document.visibilityState === "visible") {
      const timestampSaved = parseInt(
        sessionStorage.getItem("timestampSaved") || "0"
      ); // timestamp of when the screen was last active

      if (timestampSaved && this.lastMouseMoveTime) {
        const currentTimeStamp = Date.now() / 1000; // timestamp of current time in seconds
        const timeDiff = currentTimeStamp - timestampSaved / 1000; // seconds since last screen active
        const mouseMoveTimeDiff =
          currentTimeStamp - this.lastMouseMoveTime / 1000; // seconds since last mouse move
        // console.log(
        //   { timeDiff },
        //   { mouseMoveTimeDiff },
        //   { seconds: this.seconds }
        // );
        if (
          timeDiff > this.seconds &&
          mouseMoveTimeDiff >= totalTimeMinutes * 60
        ) {
          this.clearTimer();
          this.makeAPICallCallback();
        } else {
          // console.log("hi", {
          //   seconds:
          //     Math.floor(this.seconds - timeDiff) >= warningTimeMinutes * 60
          //       ? Math.floor(this.seconds - timeDiff)
          //       : Math.floor(totalTimeMinutes * 60 - mouseMoveTimeDiff),
          // });
          this.seconds =
            Math.floor(this.seconds - timeDiff) >= warningTimeMinutes * 60
              ? Math.floor(this.seconds - timeDiff)
              : Math.floor(totalTimeMinutes * 60 - mouseMoveTimeDiff);
          this.startTimer();
        }
      }
    } else {
      if (this.timerRunning) {
        sessionStorage.setItem("timestampSaved", Date.now().toString());
        clearInterval(this.interval);
        this.timerRunning = false;
      }
    }
  }
  private handleMouseMove() {
    if (!this.modalVisible) {
      this.lastMouseMoveTime = Date.now();
      this.seconds = totalTimeMinutes * 60;
    }
  }
  public resetTimer(initialMinutes: number = totalTimeMinutes): void {
    this.modalVisible = false;
    clearInterval(this.interval);
    this.lastMouseMoveTime = Date.now();
    sessionStorage.removeItem("timestampSaved");
    this.seconds = initialMinutes * 60;
    this.startTimer();
  }
  public clearTimer(): void {
    clearInterval(this.interval);
    this.timerRunning = false;
    sessionStorage.removeItem("timestampSaved");
    this.seconds = 0;
    this.modalVisible = false;
  }
}

export default TimerManager;