import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { RoleCreationForm } from "../../Drawers/RolesCreation";
import { fetchCVTableData } from "../../ControlledVocabularies/common";
import { API_URL } from "../../../API/Config";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import * as Styles from "./ManageRolesStyles";

function ManageRoles() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [currEditingRole, setCurrEditingRole] = useState({});
  const [roleDrawer, setRoleDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (roleDrawer.visibility === false) {
      setFlag(prevValue=>!prevValue);
    }
  }, [roleDrawer]);

  const columns = [
    {
      title: "",
      width: 40,
      render: (record: any) => {
        return (
          <>
            {selectedTableRow.length !== 0 ? (
              ""
            ) : (
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCurrEditingRole(record);
                    setRoleDrawer({
                      visibility: true,
                      type: "edit",
                    });
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "name",
      width: 225,
    },
    {
      title: "Privileges ",
      dataIndex: "permissions",
      render: (permissions: any) => {
        const text = permissions.length === 0
          ? <span>-</span>
          : permissions.map((data: any) => data && <Tag>{data}</Tag>);
        return <div style={{ display: "flex", rowGap: "2px" }}>{text}</div>;
      }
    }
  ];

  return (
    <Styles.Container>
      <RoleCreationForm
        drawerType={roleDrawer.type}
        visibility={roleDrawer.visibility}
        setVisibility={setRoleDrawer}
        currEditingRole={currEditingRole}
      />
      <ReTable
        name="allRoles"
        title="All Roles"
        columns={columns}
        refresh={flag}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "id",
        }}
        uniView={{
          uniView: "role",
          viewName: "allRoles",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
        headerButtons={[
          {
            title: "Add New",
            icon: <UserAddOutlined />,
            onClick: () => {
              setRoleDrawer({
                visibility: true,
                type: "create"
              })
            },
          },
        ]}
      />
    </Styles.Container>
  );
}

export default ManageRoles;
