import { Button, Select, Space, Tag, Tooltip } from "antd";
import * as Styles from "./CasesDraweStyles";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";
import DuplicateDrawer from "../DuplicateDrawer/DuplicateDrawer";
import { EyeOutlined, LinkOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import TranslationComparison from "../TranslationComparison/TranslationComparison";
import { Link, useLocation, useParams } from "react-router-dom";
import WorkflowForm from "../WorkflowForm/WorkflowForm";
import Styled from "styled-components";
import {
    getRandomDarkColor,
} from "../../../Healper/Methods";
import {
    DuplicateCaseTableDisplay,
    SourceMappingByValue,
    StageMappingByValue,
} from "../../../Healper/Mappings";
import { file } from "../../../API/File";
import { GetAllProducts } from "../../../slices/Product/Helpers/GetAllProducts";
import { getAllRouteOfAdministration } from "../../../slices/ControlledVocabularies/Helpers/getAllRouteOfAdministartion";
import { getAllUnits } from "../../../slices/ControlledVocabularies/Helpers/getAllUnits";
import { getAllCountries } from "../../../slices/ControlledVocabularies/Helpers/getAllCountries";
import { getAllSeriousness } from "../../../slices/ControlledVocabularies/Helpers/getAllSeriousness";
import { categorization } from "../../../API/Masters/Categorization/Categorization";
import TruncateComponent from "./TruncateComponent";

function CaseDetailView(props: {
    visibility: boolean;
    type: string;
    setVisibility: Function;
    width?: string;
    defaultTabId?: string;
}) {
    const { visibility, setVisibility, width, defaultTabId } = props;
    const { currentAccessingCase } = useAppSelector((store) => store.cases);
    const { userPermissions } = useAppSelector((store) => store.user);
    const { valueToTitleMapper } = useAppSelector(
        (store) => store.categorization
    );

    const location = useLocation();
    const dispatch = useAppDispatch();

    const [stageData] = useState(StageMappingByValue(currentAccessingCase.stage));
    const [allFileData, setAllFileData] = useState<any>({});
    const [selectedDuplicateValue, setSelectedDuplicateValue] =
        useState<number>();
    const [duplicateDrawerVisibility, setDuplicateDrawerVisibility] =
        useState(false);
    const [viewFullAbstractValue, setViewFullAbstractValue] =
        useState<boolean>(false);
    const [translationComparisonDrawer, setTranslationComparisonDrawer] =
        useState({
            visibility: false,
            type: "",
        });
    const [isViewRecordData, setViewRecordData] = useState<boolean>(false);
    const [categorisationData, setCategorisationData] = useState<any>(valueToTitleMapper);

    useEffect(() => {
        visibility && initialLoadApi();
    }, [visibility])

    const initialLoadApi = () => {
        dispatch(GetAllProducts());
        dispatch(getAllRouteOfAdministration());
        dispatch(getAllUnits());
        dispatch(getAllCountries());
        dispatch(getAllSeriousness());
    }

    useEffect(() => {
        if (location.pathname?.includes("view-record")) {
            setViewRecordData(true);
        }
    }, [location])

    useEffect(() => {
        setSelectedDuplicateValue(currentAccessingCase.duplicate);
        getFileDownloadUrl();
    }, [currentAccessingCase]);

    useEffect(() => {
        getCategorisationData();
    }, []);

    const getCategorisationData = async () => {
        if (Object.keys(valueToTitleMapper)?.length) return;
        const res = await categorization.getAll();
        if (res.status) {
            let enumToTitleMapper: any = {};
            let titleToEnumMapper: any = {};
            let allColors: string[] = [];
            res.data.forEach((item: any) => {
                const color = getRandomDarkColor(allColors);
                allColors.push(color)
                enumToTitleMapper[item.numid] = {
                    text: item.name,
                    color: color,
                };
                titleToEnumMapper[item.name] = item.numid;
            });
            setCategorisationData(enumToTitleMapper);
        }
    }

    const getFileDownloadUrl = async () => {
        const attachmentId = currentAccessingCase.attachment;
        let ftpAttacment, translateAttacment, auditLogsAttachment, attachment, finalResponse = [];
        if (attachmentId) {
            const res = await file.getSignedUrls(attachmentId, { urlType: "view" });
            attachment = {
                name: res.fileRecord.filename,
                url: res.url,
                type: "attachment",
            };
        }
        if (
            currentAccessingCase?.otherAttachment &&
            !currentAccessingCase?.ftpAttacment &&
            !currentAccessingCase?.translateAttacment
        ) {
            finalResponse = await Promise.all(
                currentAccessingCase?.otherAttachment?.files?.map(
                    async (fileData: any) => {
                        const res = await file.getSignedUrls(fileData.id, {
                            urlType: "view",
                        });
                        return {
                            name: res?.fileRecord.filename,
                            url: res?.url,
                            type: fileData?.type,
                        };
                    }
                )
            );
            ftpAttacment = finalResponse.filter(
                (fileData) => fileData.type === "ftp"
            );
            translateAttacment = finalResponse.filter(
                (fileData) => fileData.type === "translate"
            );
            auditLogsAttachment = finalResponse.filter(
                (fileData) => fileData.type === "auditlogs"
            );
        }
        setAllFileData({
            ftpAttacment: ftpAttacment || [],
            translateAttacment: translateAttacment || [],
            auditLogsAttachment: auditLogsAttachment || [],
            ...attachmentId ? { attachment: attachment } : {},
        });
    };

    const handleDuplicateItemChange = (selectedValue: number) => {
        setSelectedDuplicateValue(selectedValue);
        setDuplicateDrawerVisibility(true);
    };

    const DrawerContainer = Styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        overflow-y: hidden;
        height: 85vh;
    `;

    return (
        <DrawerContainer>
            <Styles.InformationContainer>
                <Styles.FormFeilds>
                    <p className="heading">
                        Title{" "}
                        {
                            <Tooltip title="View Original">
                                <EyeOutlined
                                    className="translationIcon"
                                    onClick={() => {
                                        setTranslationComparisonDrawer({
                                            type: "title",
                                            visibility: true,
                                        });
                                    }}
                                />
                            </Tooltip>
                        }
                    </p>
                    <p> {currentAccessingCase?.articleTitle}</p>
                </Styles.FormFeilds>
                <Styles.FormFeilds>
                    <p className="heading">
                        Abstract{" "}
                        {
                            <Tooltip title="View Original">
                                <EyeOutlined
                                    className="translationIcon"
                                    onClick={() => {
                                        setTranslationComparisonDrawer({
                                            type: "abstract",
                                            visibility: true,
                                        });
                                    }}
                                />
                            </Tooltip>
                        }
                    </p>
                    <p>
                        {currentAccessingCase.abstract ? (
                            <TruncateComponent 
                                data={currentAccessingCase.abstract}
                            />
                        ) : (
                            "-"
                        )}
                    </p>
                    <p>
                        <Styles.FormFeilds>
                            <p className="heading">Author Names</p>
                            <p>
                                {
                                    currentAccessingCase?.source === "embase"
                                        ? (currentAccessingCase
                                            ?.C_2_r_primarySourcesOfInformationRepeatAsNecessary
                                            ?.C_2_r_1_2_reportersGivenName || "")
                                        : (currentAccessingCase?.authors || "")
                                }
                            </p>
                        </Styles.FormFeilds>
                        {currentAccessingCase?.meta?.authorAddresses && (
                            <Styles.FormFeilds>
                                <p className="heading ">Author Addresses</p>
                                <p>{currentAccessingCase?.meta?.authorAddresses || ""}</p>
                            </Styles.FormFeilds>
                        )}
                        {currentAccessingCase?.meta?.day0ChangeReason && (
                            <Styles.FormFeilds>
                                <p className="heading ">Day 0 Change Reasons</p>
                                <ul>
                                    {
                                        currentAccessingCase?.meta?.day0ChangeReason?.split("<|||>")?.map((reason: string, i: number) =>
                                            <li>{(i + 1) + ") "}{reason}</li>
                                        )
                                    }
                                </ul>
                            </Styles.FormFeilds>
                        )}
                        {currentAccessingCase?.meta?.authorKeywords && (
                            <Styles.FormFeilds>
                                <p className="heading ">Author Keywords</p>
                                <p>{currentAccessingCase?.meta?.authorKeywords || ""}</p>
                            </Styles.FormFeilds>
                        )}
                        {currentAccessingCase?.meta?.emtreeDrugIndexTermsMajorFocus && (
                            <Styles.FormFeilds>
                                <p className="heading ">
                                    Emtree Drug Index Terms (Major Focus){" "}
                                </p>
                                <p>
                                    {currentAccessingCase?.meta
                                        ?.emtreeDrugIndexTermsMajorFocus || ""}
                                </p>
                            </Styles.FormFeilds>
                        )}
                        {currentAccessingCase?.meta?.emtreeDrugIndexTerms && (
                            <Styles.FormFeilds>
                                <p className="heading ">Emtree Drug Index Terms</p>
                                <p>
                                    {currentAccessingCase?.meta?.emtreeDrugIndexTerms || ""}
                                </p>
                            </Styles.FormFeilds>
                        )}
                        {currentAccessingCase?.meta
                            ?.emtreeMedicalIndexTermsMajorFocus && (
                                <Styles.FormFeilds>
                                    <p className="heading ">
                                        Emtree Medical Index Terms (Major Focus)
                                    </p>
                                    <p>
                                        {currentAccessingCase?.meta
                                            ?.emtreeMedicalIndexTermsMajorFocus || ""}
                                    </p>
                                </Styles.FormFeilds>
                            )}
                        {currentAccessingCase?.meta?.emtreeMedicalIndexTerms && (
                            <Styles.FormFeilds>
                                <p className="heading ">Emtree Medical Index Terms</p>
                                <p>
                                    {currentAccessingCase?.meta?.emtreeMedicalIndexTerms ||
                                        ""}
                                </p>
                            </Styles.FormFeilds>
                        )}
                    </p>
                </Styles.FormFeilds>
                <div className="heading-container">
                    <Styles.FormFeilds>
                        <p className="heading ">Source</p>
                        <p>{SourceMappingByValue(currentAccessingCase?.source)}</p>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <>
                            <p className="heading">Record ID </p>

                            <span>{currentAccessingCase?.id}</span>
                        </>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <>
                            <p className="heading ">Current WF</p>
                            {stageData.text === "-" ? (
                                "-"
                            ) : (
                                <Tag
                                    style={{ borderRadius: "5px", width: "max-content" }}
                                    color={stageData.color}
                                >
                                    {stageData.text}
                                </Tag>
                            )}
                        </>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">Auto Translated</p>
                        <span>
                            {currentAccessingCase.caseTranslated ? (
                                <Tag style={{ borderRadius: "5px" }} color="green">
                                    Yes
                                </Tag>
                            ) : (
                                <Tag style={{ borderRadius: "5px" }} color="red">
                                    No
                                </Tag>
                            )}
                        </span>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">AI Processed </p>
                        <span>
                            {currentAccessingCase.caseAiCategorized ? (
                                <Tag style={{ borderRadius: "5px" }} color="green">
                                    Yes
                                </Tag>
                            ) : (
                                <Tag style={{ borderRadius: "5px" }} color="red">
                                    No
                                </Tag>
                            )}
                        </span>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">AI Categorization</p>
                        <p>
                            {currentAccessingCase?.aiCategorization?.length > 0
                                ? currentAccessingCase?.aiCategorization?.map(
                                    (data: any) => (
                                        <p
                                            style={{
                                                color: categorisationData[data]?.color,
                                                margin: 0
                                            }}
                                        >
                                            {categorisationData[data]?.text}
                                        </p>
                                    )
                                )
                                : "-"}
                        </p>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">Record Categorization</p>
                        <p>
                            {currentAccessingCase?.categorization?.length > 0
                                ? currentAccessingCase?.categorization?.map((data: any) => (
                                    <p
                                        style={{
                                            color: categorisationData[data]?.color,
                                            margin: 0
                                        }}
                                    >
                                        {categorisationData[data]?.text}
                                    </p>
                                ))
                                : "-"}
                        </p>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">Volume</p>
                        <p>
                            {currentAccessingCase.volumeNumber
                                ? currentAccessingCase.volumeNumber
                                : "-"}
                        </p>
                    </Styles.FormFeilds>
                    {currentAccessingCase?.medlinePMID && (
                        <Styles.FormFeilds>
                            <p className="heading ">Medline PMID</p>
                            <p>{currentAccessingCase?.medlinePMID || ""}</p>
                        </Styles.FormFeilds>
                    )}
                    {currentAccessingCase?.externalRefID && (
                        <Styles.FormFeilds>
                            <p className="heading ">PUI</p>
                            <p>{currentAccessingCase?.externalRefID || ""}</p>
                        </Styles.FormFeilds>
                    )}
                    {currentAccessingCase?.meta?.DOIName && (
                        <Styles.FormFeilds>
                            <p className="heading ">DOI</p>
                            <p>{currentAccessingCase?.meta?.DOIName || ""}</p>
                        </Styles.FormFeilds>
                    )}
                    <Styles.FormFeilds>
                        <p className="heading ">Duplicate</p>
                        <Space>
                            {isViewRecordData ? DuplicateCaseTableDisplay(selectedDuplicateValue)?.text :
                                <>
                                    <Select
                                        value={selectedDuplicateValue}
                                        style={{ width: 180 }}
                                        onChange={handleDuplicateItemChange}
                                        options={[
                                            { value: 0, label: "No" },
                                            { value: 1, label: "Yes" },
                                            { value: 2, label: "Potential" },
                                        ]}
                                    />
                                    {userPermissions.includes("13") && (
                                        <Button
                                            href="/search-and-generate-report-stand-alone"
                                            target="_blank"
                                            icon={<LinkOutlined />}
                                            style={{ fontSize: "1rem" }}
                                        />
                                    )}
                                </>
                            }
                        </Space>
                    </Styles.FormFeilds>
                    {currentAccessingCase?.duplicateOf &&
                        currentAccessingCase?.duplicateOf.length !== 0 && (
                            <div>
                                <p className="heading ">Duplicate of</p>
                                <div className="tags">
                                    {currentAccessingCase.duplicateOf.map((cas: string) => {
                                        return (
                                            <Link to={`/view-record/${cas}`} target="_blank">
                                                <Tag>{cas}</Tag>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    <Styles.FormFeilds>
                        <p className="heading ">Duplicate Comment</p>
                        <p>
                            {currentAccessingCase?.duplicateComment
                                ? currentAccessingCase.duplicateComment
                                : "-"}
                        </p>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">Issue</p>
                        <p>
                            {" "}
                            {currentAccessingCase.issueNumber
                                ? currentAccessingCase.issueNumber
                                : "-"}
                        </p>
                    </Styles.FormFeilds>

                    <Styles.FormFeilds>
                        <p className="heading ">Page Range</p>
                        <p>
                            {currentAccessingCase.page ? currentAccessingCase.page : "-"}
                        </p>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">Date of Publication</p>
                        <p>
                            {currentAccessingCase.publicationDate
                                ? currentAccessingCase.publicationDate
                                : "-"}
                        </p>
                    </Styles.FormFeilds>
                    <Styles.FormFeilds>
                        <p className="heading ">Full Text Link</p>
                        {currentAccessingCase.fullTextLink ? (
                            <a href={currentAccessingCase.fullTextLink} target="_blank">
                                {currentAccessingCase?.fullTextLink}
                            </a>
                        ) : (
                            "-"
                        )}
                    </Styles.FormFeilds>
                    {currentAccessingCase?.meta?.embaseLink && (
                        <Styles.FormFeilds>
                            <p className="heading ">Embase Link</p>
                            <a
                                href={currentAccessingCase?.meta?.embaseLink}
                                target="_blank"
                            >
                                {currentAccessingCase?.meta?.embaseLink}
                            </a>
                        </Styles.FormFeilds>
                    )}
                </div>
                {allFileData?.attachment && (
                    <Styles.FormFeilds>
                        <p className="heading">Attachment</p>
                        <p>{allFileData.attachment?.name}</p>
                        <Iframe
                            url={allFileData.attachment?.url}
                            width="500px"
                            height="500px"
                            className="filePreview"
                            overflow="hidden"
                            allowFullScreen
                            display="block"
                            position="relative"
                            styles={{
                                cursor: "pointer",
                            }}
                        />
                    </Styles.FormFeilds>
                )}
                {allFileData?.ftpAttacment?.length > 0 && (
                    <Styles.FormFeilds>
                        <p className="heading" style={{ marginTop: "1rem" }}>
                            FTP Attachments
                        </p>
                        <div className="attachemnt-container">
                            {allFileData.ftpAttacment?.map((fileData: any) => (
                                <div className="attachemnt-card">
                                    <p className="attachemnt-name">{fileData?.name}</p>
                                    <Iframe
                                        url={fileData?.url}
                                        width="320px"
                                        height="320px"
                                        className="filePreview"
                                        overflow="hidden"
                                        allowFullScreen
                                        display="block"
                                        position="relative"
                                        styles={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </Styles.FormFeilds>
                )}
                {allFileData?.translateAttacment?.length > 0 && (
                    <Styles.FormFeilds>
                        <p className="heading" style={{ marginTop: "1rem" }}>
                            Translate Attachments
                        </p>
                        <div className="attachemnt-container">
                            {allFileData.translateAttacment?.map(
                                (fileData: any) => (
                                    <div className="attachemnt-card">
                                        <p className="attachemnt-name">{fileData?.name}</p>
                                        <Iframe
                                            url={fileData?.url}
                                            width="320px"
                                            height="320px"
                                            className="filePreview"
                                            overflow="hidden"
                                            allowFullScreen
                                            display="block"
                                            position="relative"
                                            styles={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </Styles.FormFeilds>
                )}
                {allFileData?.auditLogsAttachment?.length > 0 && (
                    <Styles.FormFeilds>
                        <p className="heading" style={{ marginTop: "1rem" }}>
                            Audit Logs (OLMS v1.0.0)
                        </p>
                        <div className="attachemnt-container">
                            {allFileData.auditLogsAttachment?.map(
                                (fileData: any) => (
                                    <div className="attachemnt-card">
                                        <p className="attachemnt-name">{fileData?.name}</p>
                                        <Iframe
                                            url={fileData?.url}
                                            width="320px"
                                            height="320px"
                                            className="filePreview"
                                            overflow="hidden"
                                            allowFullScreen
                                            display="block"
                                            position="relative"
                                            styles={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </Styles.FormFeilds>
                )}
            </Styles.InformationContainer>
            <div style={{ position: "relative", paddingTop: "60px" }}>
                <WorkflowForm
                    visibility={visibility}
                    setVisibility={setVisibility}
                    defaultTabId={defaultTabId}
                    isViewRecordData={isViewRecordData}
                    allFileData={allFileData}
                />
            </div>
            <TranslationComparison
                visibility={translationComparisonDrawer.visibility}
                setVisibility={setTranslationComparisonDrawer}
                type={translationComparisonDrawer.type}
            />
            <DuplicateDrawer
                visibility={duplicateDrawerVisibility}
                setVisibility={setDuplicateDrawerVisibility}
                selectedDuplicateValue={selectedDuplicateValue}
                recordId={currentAccessingCase?.id}
            />
        </DrawerContainer>
    )
}

export default CaseDetailView