import * as Styles from "./PageNotFoundStyles";
import Logo404 from "../../Assets/404.png";
import { RightCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <Styles.Container>
      <img src={Logo404} alt="404" />
      <div className="des">
        <h1>OOPS, PAGE NOT FOUND</h1>
        <Link to="/">
          <h2 className="gotoHome">Go to Homepage <RightCircleOutlined /></h2>
        </Link>
      </div>
    </Styles.Container>
  );
}
export default PageNotFound;
