import { UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import type { ColumnsType } from "antd/es/table";
import { getAllPermission } from "../../slices/Permissions/Helpers/GetAllPermission";
import { PermissionTypes } from "../../Components/Types/TableColumn.types";
import { ManagePrivileges, Header, SideMenu  } from "../../Components";
import * as Styles from "./PrivilegesManagementStyles";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";

function PrivilegesManagement() {
  const dispatch = useAppDispatch();

  const { allPermissions } = useAppSelector((store) => store.permissions);
  const [permissionsDrawer, setPermissionsDrawer] = useState({
    visibility: false,
  });

  const PermissionsColumn: ColumnsType<PermissionTypes[]> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    { title: "Description", dataIndex: "description" },
  ];

  useEffect(() => {
    dispatch(getAllPermission());
  }, []);
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ManagePrivileges
            visibility={permissionsDrawer.visibility}
            setVisibility={setPermissionsDrawer}
          />
          <ReTable
            name="previliges"
            title="Previliges"
            columns={PermissionsColumn}
            data={allPermissions}
            headerButtons={[
              {
                title: "Add Previliges",
                icon: <UserAddOutlined />,
                onClick: setPermissionsDrawer
              },
            ]}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default PrivilegesManagement;
