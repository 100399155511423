import Styled from "styled-components";

export const Body = Styled.div`
display: grid;
gap: 1rem;
    .duplicateIds{
        .duplicate-cases-container{
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem 0rem;
        }
    }
    .radioBtns{
        display: grid;
    }
`;
