import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";

const getAllRouteOfAdministration = createAsyncThunk(
  "controlledVocabularies/getAllRouteOfAdministration",
  async () => {
    try {
      const data = await controlledVocabularies.getManyRouteOfAdministration()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllRouteOfAdministration };
