import { Tag } from "antd";
import { CategorizationDisplay, SourceMappingByValue, StatusEnumToValueDisplay } from "../../Healper/Mappings";

export const columnData: any = [
    {
        title: "Record ID",
        dataIndex: "id",
    },
    {
        title: "Source",
        dataIndex: "source",
        render: (source: any) => {
            const text = SourceMappingByValue(source);
            return text;
        },
    },
    {
        title: "Day 0",
        dataIndex: "dateOfSearch",
    },
    {
        width: 280,
        title: "Record Categorization",
        dataIndex: "categorization",
    },
    {
        width: 350,
        title: "Citation",
        dataIndex: "citation",
    },
    {
        title: "Full Text Link",
        dataIndex: "fullTextLink",
    },
    {
        width: 250,
        title: "FTP Request Status",
        dataIndex: "ftpRequestStatus",
        render: (data: any) => {
            if (data) {
                const { color, text } = StatusEnumToValueDisplay(data)
                return <Tag color={color}>{text}</Tag>;
            }
        },
    },
    {
        width: 250,
        title: "Date of FTP Request Sent",
        dataIndex: "dateFtpSent",
    },
    {
        width: 250,
        title: "Date of FTP Request Received",
        dataIndex: "dateFtpReceived",
    },
    {
        width: 250,
        title: "Translation Request Status",
        dataIndex: "translationRequestStatus",
        render: (data: any) => {
            if (data) {
                const { color, text } = StatusEnumToValueDisplay(data)
                return <Tag color={color}>{text}</Tag>;
            }
        },
    },
    {
        width: 250,
        title: "Date of Translation Request Sent",
        dataIndex: "dateTranslationSent",
    },
    {
        width: 270,
        title: "Date of Translation Request Received",
        dataIndex: "dateTranslationReceived",
    }
];

export const StatusDropDown = [
    {
        title: "Required",
        value: "REQUIRED",
    },
    {
        title: "Sent",
        value: "SENT",
    },
    {
        title: "Received",
        value: "RECEIVED",
    }
]