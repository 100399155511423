import * as Styles from "./PageInProgressStyles";
import LogoInProgress from "../../Assets/WorkInProgress.png";

function PageInProgress() {
  return (
    <Styles.Container>
      <img src={LogoInProgress} alt="404" />
      <div className="des">
        <h1>Under Progress</h1>
      </div>
    </Styles.Container>
  );
}
export default PageInProgress;
