import { selectRandomItemsFromAnArray } from "../../Healper/Methods";

export const distributeRecordsEvenly = (unassigned: number, userList: any[]) => {
    let selectedUsers: any[] = [], avgDistributedRecords = 0;
    let leftOverCases = 0, totalUser = userList?.length;
    if (unassigned === 0 || totalUser === 0) return null;
    if (unassigned >= totalUser) {
        avgDistributedRecords = Math.floor(unassigned / totalUser);
        leftOverCases = unassigned % totalUser;
    }
    else if (unassigned < totalUser) {
        leftOverCases = unassigned;
    }
    if (leftOverCases > 0) {
        selectedUsers = selectRandomItemsFromAnArray(userList, leftOverCases)
    }
    selectedUsers = selectedUsers?.map(user=>user.id)
    return {
        selectedUsers,
        avgDistributedRecords,
    }
}