/* eslint-disable react-hooks/exhaustive-deps */
import "./Styles.css";
import { Table, Button, Select, Space, Tag, Badge } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { UniviewService } from "../../Classes/Uniview";
import { ReNotification } from "../ReNotification";
import { headerOptions, headerStyles } from "./Constant";
import moment from "moment";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { isDifferent, modifyData } from "../../Methods/Methods";
import { SecureDataService } from "../../Classes/SecureData";
import { ArrayToCSV, getUrlParamString } from "./Helper";
import {
  IHeaderButtons,
  IHeaderDropdowns,
  IHeaderTags,
  IProps,
  IUniView,
  IUniviewOption,
} from "./Interface";

export default function ReTable(props: IProps) {
  const {
    columns,
    data,
    loading,
    showHeader,
    name,
    title,
    headerTags,
    headerButtons,
    headerDropdowns,
    bordered,
    pagination,
    rowSelection,
    scroll,
    className,
    styles,
    columnOptions,
    expandable,
    showTableStats,
    uniView,
    exportOption,
    refresh,
    preventInitialLoad,
    dataModification,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);

  const [newColumns, setNewColumns] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [newData, setNewData] = useState<any>([]);
  const [isCsvBtnLoading, setIsCsvLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState<number>(400);
  const [paginationPageCount, setPaginationPageCount] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [
    initialGenerateColumnOptionsLoad,
    setInitialGenerateColumnOptionsLoad,
  ] = useState<boolean>(true);
  const [tableCurrentChangesProps, setTableCurrentChangesProps] = useState<any>(
    {}
  );

  const adjustTableHeight = () => {
    const availableHeight = window.innerHeight - 300;
    setTableHeight(availableHeight);
  };

  const fetchDataFromUniview = async (uniViewProps: IUniView) => {
    let { uniView, viewName, tableName, apiUrl, filter, endPoint, option } = uniViewProps;
    const dataFetcher = new UniviewService(uniView, viewName, apiUrl, tableName);

    let newOrderBy;
    if (option?.orderBy) {
      newOrderBy = {
        [`${option?.orderBy?.key}`]: option?.orderBy?.order,
      };
    }

    const res = await dataFetcher.fetchData(
      filter || {},
      { ...option, orderBy: newOrderBy || {} } || {},
      endPoint
    );
    if (res.status) {
      return res?.data;
    }
  };
  const fetchTableDataFromUniview = async (
    uniViewProps: IUniView,
    tableChangesProps: any
  ) => {
    setTableLoading(true);
    let { viewName, apiUrl, filter, endPoint, tableName, option, extra } = uniViewProps;
    const dataFetcher = new UniviewService(
      uniViewProps?.uniView,
      viewName,
      apiUrl,
      tableName
    );

    // creating new option object
    let newOption: any = {
      offset: (tableChangesProps?.pagination?.current - 1) * tableChangesProps?.pagination?.pageSize || 0,
    };
    if (option?.orderBy) {
      newOption["orderBy"] = {
        [`${option?.orderBy?.key}`]: option?.orderBy?.order,
      };
    }

    newOption.limit = tableChangesProps?.pagination?.pageSize || uniViewProps?.option?.limit || 10;

    // creating filters for uniview
    let newFilters = {
      ...uniView?.filter,
    };
    if (filter) {
      newFilters = { ...filter, ...newFilters };
    }
    // when page refresh and url has query
    if (tableChangesProps) {
      newFilters = {
        ...createFilterForUniview(tableChangesProps?.filters),
        ...newFilters,
      };
    }

    const res = await dataFetcher.fetchData(
      newFilters,
      newOption,
      endPoint,
      extra
    );
    if (res?.status) {
      let newData = res.data;
      if (dataModification) {
        newData = modifyData(
          res.data,
          dataModification?.undefinedValuesTo,
          dataModification?.nullValuesTo,
          dataModification?.emptyString
        );
      }
      if (uniViewProps?.returnResponse) {
        uniViewProps.returnResponse({
          ...res,
          data: newData,
        });
      } else {
        setNewData(newData);
      }

      setTableLoading(false);
      setPaginationPageCount(res.count);
    } else {
      setTableLoading(false);
    }
  };

  const handleTableSingleRowSelect = (
    record: any,
    selected: boolean,
    selectedRows: any[],
    nativeEvent: any
  ) => {
    const selectedRowsCount = rowSelection?.setSelectedRowKeys?.length || 0;
    if (selectedRowsCount > 1000) {
      ReNotification({
        header: "Too Many Rows Selection Attempted",
        description: "Can Not Select More Rows For This Table",
        type: "error",
      });
      return
    }
    const key = rowSelection?.rowKey ? rowSelection?.rowKey : "id";
    const storeRowType = rowSelection?.selectRowtype;

    if (selected) {
      rowSelection?.setSelectedRowKeys([
        ...rowSelection.selectedRowKeys,
        record[`${key}`],
      ]);
      if (
        storeRowType === "allData" &&
        rowSelection?.selectedRowValues &&
        rowSelection?.setSelectedRowValues
      ) {
        rowSelection?.setSelectedRowValues([
          ...rowSelection.selectedRowValues,
          record,
        ]);
      }
    } else {
      let newItems: any = [];
      rowSelection?.selectedRowKeys.forEach((item: any) => {
        if (item !== record[`${key}`]) {
          newItems.push(item);
        }
      });
      rowSelection?.setSelectedRowKeys(newItems);
      newItems = [];
      if (
        storeRowType === "allData" &&
        rowSelection?.selectedRowValues &&
        rowSelection?.setSelectedRowValues
      ) {
        rowSelection?.selectedRowValues.forEach((item: any) => {
          if (item[`${key}`] !== record[`${key}`]) {
            newItems.push(item);
          }
        });
        rowSelection?.setSelectedRowValues(newItems);
      }
    }
  };

  const handleTableAllRowSelect = async (
    selected: boolean,
    selectedRows: any,
    changeRows: any
  ) => {

    if (paginationPageCount > 1000) {
      ReNotification({
        header: "Too Many Rows Selected",
        description: "Can Not Select More Rows For This Table.",
        type: "error",
      });
      return
    }
    const key = rowSelection?.rowKey ? rowSelection?.rowKey : "id";
    const storeRowType = rowSelection?.selectRowtype;

    if (selected) {
      if (uniView) {
        const filter = Object.keys(tableCurrentChangesProps?.filters || {}).length ? tableCurrentChangesProps?.filters : uniView?.filter;
        delete uniView?.option?.limit;
        let allTableData = [];
        if(paginationPageCount>10){
          allTableData = await fetchDataFromUniview({
            ...uniView,
            filter: createFilterForUniview(filter),
            option: {
              ...uniView?.option,
              selectFields: storeRowType === "allData" ? [] : [`${key}`],
            },
          });
        } 
        else {
          allTableData = newData;
        }
        rowSelection?.setSelectedRowKeys(
          allTableData.map((ele: any) => ele[`${key}`])
        );

        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          rowSelection?.setSelectedRowValues(allTableData);
        }
      } else {
        rowSelection?.setSelectedRowKeys(
          newData.map((row: any) => row[`${key}`])
        );
        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          rowSelection?.setSelectedRowValues(newData);
        }
      }
    } else {
      rowSelection?.setSelectedRowKeys([]);
      if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
        rowSelection?.setSelectedRowValues([]);
      }
    }
  };

  const getFilterOptionItems = (tableData: object[], key: string) => {
    let uniqueItems: object[] = [];
    tableData.forEach((data: any) => {
      if (data.hasOwnProperty(key)) {
        const newItem = {
          text: data[key] ? data[key] : "-",
          value: data[key],
        };
        const isAlreadyPresent = uniqueItems.some((item: any) => {
          return item.text === newItem.text && item.value === newItem.value;
        });
        if (!isAlreadyPresent) {
          uniqueItems.push(newItem);
        }
      }
    });
    return uniqueItems;
  };

  const getFilterOptionItemsFromUniview = async (
    uniview: string,
    viewName: string,
    apiUrl: string,
    extra: any,
    columnKey: string,
    filters: any,
    tableName?: string
  ) => {
    rowSelection?.setSelectedRowKeys([]);
    let option: any = {
      orderBy: {},
      selectFields: [columnKey],
      groupBy: [columnKey],
      filters: true,
    };

    let newFilters = {
      ...createFilterForUniview(filters),
    };
    if (uniView?.filter && Object.keys(uniView?.filter)) {
      newFilters = { ...newFilters, ...uniView?.filter };
    }


    const dataFetcher = new UniviewService(uniview, viewName, apiUrl, tableName);
    // Using same fetch function to fetch data specific to the group by

    const res = await dataFetcher.fetchData(newFilters, option, "data", extra);
    if (res.status) {
      // changing the filter object
      return res.data.map((item: any) => {
        return {
          text: item[columnKey],
          value: item[columnKey],
        };
      });
    } else {
      return [];
    }
  };

  function generateColumnSortingOption(cols: any, tableData: any) {
    return cols.map((col: any) => {
      if (tableData.length > 0) {
        let newCol;
        const columnKey = col.key;
        if (
          columnOptions?.sorting &&
          columnOptions?.sorting?.columns.includes(columnKey)
        ) {
          let dataType: string = typeof tableData[0][columnKey]; // getting data type of column data
          if (dataType === "string") {
            moment(tableData[0][columnKey]).isValid() && (dataType = "date");
          }
          newCol = {
            ...col,
            sorter: (a: any, b: any) => {
              switch (dataType) {
                case "date":
                  return (
                    moment(a[columnKey]).unix() - moment(b[columnKey]).unix()
                  );
                case "string":
                  return a[columnKey]?.localeCompare(b[columnKey]);
                case "number":
                  return a[columnKey] - b[columnKey];
                case "boolean":
                  return (a[columnKey] ? 1 : -1) - (b[columnKey] ? 1 : -1);
                default:
                  break;
              }
            },
          };
        } else return col;
        return newCol;
      } else {
        return col;
      }
    });
  }

  async function generateColumnFilterOption(
    cols: any,
    filters?: any,
    sorter?: any
  ) {
    filters = filters
      ? filters
      : tableCurrentChangesProps?.filters
        ? tableCurrentChangesProps?.filters
        : {};
    sorter = sorter
      ? sorter
      : tableCurrentChangesProps?.sorter
        ? tableCurrentChangesProps?.sorter
        : {};
    // console.log({ filters, sorter });
    const newCols = await Promise.all(
      cols.map(async (col: any) => {
        let newCol;
        const columnKey = col.key;
        let isFilterItemGiven = false;
        let filterItems;
        let isMapperIsGiven = false;
        let mapper: any;

        // here we are check weather current column key is present or not in the requested columns array
        let isColumnKeyPresent = false;
        if (columnOptions?.filter) {
          if (columnOptions?.filter?.columns.includes(columnKey)) {
            isColumnKeyPresent = true;
          } else {
            columnOptions?.filter?.columns.forEach((element: any) => {
              if (typeof element === "object" && element.key === columnKey) {
                isColumnKeyPresent = true;
                if (Object.keys(element).includes("items")) {
                  isFilterItemGiven = true;
                  filterItems = element.items;
                }

                if (Object.keys(element).includes("action")) {
                  isMapperIsGiven = true;
                  mapper = element.action;
                }
              }
            });
          }
        }

        if (isColumnKeyPresent) {
          if (uniView && !isFilterItemGiven) {
            // calling the uniview if its provided to get filter items
            filterItems = await getFilterOptionItemsFromUniview(
              uniView.uniView,
              uniView.viewName,
              uniView.apiUrl,
              uniView.extra,
              columnKey,
              filters,
              uniView?.tableName
            );
          } else if (!isFilterItemGiven) {
            filterItems = getFilterOptionItems(newData, columnKey);
          }

          // mapping the filter when action is present
          if (isMapperIsGiven) {
            const mappedItems = filterItems.map((fil: any) => {
              return {
                text: mapper(fil.value),
                value: fil.value,
              };
            });
            filterItems = mappedItems;
          }

          newCol = {
            ...col,
            filterSearch: columnOptions?.filter?.filterSearch,
            filters: filterItems,

            onFilter: (value: string | number | boolean, row: any) => {
              const typeOfValue = typeof value;
              const typeOfRowValue = typeof row[columnKey];
              if (typeOfValue === "string" && typeOfRowValue === "string") {
                return row[columnKey]?.includes(value);
              } else {
                return String(row[columnKey]).includes(String(value));
              }
            },
          };
          // if (sorter.field === columnKey && sorter.order) {
          //   newCol = {
          //     ...newCol,
          //     defaultSortOrder: sorter.order,
          //   };
          // }
          // if (filters[columnKey] && filters[columnKey]?.length > 0) {
          //   newCol = {
          //     ...newCol,
          //     filteredValue: filters[columnKey],
          //   };
          // }
        } else return col;
        return newCol;
      })
    );
    setNewColumns(newCols);
  }

  const formatTableData = (filterdata: any, columnData: any, i: number) => {
    if (columnData?.render) {
      const returnValue = columnData?.render(
        filterdata[i][columnData.dataIndex],
        filterdata[i]
      );
      return React.isValidElement(returnValue)
        ? filterdata[i][columnData.dataIndex]
        : returnValue;
    } else {
      return filterdata[i][columnData.dataIndex] || "";
    }
  };

  const generateCSVWithoutUniview = () => {
    const filterdata = rowSelection?.selectedRowKeys
      ? rowSelection?.selectedRowKeys.length > 0
        ? newData.filter((ele: any) => {
          return rowSelection?.selectedRowKeys.includes(
            ele[rowSelection.rowKey || "id"] as never
          );
        })
        : newData
      : newData;

    let finalData: string[][] = [],
      existingColumnKey: string[] = [],
      finalColumns: string[] = [];
    let temp: number = 0;
    for (let idx = 0; idx < filterdata.length; idx++) {
      newColumns.forEach((columnData: any) => {
        if (
          columnData?.dataIndex &&
          !existingColumnKey.includes(columnData?.dataIndex)
        ) {
          existingColumnKey.push(columnData?.dataIndex);
          idx === 0 && finalColumns.push(columnData?.title);
          if (!finalData?.[idx]) {
            finalData.push([formatTableData(filterdata, columnData, idx)]);
          } else {
            finalData[idx][temp] = formatTableData(filterdata, columnData, idx);
          }
          temp++;
        }
      });
      temp = 0;
      existingColumnKey = [""];
    }

    const fileName = name || "Table Data";
    ArrayToCSV(
      finalColumns,
      finalData,
      `${fileName + moment().format(" DD-MM-YY hh-mm-ss A")}.csv`
    );
    ReNotification({
      header: "Downloaded Successfully",
      description: "File Download Successful!",
      type: "success",
    });
  };

  const generateCSV = async (fileName: string | undefined) => {
    if (!uniView && !exportOption?.csv?.params?.uniView) {
      generateCSVWithoutUniview();
      return;
    }

    setIsCsvLoading(true);
    if (pagination?.total && pagination?.total > 300) {
      ReNotification({
        header: "Downloading......",
        description: "Large file size, expect longer download time.",
        type: "info",
      });
    }
    const dataFetcher = new UniviewService(
      uniView?.uniView || exportOption?.csv?.params?.uniView || "",
      uniView?.viewName || exportOption?.csv?.params?.viewName || "",
      uniView?.apiUrl || exportOption?.csv?.params?.apiUrl || ""
    );
    const orderByKey = uniView?.option?.orderBy?.key || exportOption?.csv?.params?.option?.orderBy?.key || "";
    const res = await dataFetcher.generateCSV(
      exportOption?.csv?.selectRowtype === "id" ||
        !exportOption?.csv?.selectRowtype
        ? rowSelection?.selectedRowKeys
        : rowSelection?.selectedRowValues,
      {
        orderBy: { [orderByKey]: uniView?.option?.orderBy?.order || exportOption?.csv?.params?.option?.orderBy?.order },
      },
      uniView?.filter || exportOption?.csv?.params?.filter || {},
      fileName ? fileName : exportOption?.csv?.fileName || "Data",
      rowSelection?.rowKey,
      exportOption?.csv?.columns,
      exportOption?.csv?.endPoint,
      exportOption?.csv?.params?.extra
    );
    setIsCsvLoading(false);
    if (res) {
      ReNotification({
        header: "Downloaded Successfully.",
        description: "File Download Successful! ",
        type: "success",
      });
      rowSelection?.setSelectedRowKeys([]);
      if (rowSelection?.setSelectedRowValues) {
        rowSelection?.setSelectedRowValues([]);
      }
    } else {
      ReNotification({
        header: "File Download Failed",
        description: "Please Try Again Later.",
        type: "error",
      });
    }
  };

  function isValidJSON(data: any) {
    try {
      return typeof data === "object" && !Array.isArray(data);
    } catch (e) {
      return false;
    }
  }

  function createFilterForUniview(tableFilters: any) {
    let newFilter: any = {};
    if (tableFilters) {
      Object.keys(tableFilters)?.forEach((key) => {
        if (!tableFilters[key]) return newFilter;
        if (isValidJSON(tableFilters[key])) {
          newFilter[`${key}`] = tableFilters[key];
        }
        else {
          newFilter[`${key}`] = {};
          newFilter[`${key}`] = {
            "=": tableFilters[key]
              .map((value: any) => (value === null ? "null" : value))
              .join(","),
          };
        }
      })
    }
    return newFilter;
  }

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    if (uniView) {
      filters = { ...tableCurrentChangesProps?.filters, ...filters }
      const allProps = { pagination, sorter, filters };
      setUrlParams(pagination, filters, sorter);
      const urlSearchParams = "?" + decodeURIComponent(urlQuery.toString());
      setTableCurrentChangesProps(allProps);
      navigate(`${location.pathname}${urlSearchParams}`);
      // console.log(pagination, sorter);
      let option: IUniviewOption = {};
      // creating sorting props
      if (sorter?.column !== undefined) {
        option["orderBy"] = {
          key: sorter?.column?.key,
          order: sorter?.order === "ascend" ? "ASC" : "DESC",
        };
      }

      // getting new filters items and setting it in the existing columns when filter triggers
      if (Object.keys(filters).length && Object.keys(filters)[0] !== null && isDifferent(tableCurrentChangesProps?.filters, filters)) {
        await generateColumnFilterOption(newColumns, filters, sorter);
      }
      // console.log(filters);
      fetchTableDataFromUniview(
        {
          ...uniView,
          filter: createFilterForUniview(filters),
          option: {
            ...uniView?.option,
            ...option,
          },
        },
        allProps
      );
    }
  };

  const setUrlParams = (pagination: any, filters: any, sorter: any) => {
    const secureDataService = new SecureDataService();
    const paginationLabel = `${name ? name?.replace(/\s+/g, "")?.toLocaleLowerCase() : ""
      }PaginationData`;
    const paginationData = secureDataService.encrypt({
      pagination,
      filters,
      sorter,
    });
    urlQuery.set(paginationLabel, paginationData);
  };

  const getUrlParams = () => {
    const secureDataService = new SecureDataService();
    return JSON.parse(secureDataService.decrypt(getUrlParamString()));
  };

  // USE EFFECTS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);

  useEffect(() => {
    let count;
    if (uniView && pagination?.total) {
      count = pagination?.total;
    } else if (!uniView) {
      if (pagination?.total || pagination?.total === 0) {
        count = pagination?.total;
      } else {
        count = newData.length;
      }
    }
    setPaginationPageCount(count);
  }, [pagination?.total]);

  useEffect(() => {
    if (newData?.length && initialGenerateColumnOptionsLoad) {
      const func = async () => {
        setTableLoading(true);
        let newCols;
        newCols = generateColumnSortingOption(columns, newData);
        await generateColumnFilterOption(newCols);
        setInitialGenerateColumnOptionsLoad(false);
        setTableLoading(false);
      };
      func();
    }
  }, [newData]);

  useEffect(() => {
    let allProps;
    const tablePaginationName = `${name ? name?.replace(/\s+/g, "")?.toLocaleLowerCase() : ""}PaginationData`;
    if (window.location.search.includes(tablePaginationName)) {
      allProps = getUrlParams();
      setTableCurrentChangesProps(allProps);
    }
    if (uniView && !preventInitialLoad) {
      fetchTableDataFromUniview(uniView, allProps);
    }
  }, [refresh, preventInitialLoad]);

  useEffect(() => {
    if (loading !== undefined) {
      setTableLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    generateColumnFilterOption(newColumns, {}, {});
  }, [name])

  useLayoutEffect(() => {
    adjustTableHeight();
    adjustTableHeight();
    window.addEventListener("resize", adjustTableHeight);
    return () => {
      window.removeEventListener("resize", adjustTableHeight);
    };
  }, []);

  // COMPONENT VARIABLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const tableTitle = (
    <>
      <div style={headerStyles}>
        <div>
          {title && <h2>{title}</h2>}
          {headerTags?.map((tag: IHeaderTags) => {
            const { color, title } = tag;
            return (
              <Tag key={title} color={color}>
                {title}
              </Tag>
            );
          })}
        </div>
        <div style={headerOptions}>
          {headerDropdowns?.map((select: IHeaderDropdowns, index: number) => {
            const { styles, setSelectedValue, hide } = select;
            return (
              !hide && (
                <Select
                  key={index}
                  style={styles}
                  {...select}
                  onChange={(value: string | number, option: any) => {
                    setSelectedValue(value);
                  }}
                />
              )
            );
          })}
          {headerButtons?.map((btn: IHeaderButtons, index: number) => {
            const { title, visibility } = btn;
            return (
              (visibility || visibility === undefined) && (
                <Button key={index} {...btn}>
                  {title}
                </Button>
              )
            );
          })}
          {exportOption?.pdf && !exportOption?.pdf?.hide && (
            <Button
              onClick={exportOption.pdf.onClick}
              type="primary"
              loading={exportOption.pdf?.loading}
              size="middle"
              icon={<FilePdfOutlined />}
              disabled={exportOption.pdf.disabled || !newData.length}
            >
              Export to PDF
            </Button>
          )}
          {exportOption?.csv && !exportOption?.csv?.hide && (
            <Button
              onClick={() => {
                generateCSV(name);
              }}
              type="primary"
              loading={isCsvBtnLoading}
              size="middle"
              icon={<FileExcelOutlined />}
              disabled={exportOption.csv.disabled || !newData.length}
            >
              Export to CSV
            </Button>
          )}
        </div>
      </div>
      {showTableStats && (
        <Space direction="horizontal">
          <span>
            <strong>Total Rows: </strong>
            <Badge
              count={paginationPageCount || 0}
              showZero
              overflowCount={9999}
            />
          </span>
          {rowSelection && (
            <span>
              <strong>Selected Rows: </strong>
              <Badge
                count={rowSelection?.selectedRowKeys.length}
                showZero
                overflowCount={9999}
              />
            </span>
          )}
        </Space>
      )}
    </>
  );

  const componentProps = {
    key: name,
    columns: columnOptions ? newColumns : columns,
    onChange: handleTableChange,
    dataSource: newData,
    loading: tableLoading,
    rowKey: rowSelection?.rowKey,
    showHeader: showHeader,
    sortDirections: columnOptions?.sorting?.sortingDirections,
    bordered: bordered,
    pagination: {
      ...pagination,
      current:
        tableCurrentChangesProps?.pagination?.current || pagination?.current,
      total: paginationPageCount,
      hideOnSinglePage: true,
      pageSizeOptions: pagination?.pageSizeOptions
        ? pagination?.pageSizeOptions
        : [10, 20, 50, 100],
    },
    scroll: {
      ...scroll,
      y: tableHeight,
    },
    expandable: {
      ...expandable,
      expandedRowKeys: expandedRows,
      onExpandedRowsChange: (expandedRows: any) => {
        setExpandedRows(expandedRows);
      },
    },
    rowSelection: rowSelection
      ? {
        ...rowSelection,
        onSelect: handleTableSingleRowSelect,
        onSelectAll: handleTableAllRowSelect,
      }
      : undefined,
    title: () => {
      return tableTitle;
    },
  };

  return (
    <div className="table">
      <Table style={styles} className={className} {...componentProps} />
    </div>
  );
}
