import { Header, SideMenu } from "../../../Components";
import OutComeTable from "../../../Components/ControlledVocabularies/OutCome/OutComeTable";

import * as Styles from "./OutcomeStyles";

function OutCome() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <OutComeTable/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default OutCome;
