import { Button } from 'antd';
import {
    truncate,
} from "../../../Healper/Methods";
import { useState } from 'react'

function TruncateComponent({ data }: any) {

    const [isTruncate, setTruncate] = useState(false);

    return (
        <p>
            {isTruncate
                ? data
                : truncate(data, 350)}
            <Button
                type="link"
                onClick={() => {
                    setTruncate(preValue => !preValue);
                }}
                id="myBtn"
            >
                {isTruncate ? "Read less" : "Read more"}
            </Button>
        </p>
    )
}

export default TruncateComponent;