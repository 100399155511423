import { Header, SideMenu } from "../../../Components";
import ImeTable from "../../../Components/ControlledVocabularies/ImeTable/ImeTable";

import * as Styles from "./ImeStyles";

function IME() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer  className="subContainer">
          <ImeTable/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default IME;
