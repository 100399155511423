import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/User/UserSlices";
import roleReducer from "../slices/Roles/RoleSlices";
import sponsorReducer from "../slices/Sponsors/SponsorSlices"
import permissionsReducer from "../slices/Permissions/PermissionSlice"
import sideMenuReducer from '../slices/SideMenu/SideMenuSlices';
import productReducer from "../slices/Product/ProductSlices"
import ingredientReducer from "../slices/ActiveIngredient/IngredientSlices"
import labelReducer from "../slices/LabelAssessment/AssessmentSlices"
import categorizationReducer from "../slices/Categorization/CategoizationSlices"
import controlledVocabulariesReducer from "../slices/ControlledVocabularies/ControlledVocabulariesSlice"
import casesReducer from "../slices/Record/RecordSlices"
import dashboardReducer from '../slices/Dashboard/DashboardSlices';
import auditLogs from '../slices/AuditLogs/AuditLogsSlices';
import session from '../slices/Session/Session';
import { loadState } from '../localstorage';

const persistedState = loadState();

export const store = configureStore({
  preloadedState: persistedState,
  reducer: {
    user: userReducer,
    roles: roleReducer,
    sponsor: sponsorReducer,
    permissions: permissionsReducer,
    sideMenu: sideMenuReducer,
    product: productReducer,
    ingredient: ingredientReducer,
    label: labelReducer,
    categorization: categorizationReducer,
    controlledVocabularies: controlledVocabulariesReducer,
    cases: casesReducer,
    dashboard: dashboardReducer,
    auditLogs: auditLogs,
    session: session
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
