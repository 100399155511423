import { Badge, Button, Card, InputNumber, List, Radio } from 'antd'
import { useEffect, useState } from 'react';
import * as Styles from "./WorkAllocationStyles";
import { Header, SideMenu } from '../../Components'
import { Uniview } from '../../API/Uniview';
import { MinusOutlined, PlusOutlined, RedoOutlined, RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { importEngine } from '../../API/ImportEngine';
import { ReNotification } from '../../unity-ui-common/Components/ReNotification';
import moment from 'moment';
import { distributeRecordsEvenly } from './WorkAllocationMethods';
import ReModal from '../../unity-ui-common/Components/ReModal';
import WorkAllocationConfirmationModalBody from './WorkAllocationConfirmationModalBody';
import ReDatePickerDropDown from '../../unity-ui-common/Components/ReFormFields/ReDatePickerDropDown';
import { datePickerDropDownItems } from '../Dashboard/Constant';

type WorkflowModeType = "workflowOne" | "workflowTwo" | "qc"

type CounterPropsType = {
  values: any,
  setValues?: (arg: any) => void,
  readOnly: boolean,
  userId: string,
  workflowMode: WorkflowModeType,
  totalUnAssigned?: any,
  setTotalUnAssigned?: (arg: any) => void,
  totalAssigned?: any,
  setTotalAssigned?: (arg: any) => void,
};

function ListHeader() {
  return (
    <div className='list-header'>
      <div className="heading">User</div>
      <div className="heading">Pending Records</div>
      <div className="heading">Assigned Records</div>
    </div>
  )
}

function Counter(props: CounterPropsType) {
  const { values, setValues, readOnly, userId, workflowMode, totalUnAssigned, setTotalUnAssigned, totalAssigned, setTotalAssigned } = props;

  const MinusSymbol = () => {
    return (
      <button className="symbol minus-symbol" onClick={() => handleCalculation('minus')}>
        <MinusOutlined />
      </button>
    )
  }

  const AddSymbol = () => {
    return (
      <button className="symbol add-symbol" onClick={() => handleCalculation('add')}>
        <PlusOutlined />
      </button>
    )
  }

  const handleCalculation = (operationType: 'minus' | 'add') => {
    let newValue = 0, oldValue = values?.[workflowMode]?.[userId] || 0,
      totalAssignedValue = totalAssigned.data, totalUnAssignedValue = totalUnAssigned.data;
    if (operationType === "add" && totalAssignedValue === totalUnAssignedValue) return;
    switch (operationType) {
      case 'add':
        newValue = oldValue + 1;
        totalAssignedValue += 1;
        break;
      case 'minus':
        newValue = oldValue - 1;
        if (newValue < 0) { newValue = 0; break; }
        totalAssignedValue -= 1;
        totalAssignedValue = totalAssignedValue < 0 ? 0 : totalAssignedValue
        break;
      default: break;
    }
    setTotalAssigned && setTotalAssigned({
      data: totalAssignedValue,
      view: totalAssignedValue
    });
    setTotalUnAssigned && setTotalUnAssigned({
      data: totalUnAssignedValue,
      view: totalUnAssignedValue - totalAssignedValue
    });
    setValues && setValues({
      ...values,
      [workflowMode]: {
        ...values[workflowMode],
        [userId]: newValue,
      }
    })
  }

  const handleChange = (value: any) => {
    let totalAssignedValue, totalUnAssignedValue = totalUnAssigned.data;
    if (totalUnAssignedValue === 0 || totalUnAssignedValue - value < 0) return
    const valuesObj = {
      ...values,
      [workflowMode]: {
        ...values[workflowMode],
        [userId]: value,
      }
    }
    totalAssignedValue = Object.values(valuesObj?.[workflowMode]).reduce((a: any, b: any) => a + b, 0);
    if (typeof (totalAssignedValue) === 'number') {
      setTotalAssigned && setTotalAssigned({
        data: totalAssignedValue,
        view: totalAssignedValue
      })
      setTotalUnAssigned && setTotalUnAssigned({
        data: totalUnAssignedValue,
        view: totalUnAssignedValue - totalAssignedValue
      })
    }
    setValues && setValues(valuesObj)
  }

  return (
    <InputNumber
      value={values?.[workflowMode]?.[userId] || 0}
      addonBefore={!readOnly && <MinusSymbol />}
      addonAfter={!readOnly && <AddSymbol />}
      readOnly={readOnly}
      onChange={handleChange}
      controls={false}
    />
  )
}
function WorkAllocation() {

  const initialValue = {
    workflowOne: [],
    workflowTwo: [],
    qc: []
  }
  const initialStatValue = {
    data: 0,
    view: 0
  }
  const workflowModessArr = [
    {
      key: 'workflowOne',
      value: 'Workflow One'
    },
    {
      key: 'workflowTwo',
      value: 'Workflow Two'
    },
    {
      key: 'qc',
      value: 'Quality Check'
    }
  ]
  const [isLoading, setLoading] = useState(true)
  const [userData, setUserData] = useState(initialValue);
  const [values, setValues] = useState(initialValue);
  const [pendingValues, setPendingValues] = useState(initialValue);
  const [totalPending, setTotalPending] = useState(initialStatValue);
  const [totalAssigned, setTotalAssigned] = useState(initialStatValue);
  const [totalUnAssigned, setTotalUnAssigned] = useState(initialStatValue);
  const [workflowMode, setWorkFlowMode] = useState<WorkflowModeType>('workflowOne');
  const [selectedDate, setSelectedDate] = useState<any>([
    moment(new Date())
      .subtract(1, "month")
      .date(new Date().getDate()),
    moment(new Date()),
  ]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [selectedDateFilterOption, setSelectedDateFilterOption] = useState<string>("lastMonth");
  const [confirmationModalVisibility, setConfirmationModalVisibility] = useState<boolean>(false);
  const [recordAssignmentApiResponse, setRecordAssignmentApiResponse] = useState<any>([]);

  useEffect(() => {
    const currentDate = new Date()
    let filterForAPI: any = {
      createdAt: {
        ">": moment(currentDate).subtract(1, "month").date(currentDate.getDate()),
        "<": currentDate,
      },
    }
    if (selectedDate.length > 1) {
      filterForAPI = {
        createdAt: {
          ">": moment(selectedDate?.[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
          "<": moment(selectedDate?.[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z'
        },
      }
    }
    handleLoad(filterForAPI);
    setLoading(true);
  }, [workflowMode, selectedDate]);

  const handleLoad = async (filterProp?: any, type?: string) => {
    let importType: 'workflowOneUnAssigned' | 'workflowTwoUnAssigned' | 'qcUnAssigned'
    switch (workflowMode) {
      case 'workflowOne':
        importType = 'workflowOneUnAssigned'
        break;
      case 'workflowTwo':
        importType = 'workflowTwoUnAssigned'
        break;
      case 'qc':
        importType = 'qcUnAssigned'
        break;
      default:
        importType = 'workflowOneUnAssigned'
    }
    const importUniview = new Uniview("import-engine", importType);
    const userUniview = new Uniview("user", workflowMode);
    const currentDate = new Date()
    const filterForAPI = {
      createdAt: {
        ">": moment(currentDate)
          .subtract(1, "month")
          .date(currentDate.getDate()),
        "<": currentDate,
      },
    }
    const importRes = await importUniview.fetchData(filterProp || filterForAPI, { limit: 1 });
    const userRes = await userUniview.fetchData({}, { orderBy: { name: "ASC" } });
    setUserData({
      ...userData,
      [workflowMode]: userRes.data
    });
    let userMapper: any = {};
    userRes.data?.forEach((user: any) => {
      userMapper[user.id] = user.name;
    })
    setAllUsers(prevValue => ({
      ...prevValue,
      ...userMapper
    }));
    let pendingValuesObj = {}, totalPending = 0;
    userRes.data.forEach((user: any) => {
      pendingValuesObj = {
        ...pendingValuesObj,
        [user.id]: user.pendingCount,
      }
      totalPending += user.pendingCount
    });
    const totalAssignedValue = (type !== 'save' && Object.values(values?.[workflowMode]).reduce((a: any, b: any) => a + b, 0) || 0);
    setTotalAssigned({
      data: totalAssignedValue,
      view: totalAssignedValue
    });
    setPendingValues({
      ...pendingValues,
      [workflowMode]: pendingValuesObj
    });
    setTotalPending({
      data: totalPending,
      view: totalPending
    });
    setTotalUnAssigned({
      data: importRes.count,
      view: importRes.count
    });
    setLoading(false);
  }

  const handleSave = async () => {
    setLoading(true);
    let viewName: string;
    let allValues: any = values;
    let flagArr = [];
    const result = await Promise.all(['workflowOne', 'workflowTwo', 'qc'].map(async (workflow) => {
      let flag: boolean = false;
      let userDistributions: any = [];

      Object.keys(allValues[workflow]).forEach((userId: any) => {
        if (allValues[workflow]?.[userId] > 0) {
          flag = true
          userDistributions.push({ userId: userId, userName: allUsers?.[userId], count: allValues[workflow]?.[userId] })
        }
      })
      if (!flag) {
        flagArr.push(0)
        return
      }
      switch (workflow) {
        case 'workflowOne':
          viewName = 'workflowOneUnAssigned'
          break;
        case 'workflowTwo':
          viewName = 'workflowTwoUnAssigned'
          break;
        case 'qc':
          viewName = 'qcUnAssigned'
          break;
        default:
          return;
      }
      const payload = {
        userDistributions,
        viewName: viewName,
        filter: {
          createdAt: {
            ">": moment(selectedDate?.[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
            "<": moment(selectedDate?.[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z'
          }
        },
      }
      return await importEngine.postAutomaticWorkAllocation(payload);
    }));
    if (flagArr.length >= 3) {
      ReNotification({
        type: 'warning',
        placement: 'bottomLeft',
        description: 'Please Assign Records',
      })
      return;
    }
    setLoading(false);
    result.forEach((res, i) => {
      if (res) {
        if (res?.status) {
          setConfirmationModalVisibility(true);
          ReNotification({
            description: `${totalUnAssigned.data === res?.count ? "All" : res?.count} ${workflowModessArr[i].value} Records Assigned.`,
            placement: "bottomLeft",
            type: "success",
          });
        } else {
          ReNotification({
            description: `Error in ${workflowModessArr[i].value} Records Assignment.`,
            placement: "bottomLeft",
            type: "error",
          });
        }
      }
    })
    setRecordAssignmentApiResponse(result);
    const filterForAPI = {
      createdAt: {
        ">": moment(selectedDate?.[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
        "<": moment(selectedDate?.[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z'
      },
    }
    handleLoad(filterForAPI, 'save');
    setValues(initialValue);
  }

  const handleRecordsDistribution = () => {
    if (totalUnAssigned.data === 0) {
      ReNotification({
        description: `No Records To Assign.`,
        placement: "bottomLeft",
        type: "error",
      });
      return;
    }
    if (userData[workflowMode]?.length === 0) {
      ReNotification({
        description: `No Users To Assign Records.`,
        placement: "bottomLeft",
        type: "error",
      });
      return;
    }
    let autoAssignment: any = {
      ...values[workflowMode],
    };
    const recordsDistributionData = distributeRecordsEvenly(totalUnAssigned?.view, userData[workflowMode]);
    userData[workflowMode]?.map((user: any) => {
      const userId = user.id;
      let total = (recordsDistributionData?.avgDistributedRecords || 0);
      autoAssignment?.[userId] && (total += autoAssignment?.[userId]);
      if (recordsDistributionData?.selectedUsers?.length && recordsDistributionData?.selectedUsers.includes(userId)) total += 1
      autoAssignment = {
        ...autoAssignment,
        [userId]: total,
      }
    });
    setTotalAssigned(totalUnAssigned);
    setTotalUnAssigned({
      data: totalUnAssigned.data,
      view: 0
    });
    setValues({
      ...values,
      [workflowMode]: autoAssignment
    });
  }

  const resetRecordAssignment = () => {
    setTotalAssigned({
      data: 0,
      view: 0
    })
    setTotalUnAssigned({
      data: totalUnAssigned.data,
      view: totalUnAssigned.data
    })
    setValues({
      ...values,
      [workflowMode]: {}
    })
  }

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ReModal
            title="Summary of Record Assignment"
            centered
            footer={null}
            visibility={confirmationModalVisibility}
            onCancel={() => { setConfirmationModalVisibility(false) }}
            children={<WorkAllocationConfirmationModalBody response={recordAssignmentApiResponse} userList={allUsers} />}
          />
          <div className="heading-wrapper">
            <h2>Automatic Work Allocation</h2>
            <Link to='/imports/manual-work-allocation?workflow=workflowOne'><h4>Go To Manual Work Allocation <RightOutlined /></h4></Link>
          </div>
          <div className="workflow-mode-wrapper">
            <div className='workflow-selector'>
              <div className="text">Workflow Type:</div>
              <Radio.Group
                optionType="button"
                value={workflowMode}
                onChange={(e) => {
                  setWorkFlowMode(e.target.value);
                }}
              >
                {workflowModessArr.map((item) => (
                  <Radio.Button key={item.key} value={item.key}>
                    {item.value}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <div className="dateFilter">
              <ReDatePickerDropDown
                date={selectedDate}
                setDate={setSelectedDate}
                selectedOption={selectedDateFilterOption}
                setSelectedOption={setSelectedDateFilterOption}
                dropDownItems={datePickerDropDownItems}
                defaultValue="month_-1"
              />
            </div>
          </div>
          <div className="work-allocation-wrapper">
            <div className="list-wrapper">
              <List
                bordered
                loading={isLoading}
                className='unilist'
                header={<ListHeader />}
                dataSource={userData[workflowMode] || []}
                renderItem={(user: any) => (
                  <List.Item className='list-item'>
                    <div className="column">{user?.name}</div>
                    <div className="column">
                      <Counter
                        values={pendingValues}
                        readOnly={true}
                        userId={user?.id || ''}
                        workflowMode={workflowMode}
                      />
                    </div>
                    <div className="column">
                      <Counter
                        values={values}
                        setValues={setValues}
                        readOnly={false}
                        userId={user?.id || ''}
                        workflowMode={workflowMode}
                        totalUnAssigned={totalUnAssigned}
                        setTotalUnAssigned={setTotalUnAssigned}
                        totalAssigned={totalAssigned}
                        setTotalAssigned={setTotalAssigned}
                      />
                    </div>
                  </List.Item>
                )}
              />
              <div className="button-wrapper">
                <Button size='middle' type='dashed' onClick={resetRecordAssignment}><RedoOutlined /></Button>
                <Button size='middle' type='default' onClick={handleRecordsDistribution}>Distribute Records Evenly</Button>
                <Button size='middle' type='primary' onClick={handleSave}>Assign</Button>
              </div>
            </div>
          </div>
          <div className="stats-cont">
            <Card size="small">
              <span>Total Unassigned Records</span>: <Badge count={totalUnAssigned.view || 0} showZero overflowCount={9999} />
            </Card>
          </div>
        </Styles.SubContainer>
      </div>
    </div>
  )
}

export default WorkAllocation