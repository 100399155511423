import { BaseAPI } from "../Common";
import { ICreateTenant, IUpdateTenant } from './Interfaces';

class Tenant extends BaseAPI {
    create(payload: ICreateTenant): Promise<any> {
        return this.makeRequest(`create`, "post", payload)
    }

    update(tenantId: string, payload: IUpdateTenant): Promise<any> {
        return this.makeRequest(`${tenantId}`, "patch", payload)
    }

    getAll(): Promise<any> {
        return this.makeRequest(`all`)
    }

    getTenant(tenantId: string): Promise<any> {
        return this.makeRequest(`${tenantId}`)
    }
}

export const tenant = new Tenant("tenant");
