import { UniviewService } from "../unity-ui-common/Classes/Uniview";
import { API_URL } from "./Config";

export type TUniView = "user" | "import-engine" | "audit-logs" | "controlled-vocabularies" | "masterData" | "tenant" | "role"

export type TViewName = "getAll" | "workflowOne" | "workflowTwo" | "qc" | "import" | "audit" | "all" | "country" | "dosageForms" | "outcome" | "routeOfAdministration" | "unitOfMeasurement" | "seriousness" | "importantMedicalEvent" | "designatedMedicalEvent" | "medra" | "product" | "labelAssessment" | "categorization" | 'activeIngredient' | "workflowOneUnAssigned" | "workflowTwoUnAssigned" | "qcUnAssigned" | "currentUserAssigned" | "getTableFIlterItems" | "ftp" | "tenantUser" | "allSponser" | "allUsers" | "allRoles"
export class Uniview extends UniviewService {
    constructor(uniView: TUniView, viewName: TViewName) {
        super(uniView, viewName, API_URL)
    }
}