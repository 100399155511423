import styled from "styled-components";

export const ModalBody = styled.div`
  .first-message{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    h3{
      margin: 0;
    }
    .icon{
      color: #DD2515;
      font-size: 1.75rem;
    }
  }
  .timer{
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
  }
  .second-message{
    text-align: center;
    padding-inline: 28px;
    font-size: 0.9rem;
  }
`;
