import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { importEngine } from "../../API/ImportEngine";
import { addCurrentAccessingCase } from "../../slices/Record/RecordSlices";
import { getAllCategorization } from "../../slices/Categorization/Helpers/getAllCategorization";
import CaseDetailView from "../../Components/Records/CasesDrawer/CaseDetailView";
import * as Style from './CaseViewStyles';

export default function CaseView() {

  const dispatch = useAppDispatch();
  const { caseId } = useParams();
  const { currentAccessingCase } = useAppSelector((store) => store.cases);

  const handleFetchCaseData = async () => {
    const res = caseId && (await importEngine.getCaseData(caseId));
    dispatch(addCurrentAccessingCase(res[0]));
  }

  useEffect(() => {
    if (Object.keys(currentAccessingCase).length === 0) {
      getAllCategorization();
      handleFetchCaseData();
    }
  }, [currentAccessingCase])

  if (Object.keys(currentAccessingCase).length) {
    return (
      <Style.MainWrapper>
        <div className="record-view-heading">{currentAccessingCase?.id}</div>
        <div className="component-wrapper">
          <CaseDetailView
            visibility={true}
            setVisibility={() => { }}
            type="view"
          />
        </div>
      </Style.MainWrapper>

    )
  }
  else {
    return (<></>)
  }
}