import * as styles from "./SupportIdGeneratorStyles";
import {
  RedoOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";

export const supportIdGenerator = () => {
  return Math.random().toString(36).substr(2, 9);
};

function SupportIdGenerator() {
  const [supportId, setSupportId] = useState<string>("");

  function handleRefresh() {
    setSupportId(supportIdGenerator());
  }

  useEffect(() => {
    setSupportId(supportIdGenerator());
  }, []);
  return (
    <styles.Container>
      <h3>Support ID</h3>
      <styles.Body>
        <styles.SubContainer>
          <FileProtectOutlined className="icon" />
          {/* <FileProtectOutlined /> */}
          <styles.Id>
            <h1>
              <span>#</span>
              {supportId.toUpperCase()}
            </h1>
            <h4>Your Support ID</h4>
          </styles.Id>
        </styles.SubContainer>
        <Button
          danger
          icon={<RedoOutlined />}
          size="large"
          onClick={handleRefresh}
        />
      </styles.Body>
    </styles.Container>
  );
}

export default SupportIdGenerator;
