import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAuditLogSearchPayload, auditlogs } from "../../../API/AuditLog";

const GetCaseHistory = createAsyncThunk(
    "audit-logs/GetCaseHistory",
    async (payload?: IAuditLogSearchPayload) => {
        try {
            const data = await auditlogs.getAuditLogs(payload)
            return data;
        } catch (err) {
            console.log("error");
        }
    }
);

export { GetCaseHistory };