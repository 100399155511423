import { createAsyncThunk } from "@reduxjs/toolkit";
import { importEngine } from "../../../API/ImportEngine";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

const assignCase = createAsyncThunk(
  "cases/assignCase",
  async (caseData: any) => {

    try {
      const res = await importEngine.create(caseData)
      if (res.status) {
        ReNotification({
          description: res.message,
          placement: "bottomLeft",
          type: "success"
        });
      }
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { assignCase };
