import { createAsyncThunk } from "@reduxjs/toolkit";
import {product} from "../../../API/Masters/Product/Product"

const GetAllProducts = createAsyncThunk(
  "product/GetAllProducts",
  async () => {
    try {
      const sponsorData = JSON.parse(sessionStorage.getItem("selectedWorkSpace") || '{}');
      let name = sponsorData.name
      const data = await product.getAll(name)
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { GetAllProducts };
