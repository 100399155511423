import { BaseAPI } from "./Common";

class File extends BaseAPI {
  getUrl(file_type: string) {
    let payload = { file_type };
    return this.makeRequest("upload", "post", payload).then(response => {
      return response;
    });
  }

  // Upload file to external S3-compatible endpoint
  async remoteUploadFile(url: string, file: any) {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        // "x-amz-acl": "public-read",
        "Content-Type": file.type,
      },
    });
    return response;
  }

  async getSignedUrls(id: string, payload: {
    urlType: string // view or download
  }) {
    return this.makeRequest(id, "get", payload)
  }
}

export const file = new File("files");
