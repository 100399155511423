import { createSlice } from "@reduxjs/toolkit";
import { UserTypes } from "../../Components/Types/TableColumn.types";

interface SessionStorageState {
    selectedTenantId: string,
    selectedTenantName: string,
    selectedTenantRoles: string[],
    userPermissions: string[],
    userAccessToken: string,
    refreshToken: string,
    userData: UserTypes | null
}

const initialState: SessionStorageState = {
    selectedTenantId: '',
    selectedTenantName: '',
    selectedTenantRoles: [],
    userPermissions: [],
    userAccessToken: '',
    refreshToken: '',
    userData: null
};

const SessionStorageSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        handleUserLogin: (state, action) => {
            state.selectedTenantId = action.payload?.selectedTenantId || state.selectedTenantId;
            state.selectedTenantName = action.payload?.selectedTenantName || state.selectedTenantName;
            state.selectedTenantRoles = action.payload?.selectedTenantRoles || state.selectedTenantRoles;
            state.userPermissions = action.payload?.userPermissions || state.userPermissions;
            state.userAccessToken = action.payload?.userAccessToken || state.userAccessToken;
            state.refreshToken = action.payload?.refreshToken || state.refreshToken;
            state.userData = action.payload?.userData || state.userData;
        },
    },
});

export const { handleUserLogin } = SessionStorageSlice.actions;
export default SessionStorageSlice.reducer;