import { Form, Drawer, Switch } from "antd";
import { useEffect, useState } from "react";
import { ingredient } from "../../../API/Masters/ActiveIngredient/ActiveIngredient";
import { useAppDispatch } from "../../../app/hooks";
import { createActiveIngredient } from "../../../slices/ActiveIngredient/Helpers/createActiveIngredient";
import { getAllIngredient } from "../../../slices/ActiveIngredient/Helpers/getAllIngredients";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function ActiveIngredientDrawer(props: {
  drawerType: string;
  visibility: boolean;
  setVisibility: Function;
  currentEditingIngredient?: any;
}) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { drawerType, visibility, setVisibility, currentEditingIngredient } =
    props;
  const [switchStatus, setSwitchStatus] = useState(0);

  const onClose = () => {
    setVisibility({
      visibility: false,
    });
    setSwitchStatus(0);
    form.resetFields();
  };

  const submitFormHandler = async (values: any) => {
    const payload = {
      name: values.name,
      ATCCode: values.ATCCode,
      status: switchStatus,
      uniqueID: currentEditingIngredient?.id,
    };
    if (drawerType === "create") {
      dispatch(createActiveIngredient(payload));
    } else if (drawerType === "edit") {
      const apiResponse = await ingredient.update(
        currentEditingIngredient.uniqueID,
        payload
      );
      ReNotification({
        description: `Active-Ingredient ${
          apiResponse?.id ? "Updated Successfully" : "Update Fail"
        }.`,
        type: "success",
      });
    }
    onClose();
    dispatch(getAllIngredient());
  };

  useEffect(() => {
    dispatch(getAllIngredient());
    if (drawerType === "edit") {
      form.setFieldsValue({
        name: currentEditingIngredient?.name,
        ATCCode: currentEditingIngredient?.ATCCode,
        uniqueID: currentEditingIngredient.uniqueID,
      });
      setSwitchStatus(currentEditingIngredient?.status);
    }
  }, [drawerType]);

  return (
    <div>
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        title={
          drawerType === "edit"
            ? "Update Active Ingredient"
            : "Create Active Ingredient"
        }
      >
        <ReusableForm
          formInstance={form}
          onSubmit={submitFormHandler}
          submitBtn
        >
          <ReInput label="Name" name="name" required type="simple" />
          <ReInput label="ATC Code" name="ATCCode" required type="simple" />
          <Form.Item label="Status" rules={[{ required: true }]}>
            <Switch
              checked={switchStatus === 1}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => {
                setSwitchStatus(checked ? 1 : 0);
              }}
            />
          </Form.Item>
        </ReusableForm>
      </Drawer>
    </div>
  );
}

export default ActiveIngredientDrawer;
