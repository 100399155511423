import { createAsyncThunk } from "@reduxjs/toolkit";
import {product} from "../../../API/Masters/Product/Product"

const createProduct = createAsyncThunk(
  "product/createProduct",
  async (prodData:any) => {
    try {
      const data = await product.create(prodData)
      return data;
      
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { createProduct };
