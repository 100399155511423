import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import * as Styles from "./KPIHistoryStyles";
import { Descriptions, Tag } from "antd";
import { convertDateInReadableFormat } from "../../../Healper/Methods";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";

function KPIHistory() {
  const { currentAccessingCase } = useAppSelector((store) => store.cases);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const data = [
      {
        type: "Workflow One",
        dueDate: currentAccessingCase.completionStatus["0"]?.dueDate
          ? convertDateInReadableFormat(
            currentAccessingCase.completionStatus["0"].dueDate
          )
          : "-",
        completionDate: currentAccessingCase.completionStatus["0"]
          ?.completionDate
          ? convertDateInReadableFormat(
            currentAccessingCase.completionStatus["0"].completionDate
          )
          : "-",
        colorByStatus:
          currentAccessingCase.completionStatus["0"]?.status === 1
            ? "green"
            : "red",
      },
      {
        type: "Workflow Two",
        dueDate: currentAccessingCase.completionStatus["1"]?.dueDate
          ? convertDateInReadableFormat(
            currentAccessingCase.completionStatus["1"].dueDate
          )
          : "-",
        completionDate: currentAccessingCase.completionStatus["1"]
          ?.completionDate
          ? convertDateInReadableFormat(
            currentAccessingCase.completionStatus["1"].completionDate
          )
          : "-",
        colorByStatus:
          currentAccessingCase.completionStatus["1"]?.status === 1
            ? "green"
            : "red",
      },
      {
        type: "Quality Check",
        dueDate: currentAccessingCase.completionStatus["3"]?.dueDate
          ? convertDateInReadableFormat(
            currentAccessingCase.completionStatus["3"].dueDate
          )
          : "-",
        completionDate: currentAccessingCase.completionStatus["3"]
          ?.completionDate
          ? convertDateInReadableFormat(
            currentAccessingCase.completionStatus["3"].completionDate
          )
          : "-",
        colorByStatus:
          currentAccessingCase.completionStatus["3"]?.status === 1
            ? "green"
            : "red",
      }
    ];
    setData(data);
  }, []);

  const kpiTableColumn = [
    {
      title: "Workflow Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Due Date",
      key: "dueDate",
      dataIndex: "dueDate",
    },
    {
      title: "Completion Date",
      key: "completionDate",
      dataIndex: "completionDate",
      render: (data: string, record: any) => {
        return data === '-' ? <Tag>N/A</Tag> : <Tag color={record.colorByStatus}>{data}</Tag>;
      }
    },
  ];


  return (
    <ReTable
      name="kpi"
      title="KPI"
      columns={kpiTableColumn}
      data={data}
    />
  );
}

export default KPIHistory;
