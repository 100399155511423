import Styled from 'styled-components';

export const SubContainer = Styled.div`
    .formIitems{
        padding: 0.5rem 1rem ;
        display: flex;
        align-items: flex-end;
        background-color: #ffffff;
        gap: 1rem;
        .ant-form-item{
            margin-bottom: 0;
        }
        >*{
            width: 250px
        }
    }
`;
export const Tables = Styled.div``;