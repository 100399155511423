import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Header, SideMenu } from "../../../Components";
import CategorizationDrawer from "../../../Components/Master/CategorizationDrawer/CategorizationDrawer";
import * as Styles from "./CategorizationStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../../../Components/ControlledVocabularies/common";
import { API_URL } from "../../../API/Config";

function Categorization() {
  const [categorizationDrawer, setCategorizationDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [currentEditingCategory, setCurrentEditingCategory] = useState({});
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [flag, setFlag] = useState(false);
  
  const columns = [
    {
      title: "",
      width: 40,
      render: (record: any) => {
        if (record.editable) {
          // Check if categorization is editable
          return (
            <Tooltip title="Edit Category">
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCurrentEditingCategory(record);
                  setCategorizationDrawer({
                    visibility: true,
                    type: "edit",
                  });
                }}
                />
            </Tooltip>
          );
        }
        return null; // Return null if categorization is not editable
      },
    },
    {
      title: "Categorization",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];
  
  useEffect(() => {
    if(categorizationDrawer.visibility === false){
      setFlag(prevValue=>!prevValue);
    }
  }, [categorizationDrawer]);
  
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <CategorizationDrawer
            drawerType={categorizationDrawer.type}
            visibility={categorizationDrawer.visibility}
            setVisibility={setCategorizationDrawer}
            currentEditingCategory={currentEditingCategory}
            />
          <ReTable
            refresh={flag}
            name="categorization"
            title="Categorization"
            columns={columns}
            scroll={{
              x: 800,
              y: 500,
            }}
            rowSelection={{
              selectedRowKeys: selectedTableRow,
              type: "checkbox",
              setSelectedRowKeys: setSelectedTableRow,
              rowKey: "id",
            }}
            columnOptions={{
              sorting: {
                columns: ["name"],
              },
              filter: {
                columns: ["name"],
              },
            }}
            headerButtons={[
              {
                title: "Add New",
                type: "primary",
                icon: <UserAddOutlined />,
                onClick: () => {
                  setCategorizationDrawer({
                    visibility: true,
                    type: "create",
                  });
                },
              },
            ]}
            uniView={{
              uniView: "masterData",
              viewName: "categorization",
              apiUrl: API_URL,
              option: {
                orderBy: {
                  key: "name",
                  order: "ASC",
                },
                limit: 10
              },
            }}
            exportOption={{
              csv: {},
            }}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default Categorization;
