import { API_URL } from "./Config";

async function makePostRequest(
  path: string,
  payload?: any,
  multipart?: boolean
) {
  try {
    let headers = getHeaders(multipart);
    let body = payload;
    if (!multipart) body = JSON.stringify(payload);
    const res = await fetch(API_URL + path, {
      method: "POST",
      headers: headers,
      body: body,
      credentials: "include"
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function makePatchRequest(
  path: string,
  payload?: any,
  multipart?: boolean
) {
  try {
    let headers = getHeaders(multipart);
    let body = payload;
    if (!multipart) body = JSON.stringify(payload);
    const res = await fetch(API_URL + path, {
      method: "PATCH",
      headers: headers,
      body: body,
      credentials: "include"

    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function makeGetRequest(path: string, payload?: any) {
  try {
    let url = API_URL + path;
    if (payload) url = url + "?" + new URLSearchParams(payload);
    let headers = getHeaders();
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
      credentials: "include"

    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function makeRequest(
  path: string,
  method: string = "get",
  payload?: any,
  multipart?: boolean
): Promise<any> {
  if (method === "post") return makePostRequest(path, payload, multipart);
  else if (method === "patch") return makePatchRequest(path, payload, multipart);
  else return makeGetRequest(path, payload);
}

function getHeaders(multipart?: boolean) {
  let headers = {};
  if (!multipart)
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  const user = JSON.parse(sessionStorage.getItem("user") || "{}");
  if (user && user.user) {
    let userData = user.user
    userData['permission'] = user && user.permission ? user.permission : []
  }
  if (user && user.accessToken) {
    headers = { ...headers, Authorization: `Bearer ${user.accessToken}`, user: JSON.stringify(user.user) };
  }

  return headers;
}

export class BaseAPI {
  path: string;
  constructor(path: string) {
    this.path = path;
  }

  async makeRequest(
    endpoint: string,
    method: string = "get",
    payload?: any,
    multipart?: boolean
  ) {
    let fullPath = endpoint !== "" ? this.path + "/" + endpoint : this.path;
    let res = await makeRequest(fullPath, method, payload, multipart);

    if (res && res.statusCode === 401 && endpoint !== "login") {
      sessionStorage.removeItem("user");
      let current = window.location.pathname;
      sessionStorage.setItem("redirect", current);
      window.location.href = "/";
    }
    else if (res && res.statusCode === 403) {
      let current = window.location.pathname;
      sessionStorage.setItem("redirect", current);
      window.location.href = "/unauthorized";
    } else return res;
  }
}
