import * as Styles from "./LoginFormStyles";
import { UserOutlined } from "@ant-design/icons";
import { Form, Space, Alert, Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import ReOTP from "../../unity-ui-common/Components/ReOTP";
import { Link, useNavigate } from "react-router-dom";
import LogoWithText from "../../Assets/logo.svg";
import { auth } from "../../API/Auth";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { useAppDispatch } from "../../app/hooks";
import { handleUserLogin } from "../../slices/Session/Session";
import PPAndTCModal from "./PPAndTCModal";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { ReactComponent as SessionExpireIcon } from "../../Assets/SessionExpireIcon.svg";

function LoginForm(props: { noLogout?: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // STATES **********
  const [email, setEmail] = useState<string>("");
  const [accessCode, setAccessCode] = useState<string>("");
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [sec, setSec] = useState<number>(0);
  const [min, setMin] = useState<number>(15);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] =
    useState<boolean>(false);
  const [TCAndPPModalProps, setTCAndPPModalProps] = useState<any>({
    visibility: false,
    type: "PP",
  });
  const [isUnauth, setIsUnauth] = useState<boolean>(false);

  useEffect(() => {
    if (props?.noLogout) {
      return;
    }
    if (sessionStorage.getItem("unauth")) {
      setIsUnauth(true);
    }
    sessionStorage.removeItem("unauth");

    auth.logout();
  }, []);

  const handleLogin = async () => {
    if (accessCode.length !== 6) {
      ReNotification({
        description: "Please fill all the fields",
        type: "error",
      });
      return;
    }
    let res = await auth.login(email, accessCode);
    dispatch(handleUserLogin({
      userData: res.user,
      userAccessToken: res.accessToken,
      refreshToken: res.refreshToken,
    }));
    if (res && res.statusCode === 401) {
      ReNotification({
        description: "Access Code is Incorrect",
        type: "error",
      });
      return;
    }
    if (res && res.accessToken) {
      // debugger;
      let redirect = sessionStorage.getItem("redirect");
      sessionStorage.removeItem("redirect");
      if (redirect) {
        navigate(redirect);
      } else {
        if (
          auth.getCurrentRole() === 4 ||
          auth.getCurrentRole() === 3 ||
          auth.getCurrentRole() === 5
        )
          navigate("/notifications", { replace: true });
        else navigate("/workspace", { replace: true });
      }
    } else if (res.statusCode === 404) {
      ReNotification({
        description: "User not registered",
        type: "error",
      });
    } else {
      ReNotification({
        description: "Access Code is Incorrect",
        type: "error",
      });
    }
  };

  const handleSendOTP = async (values: any, type?: string) => {
    if (type === 'otpReset') {
      countDown('restart');
      ReNotification({
        description: "Access Code has been re-sent",
        type: "info",
      });
    }
    setEmail(values.email);
    const res = await auth.getOTP(values.email);
    if (res.status) {
      setIsCodeSent(true);
    } else {
      ReNotification({
        description: res.message || "Email ID Does Not Exist",
        type: "error",
      });
    }
  };

  function countDown(action?: string) {
    if (action === 'restart') setMin(15);
    setTimeout(() => {
      if (sec <= 0) {
        if (min === 0 && sec === 0) {
          return;
        }
        setSec(59);
        setMin(min - 1);
      } else {
        setSec(sec - 1);
      }

      if (min === 0 && sec === 0) {
        setAccessCode("");
      }
    }, 1000);
  }

  const handleTCAndPPClicks = (type: "TC" | "PP") => {
    setTCAndPPModalProps((pre: any) => {
      return {
        ...pre,
        type: type,
        visibility: true,
      };
    });
  };

  useEffect(() => {
    if (isCodeSent) {
      countDown();
    }
  }, [isCodeSent, min, sec]);

  useEffect(() => {
    setEmail(email || "");
    if (auth.isAuthenticated()) {
      navigate("/");
    }
  }, []);

  const handleFormSubmit = (values: any) => {
    if (isCodeSent) {
      handleLogin();
    } else {
      handleSendOTP(values);
    }
  };

  return (
    <Styles.Container>
      <PPAndTCModal
        visibility={TCAndPPModalProps.visibility}
        setVisibility={setTCAndPPModalProps}
        type={TCAndPPModalProps.type}
      />
      <ReModal
          visibility={isUnauth}
          centered
          width={500}
          title="Session Timeout"
          onCancel={() => {
            setIsUnauth(false);
          }}
          footer={
            <Button
              size="middle"
              type="primary"
              onClick={() => {
                setIsUnauth(false);
              }}
            >
              Login
            </Button>
          }
        >
          <Styles.ModalBody>
            <div className="icon">
              <SessionExpireIcon />
            </div>
            <div>
              <h3>You have been logged out.</h3>
              <p>You are logged out after 15 minutes of inactivity.</p>
              <p>Please login again.</p>
            </div>
          </Styles.ModalBody>
        </ReModal>
      <img src={LogoWithText} alt="logo with text" />
      <ReusableForm
        formInstance={form}
        onSubmit={handleFormSubmit}
        className="loginForm"
      >
        <h2>Login</h2>
        {!isCodeSent && (
          <h3>You will receive a Secure Access Code on your email address</h3>
        )}
        <Space direction={"vertical"} size={[0, 17]} className="Fields">
          {!isCodeSent && (
            <>
              <ReInput
                label=""
                placeholder="Enter Your Email Address"
                name="email"
                type="email"
                prefix={<UserOutlined />}
              />
              <Checkbox
                onChange={(e) => {
                  setIsPrivacyPolicyChecked(e.target.checked);
                }}
              >
                <span style={{ fontSize: "12px" }}>
                  By clicking Request Secure Access Code, you confirm that you
                  have read and are in agreement with the{" "}
                  {
                    <Link
                      to=""
                      onClick={() => {
                        handleTCAndPPClicks("TC");
                      }}
                    >
                      Terms & Conditions
                    </Link>
                  }{" "}
                  and{" "}
                  {
                    <Link
                      to=""
                      onClick={() => {
                        handleTCAndPPClicks("PP");
                      }}
                    >
                      Privacy Policy
                    </Link>
                  }
                  .
                </span>
              </Checkbox>
            </>
          )}
          {isCodeSent && (
            <>
              <Alert
                message={`A Secure Access Code has been sent to ${email.replace(
                  /(..)(.{1,20})(?=.*@)/g,
                  (_, a, b) => a + "*".repeat(b.length)
                )}`}
                type="info"
              />
              <ReOTP
                onChange={(otpValue) => {
                  setAccessCode(otpValue);
                }}
              />
            </>
          )}
          <Button type="primary" size="large" block htmlType="submit" disabled={isCodeSent ? false : !isPrivacyPolicyChecked}>
            {isCodeSent ? "Login" : "Request Secure Access Code"}
          </Button>
          {isCodeSent && (
            <>
              {min === 0 && sec === 0 ?
                <Button
                  type="default"
                  onClick={() => handleSendOTP({ email }, 'otpReset')}
                  style={{ padding: "4px 7px 5px 7px" }}
                  disabled={min === 0 && sec === 0 ? false : true}
                >
                  Resend Secure Access Code
                </Button> :
                <p style={{ color: "gray", margin: "0" }}>
                  Your Secure Access Code is valid for the next{" "}
                  <span style={{ fontWeight: "800" }}>{`${min < 10 ? `0${min}` : min
                    }:${sec < 10 ? `0${sec}` : sec}`}</span>
                </p>}
            </>
          )}
        </Space>
      </ReusableForm>
    </Styles.Container>
  );
}

export default LoginForm;
