import { createAsyncThunk } from "@reduxjs/toolkit";
import { importEngine } from "../../../API/ImportEngine";

const GetCaseStats = createAsyncThunk(
    "dashboard/GetCasesStats",
    async () => {
        try {
            const data = await importEngine.getCasesStats()
            return data;
        } catch (err) {
            console.log("error");
        }
    }
);

export { GetCaseStats };