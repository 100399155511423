export const loadState = () => {
    try {
      const serialState = sessionStorage.getItem("appState");
      if (serialState === null) {
        return undefined;
      }
      const state = JSON.parse(serialState);
  
      const userAccessToken =
        sessionStorage.getItem("userAccessToken")?.toString() ||
        state.session?.userAccessToken ||
        "";
      const userData = sessionStorage.getItem("userData")
        ? JSON.parse(sessionStorage.getItem("userData") || "")
        : state.session?.userData || {};
      const userPermissions = sessionStorage.getItem("userPermissions")
        ? JSON.parse(sessionStorage.getItem("userPermissions") || "")
        : state.session?.userPermissions || [];
      const selectedTenant = sessionStorage.getItem("selectedTenant")
        ? JSON.parse(sessionStorage.getItem("selectedTenant") || "")
        : state.session?.selectedTenant || {};
      return {
        ...state,
        session: {
          ...state.session,
          userAccessToken,
          userData,
          userPermissions,
          selectedTenant,
        },
      };
    } catch (err) {
      return null;
    }
  };
  
  export const saveState = async (state: any) => {
    try {
      const serialStatePresent = await sessionStorage.getItem("appState");
      let savingJson = {};
      let totalAppState = {};
      if (serialStatePresent !== null) {
        totalAppState = JSON.parse(serialStatePresent);
        savingJson = JSON.parse(serialStatePresent).session;
      }
      totalAppState = {
        ...totalAppState,
        ...{
          session: {
            ...savingJson,
            ...state,
          },
        },
      };
      const serialState = JSON.stringify(totalAppState);
      if (state.userAccessToken)
        await sessionStorage.setItem("userAccessToken", state.userAccessToken);
      if (state.userData)
        await sessionStorage.setItem("userData", JSON.stringify(state.userData));
      if (state.userPermissions)
        await sessionStorage.setItem(
          "userPermissions",
          JSON.stringify(state.userPermissions)
        );
      if (state.selectedTenant)
        await sessionStorage.setItem(
          "selectedTenant",
          JSON.stringify(state.selectedTenant)
        );
      await sessionStorage.setItem("appState", serialState);
    } catch (err) {
      console.log(err);
    }
  };
  export const initializeState = async (state: any) => {
    try {
      const serialState = JSON.stringify(state);
      await sessionStorage.setItem("appState", serialState);
    } catch (err) {
      console.log(err);
    }
  };
  export const clearState = () => {
    try {
      sessionStorage.removeItem("appState");
      sessionStorage.clear();
    } catch (err) {
      console.log(err);
    }
  };
  