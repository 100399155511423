import moment from "moment";
import { BaseAPI } from "./Common";
import { IAssignUser } from "./ImportInterface";
import { getApiUrl } from "./Utils";

class ImportEngine extends BaseAPI {
    importRecords(source: string, formData: any): Promise<any> {
        const payload = {
            formData,
            source
        }
        return this.makeRequest(`import/${source}`, "post", payload)
    }

    getMany(): Promise<any> {
        return this.makeRequest(`getMany/all`)
    }
    getAssigned(): Promise<any> {
        return this.makeRequest(`getMany/assigned`)
    }

    getUnAssigned(): Promise<any> {
        return this.makeRequest(`getMany/unAssigned`)
    }

    create(payload: IAssignUser): Promise<any> {
        return this.makeRequest(`assign`, "post", payload);
    }

    createWorkFlowOne(payload: any, caseId: number): Promise<any> {
        return this.makeRequest(`move-stage/${caseId}`, "post", payload);
    }

    createWorkFlowTwo(payload: any, caseId: number): Promise<any> {
        return this.makeRequest(`move-stage/${caseId}`, "post", payload);
    }

    duplicate(caseId: string, payload: {
        duplicateComment: string,
        duplicate: number;
        duplicateOf: string[]
    }): Promise<any> {
        return this.makeRequest(`duplicate/${caseId}`, "post", payload)
    }

    createVersion(caseId: string, payload: any): Promise<any> {
        return this.makeRequest(`create-version/${caseId}`, "post", payload)
    }

    changeFtpAndTranslation(payload: any): Promise<any> {
        return this.makeRequest(`changeFtp`, "post", payload)
    }

    searchCase(payload: {
        citation?: string,
        articleTitle?: string,
        createdDate?: string,
        referenceID?: string
        abstract?: string;
        authors?: string;
    }) {
        let searchUrl = "search?"
        for (const [key, value] of Object.entries(payload)) {
            if (value !== undefined) {
                searchUrl += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
            }
        }
        return this.makeRequest(searchUrl, "get")
    }

    async export(caseId: string): Promise<any> {
        const user = JSON.parse(sessionStorage.getItem("user") || "{}");
        const url = `${getApiUrl()}import-engine/export/${caseId}`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`
        }
        const response = await fetch(url, { headers: headers, method: "GET", credentials: "include" })
        let blob, errorObj, res = { status: false };
        let contentType = response.headers.get('Content-Type')
        if (contentType?.includes('text/xml')) {
            blob = await response.blob()
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `${caseId}-XMLExport.xml`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            res = {
                status: true
            };
        }
        else {
            errorObj = await response.json();
            res = errorObj;
        }

        return res;
    }

    getCasesStats(): Promise<any> {
        return this.makeRequest(`caseCount`);
    }

    GetCategorizationStats(): Promise<any> {
        return this.makeRequest(`categorizationCount`);
    }

    UpdateCase(caseId: string, payload: any): Promise<any> {
        return this.makeRequest(`case/${caseId}`, "patch", payload)
    }

    searchRecord(payload: any): Promise<any> {
        return this.makeRequest('searchRecord', "post", payload)
    }

    generateAssesmentReport(payload: any, fileName: string): Promise<any> {
        const user = JSON.parse(sessionStorage.getItem("user") || "{}");
        const url = `${getApiUrl()}import-engine/generateReport`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`
        }
        const res = fetch(url, { headers: headers, method: "POST", credentials: "include", body: JSON.stringify(payload) },)
            .then((response: any) => {
                return response.blob().then((blob: any) => ({ filename: `${fileName} ${moment().format("DD/MMM/YYYY hh-mm-ss A")}.pdf`, blob }));
            })
            .then(({ filename, blob }) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                return {
                    statusCode: 200
                }
            })
            .catch(error => {
                console.error(error)
                return {
                    statusCode: 500
                }
            });
        return res
    }

    generateReport(payload: any, fileName: string): Promise<any> {
        const user = JSON.parse(sessionStorage.getItem("user") || "{}");
        const url = `${getApiUrl()}import-engine/searchAndGenerateReport`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`
        }
        const res = fetch(url, { headers: headers, method: "POST", credentials: "include", body: JSON.stringify(payload) },)
            .then((response: any) => {
                return response.blob().then((blob: any) => ({ filename: `${fileName} ${moment().format("DD/MMM/YYYY hh-mm-ss A")}.pdf`, blob }));
            })
            .then(({ filename, blob }) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                return {
                    statusCode: 200
                }
            })
            .catch(error => {
                console.error(error)
                return {
                    statusCode: 500
                }
            });
        return res
    }

    getCaseData(caseId: string): Promise<any> {
        return this.makeRequest(`viewCase/${caseId}`);
    }

    async generateMedwatchCiomes(caseId: string, type: string): Promise<any> {
        const user = JSON.parse(sessionStorage.getItem("user") || "{}");
        const url = `${getApiUrl()}import-engine/generate?type=${type}`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`
        }
        const res = fetch(url, { headers: headers, method: "GET", credentials: "include" })
            .then((response: any) => {
                return response.blob().then((blob: any) => ({ filename: `${caseId}.pdf`, blob }));
            })
            .then(({ filename, blob }) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                return {
                    statusCode: 200
                }
            })
            .catch(error => {
                console.error(error)
                return {
                    statusCode: 500
                }
            });
        return res
    }
    getDashboardData(payload?: {
        startDate: string,
        endDate: string
        rangeType: string
        queryStartDate: string
        queryEndDate: string
    }): Promise<any> {
        return this.makeRequest(`dashboardData`, "post", payload)
    }

    getDashboardUserAssignnmentChartData(payload?: {
        startDate: string,
        endDate: string
        rangeType: string
        queryStartDate: string
        queryEndDate: string
    }): Promise<any> {
        return this.makeRequest(`dashboardData/user-assignment`, "post", payload)
    }
    postAutomaticWorkAllocation(payload?: {
        userDistributions: any,
        viewName: string
    }): Promise<any> {
        return this.makeRequest(`caseAssignment`, "post", payload)
    }

    shareCase(payload: any): Promise<any> {
        return this.makeRequest(`shareCase`, "post", payload)
    }

    async getQcAuditLogs() {
        return await this.makeRequest("qcconfig/data").then((response) => {
            return response
        })
    }
}

export const importEngine = new ImportEngine("import-engine");