import { createAsyncThunk } from "@reduxjs/toolkit";
import { permission } from "../../../API/UserManagement/Permission";

const getAllPermission = createAsyncThunk(
  "permission/getAllPermission",
  async () => {
    try {
      const data = await permission.getAll();
      return data
      
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllPermission };
