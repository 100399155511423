import { createSlice } from "@reduxjs/toolkit";
import { createLabelAssessment } from "./Helpers/createLabelAssessment";
import { getAllLabelAssessment } from "./Helpers/getAllLabelAssessment";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

type LabelApiType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: number;
  name: string;
};
interface LabelState {
  allLabelAssessment: LabelApiType[];
  labelLoading: boolean;
  error: string;
  createLoading: boolean

}

const initialState: LabelState = {
  allLabelAssessment: [],
  labelLoading: true,
  error: "",
  createLoading:true
};

const LabelAssessmentSlice = createSlice({
  name: "label",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllLabelAssessment.pending, (state) => {
      state.labelLoading = true;
    });
    builder.addCase(getAllLabelAssessment.fulfilled, (state, action) => {
      state.allLabelAssessment = action.payload;
      state.labelLoading = false;
    });
    builder.addCase(getAllLabelAssessment.rejected, (state) => {
      state.error = "Something went wrong";
      state.labelLoading = false;
    });
    builder.addCase(createLabelAssessment.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(createLabelAssessment.fulfilled, (state, action) => {
      state.allLabelAssessment = [...state.allLabelAssessment, action.payload];
      ReNotification({
        description: "Label Assessment Created Successfully",
        type: "success",
      });
      state.createLoading = false;
    });
    builder.addCase(createLabelAssessment.rejected, (state) => {
      state.error = "Something went wrong";
      state.createLoading = false;
    });
  },
});

export default LabelAssessmentSlice.reducer;
