import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";

interface IItems {
  title: string;
  value: string | number | boolean;
}

function ReSelect(props: {
  allowClear?: boolean;
  noStyle?: boolean;
  label: string;
  name: string;
  items: IItems[];
  type?: "multiple" | "tags" | undefined;
  searchable?: boolean;
  required?: boolean;
  disable?: boolean;
  onChange?: Function;
  form?: any;
  placeholder?: string;
  autoFocus?: boolean;
  value?: any;
  defaultValue?: string;
  style?: object;
}) {
  const {
    allowClear,
    noStyle,
    label,
    name,
    items,
    type,
    required,
    searchable,
    disable,
    form,
    onChange,
    placeholder,
    autoFocus,
    value,
    defaultValue,
    style
  } = props;
  const [dropDownoptions, setDropDownoptions] = useState<IItems[]>([]);
  const [rules, setRules] = useState<any[]>([]);

  useEffect(() => {
    setDropDownoptions(items);
  }, [items]);

  useEffect(() => {
    setRules(
      required ? [{ required: true, message: `Please Enter The ${label}` }] : []
    );
  }, [required, disable, form]);

  const handleOnChange = (changedValues: any, allValues: any) => {
    onChange && onChange(changedValues, allValues);
  };

  const handleSearch = (value: string) => {
    if (searchable && value?.length) {
      let filterOptions: IItems[] = [];
      items?.forEach((data) => {
        if (data.title.toLowerCase().includes(value.toLowerCase())) {
          filterOptions.push(data);
        }
      });
      setDropDownoptions(filterOptions);
    }
    if (value?.length === 0) {
      setDropDownoptions(items);
    }
  };

  return (
    <Form.Item label={label} name={name} rules={rules} noStyle={noStyle}>
      <Select
        disabled={disable}
        allowClear={allowClear === undefined ? true : allowClear}
        mode={type}
        showSearch={searchable}
        onChange={handleOnChange}
        placeholder={placeholder || ""}
        onSearch={(e) => {
          handleSearch(e);
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        autoFocus={autoFocus}
        value={value}
        defaultValue={defaultValue}
        style={style}
      >
        {dropDownoptions.map((item: any) => {
          const { value, title } = item;
          return (
            <Select.Option value={value} disabled={item?.disable || false}>
              {title}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default ReSelect;
