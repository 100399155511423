import { Header, ReportForm, SideMenu } from "../../Components";
import * as Styles from "./AddEditReportStyles";

function AddEditReport() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ReportForm />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default AddEditReport;
