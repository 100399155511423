export const caseTypes = [
    {
        title: "Literature",
        value: 0
    },
    {
        title: "Spontaneous",
        value: 1
    },
    {
        title: "Clinical Study",
        value: 2
    },
]

export const sourceTypes = [
    {
        title: "PubMed",
        value: 0
    },
    {
        title: "Embase",
        value: 1
    },
    {
        title: "Local Literature",
        value: 2
    },
]