import { createSlice } from "@reduxjs/toolkit";
import { getAllCountries } from "./Helpers/getAllCountries";
import { getAllDme } from "./Helpers/getAllDME";
import { getAllDosageForms } from "./Helpers/getAllDosageForms";
import { getAllIme } from "./Helpers/getAllIME";
import { getAllMedra } from "./Helpers/getAllMedra";
import { getAllOutcomes } from "./Helpers/getAllOutcome";
import { getAllRouteOfAdministration } from "./Helpers/getAllRouteOfAdministartion";
import { getAllSeriousness } from "./Helpers/getAllSeriousness";
import { getAllUnits } from "./Helpers/getAllUnits";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
export type CountryTypes = {
  countryCode: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  alpha2: string;
  status: number;
};

export type DosageType = {
  createdAt: string;
  updatedAt: string;
  name: string;
  code: number;
  shortName: string;
  form: string;
  versionSEQ: number;
  status: number;
};
export type OutComeType = {
  createdAt: string;
  updatedAt: string;
  label: string;
  code: number;
  currentFlag: string;
  status: number;
};

export type RouteOfAdministrationType = {
  createdAt: string;
  updatedAt: string;
  label: string;
  code: number;
  currentFlag: string;
  status: number;
};

export type UnitOfMeasurementType = {
  ucumCode: string;
  createdAt: string;
  updatedAt: string;
  label: string;
  currentFlag: string;
  status: number;
};

export type SeriousType = {
  slug: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  status: number;
};

export type IMEType = {
  code: string;
  createdAt: string;
  updatedAt: string;
  PTName: string;
  SOCName: string;
  status: number;
};

export type DMEType = {
  slug: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  status: number;
};

export type MedraType = {
  lltCode: number;
  lltName: string;
  ptCode: number;
  ptName: string
  hltCode: number;
  hltName: string;
  hlgtCode: number;
  hlgtName: string;
  socCode: number;
  socName: string;
  socAbbrev: string;
  version: string;
  status: number;

}


interface ContolledVocabulaiesState {
  allCountries: CountryTypes[];
  allDosageForm: DosageType[];
  allOutcome: OutComeType[];
  allRouteOfAdministration: RouteOfAdministrationType[];
  allSeriousness: SeriousType[];
  allUnits: UnitOfMeasurementType[]
  allIME: IMEType[];
  allDME: DMEType[];
  allMedra: MedraType[]
  loading: boolean;
  error: string;
  countryLoading: boolean;
  medDraLoading: boolean,
  dmeLoading: boolean,
  imeLoading: boolean,
  seriousnessLoading: boolean,
  outcomeLoading: boolean,
  uomLoading: boolean,
  routeLoading: boolean,
  dosageLoading: boolean,
  routeAdministrationLoading: boolean
}

const initialState: ContolledVocabulaiesState = {
  allCountries: [],
  allDosageForm: [],
  allOutcome: [],
  allRouteOfAdministration: [],
  allSeriousness: [],
  allUnits: [],
  allIME: [],
  allDME: [],
  allMedra: [],
  loading: true,
  error: "",
  countryLoading: true,
  medDraLoading: true,
  dmeLoading: true,
  imeLoading: true,
  seriousnessLoading: true,
  outcomeLoading: true,
  uomLoading: true,
  routeLoading: true,
  dosageLoading: true,
  routeAdministrationLoading: true
};

const ControlledVocabulariesSlice = createSlice({
  name: "controlledVocabularies",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllCountries.pending, (state) => {
      state.countryLoading = true;
    });
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.allCountries = action.payload;
      state.countryLoading = false
    });
    builder.addCase(getAllCountries.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.countryLoading = false
    });
    builder.addCase(getAllDosageForms.pending, (state) => {
      state.dosageLoading = true;
    });
    builder.addCase(getAllDosageForms.fulfilled, (state, action) => {
      state.allDosageForm = action.payload;
      state.dosageLoading = false
    });
    builder.addCase(getAllDosageForms.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.dosageLoading = false
    });
    builder.addCase(getAllRouteOfAdministration.pending, (state) => {
      state.routeAdministrationLoading = true;
    });
    builder.addCase(getAllRouteOfAdministration.fulfilled, (state, action) => {
      state.allRouteOfAdministration = action.payload;
      state.routeAdministrationLoading = false
    });
    builder.addCase(getAllRouteOfAdministration.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.routeAdministrationLoading = false
    });
    builder.addCase(getAllUnits.pending, (state) => {
      state.uomLoading = true;
    });
    builder.addCase(getAllUnits.fulfilled, (state, action) => {
      state.allUnits = action.payload;
      state.uomLoading = false
    });
    builder.addCase(getAllUnits.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.uomLoading = false
    });
    builder.addCase(getAllOutcomes.pending, (state) => {
      state.outcomeLoading = true;
    });
    builder.addCase(getAllOutcomes.fulfilled, (state, action) => {
      state.allOutcome = action.payload;
      state.outcomeLoading = false
    });
    builder.addCase(getAllOutcomes.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.outcomeLoading = false
    });
    builder.addCase(getAllSeriousness.pending, (state) => {
      state.seriousnessLoading = true;
    });
    builder.addCase(getAllSeriousness.fulfilled, (state, action) => {
      state.allSeriousness = action.payload;
      state.seriousnessLoading = false
    });
    builder.addCase(getAllSeriousness.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.seriousnessLoading = false
    });
    builder.addCase(getAllIme.pending, (state) => {
      state.imeLoading = true;
    });
    builder.addCase(getAllIme.fulfilled, (state, action) => {
      state.allIME = action.payload;
      state.imeLoading = false
    });
    builder.addCase(getAllIme.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.imeLoading = false
    });
    builder.addCase(getAllDme.pending, (state) => {
      state.dmeLoading = true;
    });
    builder.addCase(getAllDme.fulfilled, (state, action) => {
      state.allDME = action.payload;
      state.dmeLoading = false
    });
    builder.addCase(getAllDme.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.dmeLoading = false
    });
    builder.addCase(getAllMedra.pending, (state) => {
      state.medDraLoading = true;
    });
    builder.addCase(getAllMedra.fulfilled, (state, action) => {
      state.allMedra = action.payload;
      state.medDraLoading = false
    });
    builder.addCase(getAllMedra.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.medDraLoading = false
    });
  },
});

export default ControlledVocabulariesSlice.reducer;
