import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  background-color: #ffffff;
  align-items: center;
  img {
    width: 7rem;
  }
  .my-select{
    min-width: 225px;
  }
  .options {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
