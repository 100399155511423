import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  width: 30rem;
  img {
    width: 15rem;
  }
  .loginForm {
    background-color: #ffffff;
    padding: 3rem;
    text-align: left;
    /* margin-bottom: 1rem; */
    h3 {
      color: gray;
    }
    .Fields {
      width: 100%;
      text-align: left;
      margin-top: 1.5rem;
    }
    .ant-form-item{
      margin-bottom: 0;
    }
  }
`;
export const ErrorMessage = styled.div`
  font-size: 20px;
  text-align: center;
  color: red;
`;

export const ModalBody = styled.div`
display: grid;
    grid-auto-flow: column;
  .icon{
    display: flex;
    justify-content: center;
    svg{
      width: 60px;
      fill: #DD2515;
    }
  }
  p{
    margin: 0;
  }
`;
