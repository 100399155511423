import { Drawer, Input, Radio, Button, Tag } from "antd";
import * as Styles from "./DuplicateDrawerStyles";
import { useEffect, useState } from "react";
import CreateVersionModal from "../CreateVersionModal/CreateVersionModal";
import { importEngine } from "../../../API/ImportEngine";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addCurrentAccessingCase } from "../../../slices/Record/RecordSlices";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function DuplicateDrawer(props: {
  visibility: boolean;
  setVisibility: Function;
  selectedDuplicateValue: any;
  recordId?: string;
}) {
  const { visibility, setVisibility, selectedDuplicateValue, recordId } = props;
  const { TextArea } = Input;
  const { currentAccessingCase } = useAppSelector((store) => store.cases);
  const dispatch = useAppDispatch();

  const [duplicateOf, setDuplicateOf] = useState<any[]>([]);
  const [newDuplicateCaseId, setNewDuplicateCaseId] = useState<any>("");
  const [isAddNewTagInputVisisbile, setIsAddNewTagInputVisisbile] =
    useState<any>(false);
  const [duplicateComment, setDuplicateComment] = useState<string>("");
  const [selectedRadioBtn, setselectedRadioBtn] = useState<number>(1);
  const [createVersionModalVisibility, setCreateVersionModalVisibility] =
    useState<boolean>(false);

  const handleRadioBtnChanges = (e: any) => {
    setselectedRadioBtn(e.target.value);
    if (e.target.value === 0) {
      setCreateVersionModalVisibility(true);
    }
  };

  async function handleSubmit() {
    if (!duplicateComment) {
      ReNotification({
        description: "Please provide duplicate comment",
        type: "info",
      });
      return;
    }

    const payload = {
      duplicateComment,
      duplicate: selectedDuplicateValue,
      duplicateOf: duplicateOf,
    };

    const res = await importEngine.duplicate(currentAccessingCase.id, payload);
    if (res.case) {
      ReNotification({
        description: "Record is updated",
        type: "success",
      });
      setVisibility(false);
      dispatch(
        addCurrentAccessingCase({
          ...currentAccessingCase,
          duplicate: selectedDuplicateValue,
          duplicateComment: duplicateComment,
          duplicateOf: duplicateOf,
        })
      );
    }
  }

  const handleInputConfirm = () => {
    if (newDuplicateCaseId) {
      setDuplicateOf([...duplicateOf, newDuplicateCaseId.toUpperCase()]);
    }
    setIsAddNewTagInputVisisbile(false);
    setNewDuplicateCaseId("");
  };
  const handleTagRemove = (removedTag: string) => {
    const newTags = duplicateOf.filter((dup: any) => dup !== removedTag);
    setDuplicateOf(newTags);
  };

  useEffect(() => {
    setDuplicateOf(currentAccessingCase?.duplicateOf);
    setDuplicateComment(currentAccessingCase?.duplicateComment);
  }, [visibility]);
  return (
    <>
      <Drawer
        className="drawer"
        title="Duplicate Record"
        width={600}
        placement="right"
        onClose={() => {
          setVisibility(false);
          setDuplicateOf([]);
          setNewDuplicateCaseId('');
          setDuplicateComment('');
          setselectedRadioBtn(1);
          setIsAddNewTagInputVisisbile(false)
        }}
        open={visibility}
        extra={
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        }
      >
        <Styles.Body>
          {selectedDuplicateValue === 1 && (
            <div className="duplicateIds">
              <h3>Duplicate of</h3>
              <div className="duplicate-cases-container">
                {duplicateOf.map((cas) => {
                  return (
                    <Link to={`/view-record/${cas}`} target="_blank">
                      <Tag
                        closable
                        onClose={(e) => {
                          e.preventDefault();
                          handleTagRemove(cas);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {cas}
                      </Tag>
                    </Link>
                  );
                })}
                {isAddNewTagInputVisisbile ? (
                  <Input
                    type="text"
                    size="small"
                    value={newDuplicateCaseId}
                    onChange={(e: any) => setNewDuplicateCaseId(e.target.value)}
                    onPressEnter={handleInputConfirm}
                    style={{ width: "120px" }}
                  />
                ) : (
                  <Tag onClick={() => setIsAddNewTagInputVisisbile(true)} style={{ cursor: 'pointer' }}>
                    <PlusOutlined /> Add Record ID
                  </Tag>
                )}
              </div>
            </div>
          )}
          {selectedDuplicateValue === 0 && (
            <div className="createVersion">
              <h3>Want to create a version</h3>
              <Radio.Group
                onChange={(e) => handleRadioBtnChanges(e)}
                value={selectedRadioBtn}
                className="radioBtns"
              >
                <Radio value={0}>Yes</Radio>
                <Radio value={1}>No</Radio>
              </Radio.Group>
            </div>
          )}
          <div className="comment">
            <h3>Duplicate Comment</h3>
            <TextArea
              rows={6}
              maxLength={500}
              autoSize={{ minRows: 7, maxRows: 4 }}
              showCount
              allowClear
              value={duplicateComment}
              onChange={(e) => setDuplicateComment(e.target.value)}
            />
          </div>
        </Styles.Body>
      </Drawer>
      <CreateVersionModal
        visibility={createVersionModalVisibility}
        setVisibility={setCreateVersionModalVisibility}
        setRadioBtnValue={setselectedRadioBtn}
        recordId={recordId}
      />
    </>
  );
}

export default DuplicateDrawer;
