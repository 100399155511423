import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { assignCase } from "./Helpers/assignCase";
import { workFLowOneApi } from "./Helpers/workFlowOne";

const initialState: any = {
  assignnedCase: "",
  loading: true,
  error: "",
  currentAccessingCase: {},
  refresh: false,
};

const CaseSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    addCurrentAccessingCase(state, action: PayloadAction<any>) {
      state.currentAccessingCase = action.payload
    },
    updateRefresh(state, action: PayloadAction<any>) {
      state.refresh = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(assignCase.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignCase.fulfilled, (state, action) => {
      state.assignnedCase = action.payload;
    });
    builder.addCase(assignCase.rejected, (state, action) => {
      state.error = "Something went wrong";
    });
    builder.addCase(workFLowOneApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(workFLowOneApi.fulfilled, (state, action) => {
      state.assignnedCase = action.payload;
    });
    builder.addCase(workFLowOneApi.rejected, (state, action) => {
      state.error = "Something went wrong";
    });
  },
});

export const { addCurrentAccessingCase, updateRefresh } = CaseSlice.actions
export default CaseSlice.reducer;
