import { Input, Space } from "antd";
import { useEffect, useState } from "react";

type Props = {
  onChange: (...args: any[]) => any;
};

function ReOTP({ onChange }: Props) {
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const inputFocus = (elmnt: any) => {
    if (elmnt.key === "ArrowLeft" || elmnt.key === "ArrowRight") {
      const next =
        elmnt.key === "ArrowLeft"
          ? elmnt.target.tabIndex - 2
          : elmnt.target.tabIndex;
      const limitCondition = elmnt.key === "ArrowLeft" ? next > -1 : next < 6;
      if (limitCondition) {
        elmnt.target.form.elements[next].focus();
      }
    }
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      setOtp({
        ...otp,
        [`otp${elmnt.target.tabIndex}`]: "",
      });
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (elmnt.keyCode >= 48 && elmnt.keyCode <= 57) {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleOTPPaste = (e: any) => {
    e.preventDefault();
    let value = e.clipboardData.getData("text");
    value = value.replaceAll(/\s/g, "");
    value = value.replace(/\D/g, "");
    setOtp({
      otp1: value.charAt(0),
      otp2: value.charAt(1),
      otp3: value.charAt(2),
      otp4: value.charAt(3),
      otp5: value.charAt(4),
      otp6: value.charAt(5),
    });
  };

  useEffect(() => {
    if (otp.otp6) {
      onChange(Object.values(otp).join(""));
    }
  }, [otp]);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const isNumber = /^\d+$/.test(value);
    if (isNumber || value === "") {
      setOtp({
        ...otp,
        [name]: value,
      });
    }
  };

  return (
    <div className="otp-input-container">
      <Space direction="horizontal" size="large">
        <Input
          name="otp1"
          type="text"
          value={otp.otp1}
          maxLength={1}
          onChange={(e) => {
            handleInputChange(e);
          }}
          size="large"
          onKeyUp={(e) => {
            inputFocus(e);
          }}
          tabIndex={1}
          autoFocus
          onPaste={handleOTPPaste}
          style={{ textAlign: "center" }}
        />
        <Input
          name="otp2"
          type="text"
          value={otp.otp2}
          maxLength={1}
          onChange={(e) => {
            handleInputChange(e);
          }}
          size="large"
          onKeyUp={(e) => {
            inputFocus(e);
          }}
          tabIndex={2}
          onPaste={handleOTPPaste}
          style={{ textAlign: "center" }}
        />
        <Input
          name="otp3"
          type="text"
          value={otp.otp3}
          maxLength={1}
          onChange={(e) => {
            handleInputChange(e);
          }}
          size="large"
          onKeyUp={(e) => {
            inputFocus(e);
          }}
          tabIndex={3}
          onPaste={handleOTPPaste}
          style={{ textAlign: "center" }}
        />
        <Input
          name="otp4"
          type="text"
          maxLength={1}
          value={otp.otp4}
          onChange={(e) => {
            handleInputChange(e);
          }}
          size="large"
          onKeyUp={(e) => {
            inputFocus(e);
          }}
          tabIndex={4}
          onPaste={handleOTPPaste}
          style={{ textAlign: "center" }}
        />
        <Input
          name="otp5"
          type="text"
          value={otp.otp5}
          maxLength={1}
          onChange={(e) => {
            handleInputChange(e);
          }}
          size="large"
          onKeyUp={(e) => {
            inputFocus(e);
          }}
          tabIndex={5}
          onPaste={handleOTPPaste}
          style={{ textAlign: "center" }}
        />
        <Input
          name="otp6"
          type="text"
          value={otp.otp6}
          maxLength={1}
          onChange={(e) => {
            handleInputChange(e);
          }}
          size="large"
          onKeyUp={(e) => {
            inputFocus(e);
          }}
          tabIndex={6}
          onPaste={handleOTPPaste}
          style={{ textAlign: "center" }}
        />
      </Space>
    </div>
  );
}

export default ReOTP;
