import { BaseAPI } from "./Common";
import RefreshToken from "./RefreshToken";

class Auth extends BaseAPI {

    refreshToken = () => {
        const userData = JSON.parse(sessionStorage.getItem("user") || '{}');
        let payload = {
            refreshToken: userData.refreshToken
        }

        return this.makeRequest("refresh", "post", payload)
            .then(response => {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    this.logout();
                }

                userData.accessToken = response.accessToken;
                sessionStorage.setItem("user", JSON.stringify(userData));
            })
    }

    getOTP(email: string) {
        let payload = { email }
        return this.makeRequest("generateOTP", "post", payload);
    }

    login(email: string, accessCode: string) {
        let payload = { username: email, password: accessCode }
        return this.makeRequest("login", "post", payload).then((response) => {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                sessionStorage.removeItem('redirect');

                this.logout();
            }

            sessionStorage.setItem('user', JSON.stringify(response || {}));
            sessionStorage.removeItem('redirect');

            RefreshToken.startInterval();

            return response;
        })
    }

    logout() {
        sessionStorage.removeItem("appState");
        sessionStorage.clear();
    }

    isAuthenticated() {
        const user = sessionStorage.getItem('user')
        if (user) {
            RefreshToken.startInterval();
            return JSON.parse(user);
        }
        return false;
    }

    getCurrentRole() {
        const user = sessionStorage.getItem('user')
        if (user) {
            let parsedUser = JSON.parse(user)
            return parsedUser.user && parsedUser.user.role
        }
        return null
    }

    isCurrUserAdmin() {
        if (auth.isAuthenticated().permissions.length === 7) {
            return true
        }
        return false
    }

    getUsersPrivileges() {
        const user = sessionStorage.getItem('user')
        if (user) {
            let parsedUser = JSON.parse(user)
            return parsedUser.permissions
        }
        return []
    }

    isPermissionExists(permissions: string[]) {
        return permissions.some((value) =>
            this.getUsersPrivileges()?.includes(value)
        );
    }
}

export const auth = new Auth("auth")