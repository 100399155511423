import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetAuditLogs } from './Healper/GetAuditLogs';
import { GetImportAuditLogs } from './Healper/GetImportAuditLogs';
import { GetCaseHistory } from "./Healper/GetCaseHistory";

const initialState: any = {
    allAuditLogs: [],
    importLogs: [],
    caseHistory: [],
    loading: true,
    error: "",
};

const AuditLogsSlice = createSlice({
    name: "auditLogs",
    initialState,
    reducers: {
        addAllAuditLogs(state, action: PayloadAction<any>) {
            state.allAuditLogs = action.payload
        },
    },

    extraReducers: (builder) => {
        builder.addCase(GetAuditLogs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetAuditLogs.fulfilled, (state, action) => {
            state.allAuditLogs = action.payload;
            state.loading = false;
        });
        builder.addCase(GetAuditLogs.rejected, (state, action) => {
            state.error = "Something went wrong";
            state.loading = false;
        });
        

        builder.addCase(GetCaseHistory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetCaseHistory.fulfilled, (state, action) => {
            state.caseHistory = action.payload;
            state.loading = false;
        });
        builder.addCase(GetCaseHistory.rejected, (state, action) => {
            state.error = "Something went wrong";
            state.loading = false;
        });

        builder.addCase(GetImportAuditLogs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetImportAuditLogs.fulfilled, (state, action) => {
            state.importLogs = action.payload;
            state.loading = false;
        });
        builder.addCase(GetImportAuditLogs.rejected, (state, action) => {
            state.error = "Something went wrong";
            state.loading = false;
        });
    },
});

export const { addAllAuditLogs } = AuditLogsSlice.actions
export default AuditLogsSlice.reducer;
