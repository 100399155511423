import { Header, SideMenu } from "../../../Components";
import UOMTable from "../../../Components/ControlledVocabularies/UOM/UOMTable";

import * as Styles from "./UOMStyles";

function UnitOfMeasurement() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <UOMTable />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default UnitOfMeasurement;
