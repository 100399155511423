import { useEffect, useState } from "react";
import * as Styles from "./MedraStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../common";
import { API_URL } from "../../../API/Config";

function MedraTable() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);

  const columns = [
    {
      title: "LLT Code",
      dataIndex: "lltCode",
      width: 150,
    },
    {
      title: "LLT Name",
      dataIndex: "lltName",
      width: 250,
    },
    {
      title: "PT Code",
      dataIndex: "ptCode",
      width: 150,
    },
    {
      title: "PT Name",
      dataIndex: "ptName",
      width: 200,
    },
    {
      title: "HLT Code",
      dataIndex: "hltCode",
      width: 150,
    },
    {
      title: "HLT Name",
      dataIndex: "hltName",
      width: 200,
    },
    {
      title: "HLGT Code",
      dataIndex: "hlgtCode",
      width: 150,
    },
    {
      title: "HLGT Name",
      dataIndex: "hlgtName",
      width: 200,
    },
    {
      title: "SOC Code",
      dataIndex: "socCode",
      width: 150,
    },
    {
      title: "SOC Name",
      dataIndex: "socName",
      width: 200,
    },
    {
      title: "SOC_Abbrev",
      width: 170,
      dataIndex: "socAbbrev",
    },
  ];

  return (
    <Styles.SubContainer>
      <ReTable
        name="medDra"
        title="MedDra"
        columns={columns}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "lltCode",
        }}
        uniView={{
          uniView: "controlled-vocabularies",
          viewName: "medra",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "lltCode",
              order: "DESC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
      />
    </Styles.SubContainer>
  );
}

export default MedraTable;
