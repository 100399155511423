import { Form, Drawer } from "antd";
import { useEffect, useState } from "react";
import { permission } from "../../API/UserManagement/Permission";
import { role } from "../../API/UserManagement/Role";
import { getAllRoles } from "../../slices/Roles/Helpers/GetAllRoles";
import { useAppDispatch } from "../../app/hooks";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReCheckBox from "../../unity-ui-common/Components/ReFormFields/ReCheckbox";
import "../Management/ManageUsers/styles.css";
import * as Styles from "./SponserCreationFormStyles";

export const RoleCreationForm = (props: {
  drawerType: string;
  visibility: boolean;
  setVisibility: Function;
  currEditingRole: any;
}) => {
  const [form] = Form.useForm();
  const { drawerType, visibility, setVisibility, currEditingRole } = props;
  const dispatch = useAppDispatch();

  const [allPermissions, setAllPermissions] = useState<any[]>([]);
  const [activePermissions, setActivePermissions] = useState<any>();

  const onClose = () => {
    setVisibility({
      visibility: false,
      drawerType: "create",
    });
    form.resetFields();
  };

  const getAllPermissions = async () => {
    const data = await permission.getAll();
    setAllPermissions(data);
  };

  async function handleFormSubmitButtonClicks(values: any) {
    let permissions = [""];
    allPermissions.forEach((mainPermissionsData: any) => {
      if (values[mainPermissionsData.name]) {
        permissions.push(mainPermissionsData.id);
      }
    });
    const payload = {
      name: values.name,
      description: values.description,
      permissions: permissions,
    };
    if (drawerType === "create") {
      await role.create(payload);
    } else {
      await role.update(currEditingRole.id, payload);
    }
    dispatch(getAllRoles());
    ReNotification({
      description: `User Role ${drawerType === "create" ? "Created" : "Updated"
        } Successfully`,
      type: "success",
    });
    onClose();
  }

  useEffect(() => {
    getAllPermissions();
  }, [visibility]);

  useEffect(() => {
    if (drawerType === "edit" && allPermissions) {
      let values = {
        name: currEditingRole.name,
        description: currEditingRole.description,
      };
      let tempActivePermissions: any = {};
      allPermissions?.forEach((mainPermissionData: any) => {
        const isPermission = currEditingRole?.permissions?.some(
          (userPermissionData: any) =>
            userPermissionData === mainPermissionData.name
        );
        values = { ...values, [mainPermissionData.name]: isPermission };
        tempActivePermissions[mainPermissionData.name] = isPermission;
      });
      setActivePermissions(tempActivePermissions);
      form.setFieldsValue(values);
    }
  }, [drawerType, allPermissions]);

  const handleCheckboxClick = (name: string) => {
    const value = form.getFieldValue(name);
    form.setFieldsValue({
      [name]: !value
    })
    setActivePermissions({...activePermissions, [name]: !value})
  }

  return (
    <div>
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        width="640"
        title={drawerType === "edit" ? "Edit User Roles" : "Create Roles"}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmitButtonClicks}
          submitBtn
        >
          <ReInput label="Name" name="name" required type="simple" />
          <ReInput
            label="Description"
            name="description"
            required
            type="simple"
          />
          <p>Privileges</p>
          <Styles.PermissionGrid>
            {allPermissions?.map((data: any, i) => (
              <Styles.PermissionBox className={`${activePermissions?.[data.name] ? "active" : ""}`} onClick={() => handleCheckboxClick(data.name)}>
                <div className="permission-checkbox">
                  <ReCheckBox label="" name={data.name} />
                </div>
                <div className="text">{data.name}</div>
              </Styles.PermissionBox>
            ))}
          </Styles.PermissionGrid>
        </ReusableForm>
      </Drawer>
    </div>
  );
};
