import styled from "styled-components";

export const Container = styled.div`
   display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  height: calc(100vh - 60px);
  gap: 0.5rem;
  position: relative;
  transition-duration: 120ms;
  .menu {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .toggleBtn {
    position: absolute;
    z-index: 1000;
    right: -10px;
    font-size: 25px;
    background-color: white;
    border-radius: 50%;
    color: #cccccc;
    top: 2px;
  }
`;
export const MenuFooter = styled.div`
  text-align: center;
  padding-bottom: 0.5rem;
  width: 230px;
  h6 {
    margin: 0;
    span {
      color: #dd2515;
    }
  }
`;
export const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    color: #1890ff;
  }
`;

export const ToggleBtn = styled.div`
  position: absolute;
  z-index: 1000;
  font-size: 25px;
  background-color: white;
  border-radius: 50%;
  color: #4c4c4c;
  top: 8px;
  right: -6px;

  height: 2rem;
  box-sizing: border-box;
  width: 2rem;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
