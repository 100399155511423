import styled from "styled-components";

export const Container = styled.div`
  .no-record-error{
    text-align: center;
    color: grey;
    .icon{
        font-size: 2rem;
    }
  }
`;