export const colors = ["#0084FF", "#9370DB", "#00C300", "#FF0084", "#DF2029", "#AFEEEE", "#DAA520"]

export const datePickerDropDownItems = [
    {
        title: "Today",
        value: "days_-0"
    },
    {
        title: "Last Week",
        value: "days_-7"
    },
    {
        title: "Last 15 days",
        value: "days_-15"
    },
    {
        title: "Last Month",
        value: "month_-1"
    },
    {
        title: "Last Quarter",
        value: "custom_quarter"
    },
    {
        title: "Custom",
        value: "custom"
    },
]


{/* <Option value="">Last Week</Option>
        <Option value="">Last 15 days</Option>
        <Option value="">Last Month</Option>
        <Option value="">Last Quarter</Option> */}