import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";

const getAllCountries = createAsyncThunk(
  "controlledVocabularies/getAllCountries",
  async () => {
    try {
      const data = await controlledVocabularies.getManyCountries()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllCountries };
