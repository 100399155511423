import { createAsyncThunk } from "@reduxjs/toolkit";
import { ingredient } from "../../../API/Masters/ActiveIngredient/ActiveIngredient";

const createActiveIngredient = createAsyncThunk(
  "ingredient/createIngredient",
  async (ingredientData: any) => {
    try {
      const data = await ingredient.create(ingredientData);
      return data;
    } catch (err) {
      console.log("error");
    }
  }
);

export { createActiveIngredient };
