import styled from "styled-components";

export const Container = styled.div``;
export const Workflow = styled.div`
    margin-bottom: 0.5rem;
    span{
        margin-right: 5px;
        margin-left: 5px;
    }
`;
export const PermissionGrid = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto; */
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 2rem;
`;

export const PermissionBox = styled.div`
    padding: 0.25rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    position: relative;
    cursor: pointer;
    background-color: #fafafa;
    padding-inline: 20px;
    transition: 0.25s ease-in-out;
    :hover{
        background-color: #fdedeb;
        border-color: #DD2515;
    }
    &.active{
        background-color: #fad1cd;
        border-color: #DD2515;
        font-weight: 500;
    }
    .permission-checkbox{
        display: grid;
        place-items: center;
        width: 25px;
        /* outline: 1px solid black; */
        .ant-form-item{
            margin: 0;
        }
    }
    .text{
        text-align: center;
        /* font-size: 0.8rem; */
        line-height: 15px;
    }
`;