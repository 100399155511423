import { useEffect, useState } from "react";
import * as Styles from "./DmeStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../common";
import { API_URL } from "../../../API/Config";

function DMETable() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);

  const columns = [
    {
      title: "PT Name",
      dataIndex: "name",
    },
  ];

  return (
    <Styles.SubContainer>
      <ReTable
        name="dme"
        title="DME"
        columns={columns}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "slug",
        }}
        uniView={{
          uniView: "controlled-vocabularies",
          viewName: "designatedMedicalEvent",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
      />
    </Styles.SubContainer>
  );
}

export default DMETable;
