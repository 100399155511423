import CryptoJS from 'crypto-js';

export class SecureDataService {
    private secretKey: string
    constructor(
    ) {
        this.secretKey = "a545sd4fs6kjsdhaisudhajzxkljcn3354654654zkludd6sd54f6sd5v44d654v4z3x2c"
    }
    encrypt(data: any) {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
        return encryptedData;
    }
    decrypt(data: any) { 
        const decryptedData = CryptoJS.AES.decrypt(data, this.secretKey).toString(CryptoJS.enc.Utf8);
        return decryptedData
     }
}
