import Styled from 'styled-components';

export const SubContainer = Styled.div`
    width: 100%;
    .formIitems{
    padding: 0.5rem 1rem ;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: #ffffff;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .record-container{
    padding-bottom: 10px;
  }
`;