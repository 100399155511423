const listDivStyles = { marginLeft: "1rem", marginTop: "1rem" };
const listStyles = { margin: 0 };

export const PrivacyPolicyTemplate = (
  <>
    <h1>WEB PRIVACY POLICY</h1>
    <p>
      This page explains our privacy policy and how we will use and protect any
      information about you that you give us when you visit this website.
      <br />
      <br />
      We take your privacy seriously. We will not collect any personal
      information about you without your consent. Any personal information
      submitted by you on this website will not be shared with third parties.
      <br />
      <br />
      This Web Privacy Policy (‘Policy’) is operated by Soterius, Inc.
      (‘Soterius”), which may also operate other websites. This Policy may not
      apply to those other websites and we advise you to review the privacy
      policy posted on any other website operated by Soterius.
      <br />
      <br />
      By using this website you agree to the terms of this Policy.
    </p>

    <h2>Information we may collect from you</h2>
    <p>
      When you visit or access our site, we may collect the following
      information from you:
      <div style={listDivStyles}>
        <p style={listStyles}>
          • If you complete any online forms, including if you register as a
          user of the website, then the details entered such as your name,
          address, telephone number, email address will all be collected
        </p>
        <p style={listStyles}>
          • If you start an online account on the website, then your username
          and password will be collected
        </p>
        <p>
          • We may decide to do online surveys on this website and any
          information you provide as a participant will be collected
        </p>
      </div>
    </p>

    <h3>Information Storage</h3>
    <p>
      Any information submitted on this website is sent to and stored on secure
      servers in the United States of America. This is done in order to properly
      process the collected information. By submitting information on this
      website you agree to this storing and processing of information.
    </p>

    <h4>Disclosure of your information</h4>
    <p>
      We will not disclose your information to any other party unless we are
      acting within the ambit of this policy, or if we are legally required to
      disclose the information, or in order to prevent an occurrence of fraud or
      reduce the risk of fraud, or if we sell our business to a buyer. We may
      disclose your personal information to any member of our group of
      companies.
    </p>

    <h2>Your rights</h2>
    <p>
      You have the right to ask us for details of and access to any information
      that we hold about you. On request we will provide you with a copy of the
      held information for a fee of GBP10. You can also correct any information
      that we hold on you if so required
    </p>

    <h2>Cookies</h2>
    <p>
      A cookie is a small file which is placed on a computer’s hard drive, or in
      the browser memory, to make a website work, or work more efficiently for
      returning visitors. When you visit our website, a cookie may also get
      placed in the browser files of your computer and be stored on your
      computer’s hard drive.
      <br />
      <br />
      The cookies used on this website do not store any personal or confidential
      information about you and are used to provide functionality or for
      analysis only.
      <br />
      <br />
      This website uses Google Analytics to record visitor trends. Google
      Analytics uses a cookie to track which pages are accessed. The cookie
      contains no personally-identifiable information, but it does use your
      computer’s IP address to determine where in the world you are accessing
      the site from and to track your page visits within the site.
    </p>

    <h3>Third-party content</h3>
    <p>
      From time to time, we may embed external content or links from third-party
      websites (e.g. Facebook, Twitter, YouTube) within our website. These
      websites may utilize cookies and the privacy policy that will apply to
      such third-party content will be published on the website of that
      third-party content provider. Whilst we may provide these links for your
      convenience, we do not review or monitor the privacy policies or terms and
      conditions of any external websites and your use of those other external
      websites is subject to the terms and conditions and privacy policies of
      those sites.
    </p>

    <h3>Enabling/disabling cookies</h3>
    <p>
      You have the ability to accept or decline cookies by modifying the
      settings in your browser. However, our website may not function correctly
      if cookies are disabled.
    </p>

    <h3>Security</h3>
    <p>
      It is very important to us that any information that you submit via this
      website is kept secure. However, whilst we will take every reasonable
      effort to ensure that your information is protected, we cannot guarantee
      that loss, alteration or misuse of the information will never occur. It
      must also be understood that when you submit information over the
      internet, absolute security is never guaranteed, and therefore any
      information you do submit will be done entirely at your own risk.
    </p>
    <h4>Changes to this privacy policy</h4>
    <p>
      We reserve the right to change this policy at any time and for any reason.
      If this policy changes in any way, we will update this page. Please
      revisit this page regularly to ensure that you are always aware of what
      information we collect and which cookies we are using.
    </p>
  </>
);

export const TermsAndConditionsTemplate = (
  <>
    <h3>Welcome to Soterius</h3>
    <p>
      These terms and conditions (“Terms and Conditions”) outline the rules and
      regulations governing the the use of Soterius, Inc.’s website, located at
      https://www.soterius.com
      <br />
      <br />
      By accessing this website, we assume you accept these Terms and
      Conditions. Do not continue to use Soterius if you do not agree with all
      of the Terms and Conditions stated on this page.
      <br />
      <br />
      The following terminology applies to these Terms and Conditions, Web
      Privacy Policy and all Agreements: “Client”, “You” and “Your” refers to
      you, the person log on this website and compliant to the Company’s terms
      and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers
      to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and
      ourselves. All terms refer to the offer, acceptance and consideration of
      payment necessary to undertake the process of our assistance to the Client
      in the most appropriate manner for the express purpose of meeting the
      Client’s needs in respect of provision of the Company’s stated services,
      in accordance with and subject to, prevailing law of the United States of
      America. Any use of the above terminology or other words in the singular,
      plural, capitalization and/or he/she or they, are taken as interchangeable
      and therefore as referring to same.
    </p>

    <h3>Cookies</h3>
    <p>
      We employ the use of cookies. By accessing www.soterius.com, you agreed to
      use cookies in agreement with the Soterius, Inc.’s Web Privacy Policy.
      <br />
      <br />
      Most interactive websites use cookies to let us retrieve the user’s
      details for each visit. Cookies are used by our website to enable the
      functionality of certain areas to make it easier for people visiting our
      website. Some of our affiliate/advertising partners may also use cookies.
    </p>

    <h3>License</h3>
    <p>
      Unless otherwise stated, Soterius, Inc.. and/or its licensors own the
      intellectual property rights for all material on Soterius. All
      intellectual property rights are reserved. You may access this from
      Soterius for your own personal use subjected to restrictions set in these
      terms and conditions.
      <br />
      <br />
      You must not:
      <div style={listDivStyles}>
        <p style={listStyles}>• Republish material from Soterius</p>
        <p style={listStyles}>
          • Sell, rent or sub-license material from Soterius
        </p>
        <p style={listStyles}>
          • Reproduce, duplicate or copy material from Soterius
        </p>
        <p>
          • Redistribute content from Soterius without giving the proper credits
          to Soterius, Inc.
        </p>
      </div>
      This Agreement shall begin on the date hereof.
      <br />
      <br />
      Parts of this website offer an opportunity for users to post and exchange
      opinions and information in certain areas of the website. Soterius, Inc..
      does not filter, edit, publish or review comments prior to their presence
      on the website. Comments do not reflect the views and opinions of
      Soterius, Inc. Its agents and/or affiliates. Comments reflect the views
      and opinions of the person who post their views and opinions. To the
      extent permitted by applicable laws, Soterius, Inc. shall not be liable
      for the comments or for any liability, damages or expenses caused and/or
      suffered as a result of any use of and/or posting of and/or appearance of
      the comments on this website.
      <br /> <br />
      Soterius Inc. reserves the right to monitor all comments and to remove any
      comments which can be considered inappropriate, offensive or cause a
      breach of these Terms and Conditions
      <br /> <br />
      You warrant and represent that:
      <div style={listDivStyles}>
        <p style={listStyles}>
          • You are entitled to post the comments on our website and have all
          necessary licenses and consents to do so;
        </p>
        <p style={listStyles}>
          • The comments do not invade any intellectual property right,
          including without limitation copyright, patent or trademark of any
          third party;
        </p>
        <p style={listStyles}>
          • The comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy
        </p>
        <p style={listStyles}>
          • The comments will not be used to solicit or promote business or
          custom or present commercial activities or unlawful activity.
        </p>
        <p>
          • You hereby grant Soterius, Inc.. a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </p>
      </div>
    </p>

    <h3>Hyperlinking to our Content</h3>
    <p>
      The following organizations may link to our website without prior written
      approval:
      <div style={{ marginLeft: "1rem" }}>
        <p style={{ margin: 0 }}>• Government agencies;</p>
        <p style={{ margin: 0 }}>• Search engines;</p>
        <p style={{ margin: 0 }}>• News organizations;</p>
        <p style={{ margin: 0 }}>
          • Online directory distributors may link to our website in the same
          manner as they hyperlink to the websites of other listed businesses;
          and
        </p>
        <p>
          • System wide accredited businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our website.
        </p>
      </div>
      <p>
        These organizations may link to our home page, to publications or to
        other website information so long as the link: (a) is not in any way
        deceptive; (b) does not falsely imply sponsorship, endorsement or
        approval of the linking party and its products and/or services; and (c)
        fits within the context of the linking party’s site.
        <br />
        <br />
        We may consider and approve other link requests from the following types
        of organizations:
      </p>
      <div style={listDivStyles}>
        <p style={listStyles}>
          • commonly-known consumer and/or business information sources;
        </p>
        <p style={listStyles}>• dot.com community sites;</p>
        <p style={listStyles}>
          • associations or other groups representing charities; online
          directory distributors;
        </p>
        <p style={listStyles}>• internet portals;</p>
        <p style={listStyles}>• accounting, law and consulting firms; and</p>
        <p>• educational institutions and trade associations.</p>
      </div>
    </p>

    <p>
      We will approve link requests from these organizations if we decide that:
      (a) the link would not make us look unfavorably to ourselves or to our
      accredited businesses; (b) the organization does not have any negative
      records with us; (c) the benefit to us from the visibility of the
      hyperlink compensates the absence of Soterius, Inc.; and (d) the link is
      in the context of general resource information.
      <br />
      <br />
      These organizations may link to our home page so long as the link: (a) is
      not in any way deceptive; (b) does not falsely imply sponsorship,
      endorsement or approval of the linking party and its products or services;
      and (c) fits within the context of the linking party’s site.
      <br />
      <br />
      If you are one of the organizations listed in paragraph 2 above and are
      interested in linking to our website, you must inform us by sending an
      e-mail to info@soterius.com. Please include your name, your organization
      name, contact information as well as the URL of your site, a list of any
      URLs from which you intend to link to our website, and a list of the URLs
      on our site to which you would like to link. Kindly, wait 2-3 weeks for a
      response.
      <br />
      <br />
      Approved organizations may hyperlink to our website as follows:
      <div style={listDivStyles}>
        <p style={listStyles}>
          <p style={listStyles}>• By use of our corporate name; or</p>• By use
          of the uniform resource locator being linked to; or
        </p>
        <p>
          • By use of any other description of our website being linked to that
          makes sense within the context and format of content on the linking
          party’s site.
        </p>
      </div>
      <p>
        No use of Soterius, Inc.’s logo or other artwork will be allowed for
        linking absent a trademark license agreement.
      </p>
    </p>

    <h3>iFrames</h3>
    <p>
      Without prior approval and written permission, you may not create frames
      around our Webpages that alter in any way the visual presentation or
      appearance of our Website.
    </p>

    <h3>Content Liability</h3>
    <p>
      We shall not be hold responsible for any content that appears on your
      website. You agree to protect and defend us against all claims that is
      rising on your website. No link(s) should appear on any website that may
      be interpreted as libelous, obscene or criminal, or which infringes,
      otherwise violates, or advocates the infringement or other violation of,
      any third party rights.
    </p>

    <h3>Your Privacy</h3>
    <p>Please read our Web Privacy Policy available on our website.</p>

    <h3>Reservation of Rights</h3>
    <p>
      We reserve the right to request that you remove all links or any
      particular link to our Website. You approve to immediately remove all
      links to our Website upon request. We also reserve the right to amend
      these terms and conditions and it’s linking policy at any time. By
      continuously linking to our Website, you agree to be bound to and follow
      these linking terms and conditions.
    </p>

    <h3>Removal of links from our website</h3>
    <p>
      If you find any link on our Website that is offensive for any reason, you
      are free to contact and inform us any moment. We will consider requests to
      remove links but we are not obligated to or so or to respond to you
      directly.
      <br />
      <br />
      We do not ensure that the information on this website is correct, we do
      not warrant its completeness or accuracy; nor do we promise to ensure that
      the website remains available or that the material on the website is kept
      up to date.
    </p>

    <h3>Disclaimer</h3>
    <p>
      To the maximum extent permitted by applicable law, we exclude all
      representations, warranties and conditions relating to our website and the
      use of this website. Nothing in this disclaimer will:
      <div style={{ marginLeft: "1rem" }}>
        <p style={{ margin: 0 }}>
          • limit or exclude our or your liability for death or personal injury;
        </p>
        <p style={{ margin: 0 }}>
          • limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </p>
        <p>
          • limit any of our or your liabilities in any way that is not
          permitted under applicable law; or exclude any of our or your
          liabilities that may not be excluded under applicable law.
        </p>
      </div>
      <p>
        The limitations and prohibitions of liability set in this section and
        elsewhere in this disclaimer:
        <br />
        <br />
        (a) are subject to the preceding paragraph; and
        <br />
        <br />
        (b) govern all liabilities arising under the disclaimer, including
        liabilities arising in contract, in tort and for breach of statutory
        duty.
        <br />
        <br />
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </p>
    </p>
  </>
);
