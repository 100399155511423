import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UserTypes,
} from "../../Components/Types/TableColumn.types";
import { getUsers } from "./Helpers/GetAllUsers";

interface UserState {
  allUsers: UserTypes[];
  loading: boolean;
  error: string;
  userPermissions: string[]
}

interface UpdateTypes {
 
  payload:{
    name: string;
    value: string;
  }
}

const initialState:UserState = {
  allUsers: [],
  loading: true,
  error: "",
  userPermissions: []
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserPermissions: (state,  action) => {
      state.userPermissions = action.payload     
  }
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      state.loading=false
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.error = "Something went wrong";
      state.loading=false
    });
  },
});

export const { addUserPermissions } = UserSlice.actions;
export default UserSlice.reducer;
