import moment from "moment";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import { Button, Form, Space } from "antd";
import { Uniview } from "../../API/Uniview";
import { useEffect, useState } from "react";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

function AllAuditLog() {

    const [form] = Form.useForm(); // form instance
    const [allUsers, setAllUsers] = useState<any[]>([]);
    const [generateBtnLoading, setGenerateBtnLoading] = useState<boolean>(false);

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        let univiewService = new Uniview("user", "tenantUser");
        let allUsersRes = await univiewService.fetchData({}, {
            orderBy: { name: "ASC" },
        });
        setAllUsers([
            ...allUsersRes.data,
            {
                id: '421c2f56-755a-4a33-83e9-b5e3d3eaad79',
                name: 'System'
            }
        ])
    }

    const handleFilterSubmit = async (values: any) => {
        const { users, date } = values;
        let data: any = {};
        let userName: string = '';
        if (users) {
            const userId = users?.split('_/')?.[0] || ''
            userName = users?.split('_/')?.[1] || ''
            data["user"] = {
                id: userId,
                name: userName
            };
        }
        data["startDate"] = moment(date[0]._d).format('YYYY-MM-DD') + 'T00:00:00.000Z';
        data["endDate"] = moment(date[1]._d).format('YYYY-MM-DD') + 'T23:59:59.999Z';

        let uniview = new Uniview("import-engine", "audit");
        setGenerateBtnLoading(true);
        ReNotification({
            description: "The Audit Trail Report May Take a Few Minutes to Generate.",
            placement: "bottomLeft",
            type: "info",
        });
        let res = await uniview.generatePdf(
            {
                createdAt: {
                    ">": moment(date[0]._d).format('YYYY-MM-DD') + 'T00:00:00.000Z',
                    "<": moment(date[1]._d).format('YYYY-MM-DD') + 'T23:59:59.999Z',
                },
                org: { "=": JSON.parse(sessionStorage.getItem("selectedWorkSpace") || '')?.id }
            },
            {
                orderBy: { createdAt: "DESC" },
            },
            `${users ? data.user.name + " " : ""}Audit Trail Report ${moment(data.startDate).format("DD-MMM-YYYY")} to ${moment(data.endDate).format("DD-MMM-YYYY")}`,
            "pdf",
            data,
        );
        if (res?.statusCode === 200) {
            ReNotification({
                description: `Audit Trail ${userName ? "For " + userName : "All Users"} Generated`,
                placement: "bottomLeft",
                type: "info",
            });
        } else {
            ReNotification({
                description: "No Data Available",
                placement: "bottomLeft",
                type: "error",
            });
        }
        setGenerateBtnLoading(false);
    };

    return (
        <ReusableForm
            formInstance={form}
            onSubmit={handleFilterSubmit}
            formStyles={{ marginBottom: "0.5rem" }}
        >
            <div className="formIitems">
                <ReSelect
                    label="Users"
                    name="users"
                    items={allUsers?.map((user: any) => {
                        return {
                            title: user.name,
                            value: user.id + "_/" + user.name,
                        };
                    })}
                    searchable
                />
                <ReDatePicker
                    label="Date"
                    name="date"
                    type="rangePicker"
                    dateFormat="DD-MMM-YYYY"
                    featureDates
                    width="100%"
                    required
                />
                <Form.Item noStyle>
                    <Space
                        direction="horizontal"
                        style={{ width: "7rem", marginTop: "5px" }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={generateBtnLoading}
                        >
                            Export PDF
                        </Button>
                        <Button htmlType="reset">Reset</Button>
                    </Space>
                </Form.Item>
            </div>
        </ReusableForm>
    )
}

export default AllAuditLog