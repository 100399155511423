import { createAsyncThunk } from "@reduxjs/toolkit";
import { role } from "../../../API/UserManagement/Role";

const getAllRoles = createAsyncThunk(
  "user/getAllRoles",
  async () => {
    try {
      const data = await role.getAll();
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllRoles };
