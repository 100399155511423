import Styled from 'styled-components';

export const Container = Styled.div`
  margin-bottom: 0.5rem;
`;
export const Controls = Styled.div`
  display: flex;
  gap: 1rem;
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;
    .control{
        width: 20rem;
    }
`;

export const Form = Styled.div`
  background-color: #ffffff;
  .twoColumn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    
    .fileType{
      font-size: 0.8rem;
      margin-top: -1.05rem;
      color: #A9A9A9;
    }
    &.sourceTypeValue2 .ant-form-item:last-child{
      grid-column: 1 / span 2;
    }
  }
`;