import { Header, SideMenu } from "../../../Components";
import SeriousnessTable from "../../../Components/ControlledVocabularies/Seriousness/SeriousnessTable";

import * as Styles from "./SeriousnessStyle";

function Seriousness() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer  className="subContainer">
          <SeriousnessTable/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default Seriousness;
