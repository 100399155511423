import { useState } from "react";
import * as Styles from "./DosageFormTableStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { API_URL } from "../../../API/Config";

function DosageFormTable() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);

  const DosageTableColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Dosage Form Value",
      dataIndex: "form",
      key: "form",
    },

    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
    },
  ];

  return (
    <Styles.SubContainer>
      <ReTable
        name="dosageForms"
        title="Dosage Forms"
        columns={DosageTableColumn}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "code",
        }}
        uniView={{
          uniView: "controlled-vocabularies",
          viewName: "dosageForms",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
      />
    </Styles.SubContainer>
  );
}

export default DosageFormTable;
