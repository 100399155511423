export interface ISelectedColumnData {
  dataIndex: string;
  title: string;
  width: number;
  columnId: string;
  render?: Function;
}

export const StatusDropDown = [
  {
    title: "Required",
    value: "0",
  },
  {
    title: "Sent",
    value: "1",
  },
  {
    title: "Received",
    value: "2",
  }
]

export const workflowItems = [
  {
    title: "Workflow 1",
    value: 0
  },
  {
    title: "Workflow 2",
    value: 1
  },
  {
    title: "Processed",
    value: 2
  },
  {
    title: "QC",
    value: 3
  },
]

export const completionStatusItems = [
  {
    title: "On Time",
    value: 1
  },
  {
    title: "Late",
    value: 0
  },
]

export const selectionCriteriaOptions = [
  { label: "Record ID", value: "id" },
  { label: "Citation", value: "citation" },
  { label: "Day 0", value: "dateOfSearch" },
  { label: "Product Name", value: "productRelatedData" },
  { label: "Event Reported Term", value: "reportData" }, // reportData, eventTerm
  { label: "Duplicate Of", value: "duplicateOf" },
  { label: "PT", value: "ptData" },
  { label: "SOC", value: "socData" },
  { label: "Seriousness", value: "seriousnessValue" },
  { label: "Narrative", value: "comment" },
  { label: "AI Categorization", value: "aiCategorization" },
  { label: "Record Categorization", value: "categorization" },
  { label: "Sub Categorization", value: "subCategorization" },
  { label: "Gender", value: "D_5_sex" },
  { label: "Age Group", value: "D_2_3_patientAgeGroupAsPerReporter" },
  {
    label: "Reporter Country",
    value: "reporterCountry", // originCountry, reporterCountry
  },
  {
    label: "Primary Reporter",
    value: "primaryReporter", // primaryReporter
  },
  { label: "Abstract", value: "abstract" },
  { label: "Article Title", value: "articleTitle" },
  { label: "Reference ID", value: "externalRefID" },
  { label: "Translation Request Status", value: "translationRequestStatus" },
  { label: "Full Text Publication Request Status", value: "ftpRequestStatus" },
  { label: "MLM", value: "isMLM" },
  // { label: "Workflow", value: "stage" },
  { label: "Workflow 1 (DD)", value: "workflow1DD" },
  { label: "Workflow 1 (CD)", value: "workflow1CD" },
  { label: "Workflow 1 (CS)", value: "workflow1CS" },

  { label: "Workflow 2 (DD)", value: "workflow2DD" },
  { label: "Workflow 2 (CD)", value: "workflow2CD" },
  { label: "Workflow 2 (CS)", value: "workflow2CS" },

  { label: "QC (DD)", value: "qcDD" },
  { label: "QC (CD)", value: "qcCD" },
  { label: "QC (CS)", value: "qcCS" }
];
