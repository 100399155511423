import { createSlice } from "@reduxjs/toolkit";
import { GetAllProducts } from "./Helpers/GetAllProducts";

type ProductApiType = {
  id: string
  createdAt: string;
  updatedAt: string;
  status: number;
  uniqueID: string;
  name: string;
  activeIngredients: string[];
  activeIngredientAliases:string;
  countries: string[];
  dosageForm: string;
  routeOfAdministration: string;
  strength: number;
  unitOfMeasurement: string;
  sponsor: string;
  
};
interface ProductState {
  allProduct: ProductApiType[];
  loading: boolean;
  error: string;
  productLoading:boolean
}

const initialState: ProductState = {
  allProduct: [],
  loading: true,
  error: "",
  productLoading:true
};

const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(GetAllProducts.pending, (state) => {
      state.productLoading = true;
    });
    builder.addCase(GetAllProducts.fulfilled, (state, action) => {
      state.allProduct = action.payload;
      state.productLoading = false;
    });
    builder.addCase(GetAllProducts.rejected, (state, action) => {
      state.error = "Something went wrong";
      state.productLoading = false;
    });
  },
});

export default ProductSlice.reducer;
