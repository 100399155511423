import styled from "styled-components";

export const Container = styled.div`
  .jsonForm {
    #root {
      /* all: unset;
      display: grid;
        grid-template-columns: 1fr 1fr; */
      .ant-row {
        /* all: unset; */
        /* display: grid;
          grid-template-columns: 1fr 1fr; */
        #root {
          /* all: unset; */
        }
      }
    }
  }
`;
