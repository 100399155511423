import ReTable from "../../unity-ui-common/Components/ReTable/ReTable"
import { useEffect, useState } from "react"
import { Button, Tag } from "antd";
import BatchDetailDrawer from "./BatchDetailDrawer";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { importEngine } from "../../API/ImportEngine";
import moment from "moment";
import { qcBatchStatus } from "../../Healper/Mappings";
import { tenant } from "../../API/UserManagement/Tenant";
import { auditlogs } from "../../API/AuditLog";

function QualityCheckLogs() {

    const tenantData = JSON.parse(sessionStorage.getItem("selectedWorkSpace") || "{}")
    const [visibility, setVisibility] = useState(false);
    const [qcDrawerData, setQcDrawerData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [lastRunDate, setLastRunDate] = useState(null);
    const [lastRunBatch, setLastRunBatch] = useState(null);
    
    const qcLogsColumns = [
        {
            title: "Batch ID",
            dataIndex: "batch_number",
            key: "batch_number",
            width: 400,
            render: (id: string, record: any) => {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            handleBatchDetailClick(record);
                        }}
                    >
                        {id}
                    </Button>
                );
            },
        },
        {
            title: "Total Records",
            dataIndex: "batch_data",
            key: "batch_data",
            render: (data: any) => {
                return (data?.length);
            },
        },
        {
            title: "Import Date",
            dataIndex: "batch_import",
            key: "batch_import",
            render: (date: any) => {
                return moment(date).utcOffset("+05:30").format("DD-MMM-YYYY hh:mm A") + ` GMT +5:30`;
            },
        },
        {
            title: "Batch Status",
            dataIndex: "batch_status",
            key: "batch_status",
            render: (record: any) => {
                const color = qcBatchStatus?.[record];
                return (
                    <Tag color={color} title="status">
                        {record}
                    </Tag>
                );
            },
        }
    ];

    useEffect(() => {
        getRecordsData();
    }, [])

    const getRecordsData = async () => {
        setTableLoading(true);
        const res = await importEngine.getQcAuditLogs();
        const tenantInfo = await tenant.getTenant(tenantData.id);
        setTableLoading(false);
        if (res?.status) {
            setTableData(res?.data);
            setPaginationPageSize(res?.data?.length);
            if(tenantInfo?.meta?.lastBatchId){
                const auditLogsRes = await auditlogs.getQcBatchAuditLogs(tenantInfo?.meta?.lastBatchId);
                const lastRunBatchData = res?.data?.filter((data: any) => (data.batch_number === tenantInfo?.meta?.lastBatchId))
                setLastRunDate(auditLogsRes?.createdAt);
                setLastRunBatch(lastRunBatchData[0])
            }
        }
        else {
            ReNotification({
                description: "Something Went Wrong.",
                placement: "bottomLeft",
                type: "error",
            });
        }
    }

    const handleBatchDetailClick = (record: any) => {
        setVisibility(true);
        setQcDrawerData(record);
    };

    return (
        <>
            <ReTable
                name="qualityCheckLogs"
                title="Quality Check Logs"
                data={tableData}
                columns={qcLogsColumns}
                loading={tableLoading}
                showTableStats
                pagination={{
                    total: paginationPageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                headerButtons={[{
                    title: lastRunDate ? "Last Run Date: " + moment(lastRunDate).utcOffset("+05:30").format("DD-MMM-YYYY hh:mm A") + " GMT +5:30" : "",
                    type: "text",
                    onClick: () => handleBatchDetailClick(lastRunBatch)
                }]}
            />
            <BatchDetailDrawer
                qcDrawerData={qcDrawerData}
                visibility={visibility}
                setVisibility={setVisibility}
            />
        </>
    )
}

export default QualityCheckLogs