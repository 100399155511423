import Styled from "styled-components";

export const SubContainer = Styled.div`
    display: flex;
    gap: 0.5rem;
    width: 100%;
    flex-direction: column;
    .filters{
        display: flex;
        justify-content: end;
    }
`;

export const StatsCards = Styled.div`
    display: flex;
    justify-content: left;
    gap: 0.5rem;
    flex-wrap: wrap;
    .ant-card{
        min-width: 250px;
        width: 19.4%;
    }
    `;

export const Charts = Styled.div`
    display: grid;
    gap: 0.5rem;
    .section{
        margin-top: "1rem"
    }
`;