import {
  Button,
  Form,
  Drawer,
  Switch,
} from "antd";
import { UserRoleMatrix } from "./RoleMatrix/UserRoleMatrix";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { GetRoleName } from "../../Utils/GetRoleInfo";
import { user } from "../../API/UserManagement/User";
import { getUsers } from "../../slices/User/Helpers/GetAllUsers";
import { ICreateUser } from "../../API/UserManagement/Interfaces";
import { getAllRoles } from "../../slices/Roles/Helpers/GetAllRoles";
import { getAllSponsors } from "../../slices/Sponsors/Helpers/GetAllSponsors";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

export const UserCreationForm = (props: {
  drawerType: string;
  visibility: boolean;
  setVisibility: Function;
  currEditingUser: any;
}) => {
  const [form] = Form.useForm();
  const { drawerType, visibility, setVisibility, currEditingUser } = props;
  const { allRoles } = useAppSelector((store) => store.roles);
  const { allSponsors } = useAppSelector((store) => store.sponsor);
  const dispatch = useAppDispatch();
  const options: any = [];
  const initialFormValues = {
    name: "",
    email: "",
    phoneNumber: "",
    roleMatrices: [{ roles: [""], tenant: "" }],
    status: 1,
  };

  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [selectedSponsors, setSelectedSponsors] = useState<any[]>([]);
  const [tempUser, setTempUser] = useState<ICreateUser>(initialFormValues);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSelectChanges = (type: string, value: string[]) => {
    const data = value.map((id: string) => ({
      name: GetRoleName(id, type === "role" ? allRoles : allSponsors),
      value: id,
    }));
    if (type === "role") {
      setSelectedRoles(data);
    } else {
      setSelectedSponsors(data);
      const allIds = data.map((ele) => ele.value);
      const filtered = tempUser.roleMatrices.filter((mt) =>
        allIds.includes(mt.tenant)
      );
      setTempUser((prevData) => {
        return {
          ...prevData,
          roleMatrices: filtered,
        };
      });
    }
  };

  const onReset = () => {
    form.resetFields();
    onClose();
  };

  const onClose = () => {
    setVisibility({
      visibility: false,
      type: "create",
    });
    form.resetFields();
  };

  const rolesApiArray = allRoles.map((role) => ({
    name: role.name,
    value: role.id,
  }));

  for (let i = 0; i < rolesApiArray.length; i++) {
    options.push({
      title: rolesApiArray[i].name,
      value: rolesApiArray[i].value,
    });
  }

  const handleFormSubmit = async (values: any) => {
    setIsSubmitted(true);
    if(values?.phoneNumber?.toString()?.length !== 10){
      ReNotification({
        description: "Enter A Valid Phone Number",
        type: "error",
      })
      return
    }
    const payload = {
      name: values.name,
      email: values.email,
      phoneNumber: values.phoneNumber?.toString(),
      roleMatrices: tempUser.roleMatrices,
      status: tempUser.status,
    };
    if (
      payload?.name?.trim() !== "" &&
      payload?.email?.trim() !== "" &&
      payload?.phoneNumber.length === 10
    ) {
      if (drawerType === "create") {
        const res = await user.create(payload);
        if (res?.status) {
          ReNotification({
            description: "User Created Successfully",
            type: "success",
          });
          setVisibility({
            visibility: false,
            type: "create",
          });
        } else {
          ReNotification({
            description: res?.message || "Something went wrong",
            type: "error",
          });
        }
        setIsSubmitted(false);
        dispatch(getUsers());
      } else {
        await user.update(currEditingUser.id, payload);
        dispatch(getUsers());
        setIsSubmitted(false);
        ReNotification({
          description: "User Updated Successfully",
          type: "success",
        });
        setVisibility({
          visibility: false,
          type: "create",
        });
        setIsSubmitted(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAllRoles());
    dispatch(getAllSponsors());
  }, []);

  useEffect(() => {
    if (drawerType === "edit") {
      // findinig already have sponsors
      const alreadySelectedSponsors = currEditingUser.roleMatrices.map(
        (ele: any) => ({
          name: GetRoleName(ele.tenant, allSponsors),
          value: ele.tenant,
        })
      );
      setSelectedSponsors(alreadySelectedSponsors);
      // findinig already have roles
      const uniqueRoleIds: any = [];
      currEditingUser.roleMatrices.map((ele: any) => {
        return ele.roles.map((id: string) => {
          if (!uniqueRoleIds.includes(id)) {
            uniqueRoleIds.push(id);
          }
        });
      });
      uniqueRoleIds.map((id: string) => ({
        name: GetRoleName(id, allRoles),
        value: id,
      }));
      const alreadySelectedRoles = uniqueRoleIds.map((id: string) => ({
        name: GetRoleName(id, allRoles),
        value: id,
      }));
      setSelectedRoles(alreadySelectedRoles);
      setTempUser({
        name: currEditingUser.name,
        email: currEditingUser.email,
        phoneNumber: currEditingUser.phoneNumber,
        roleMatrices: currEditingUser.roleMatrices,
        status: currEditingUser.status,
      });
      form.setFieldsValue({
        name: currEditingUser.name,
        email: currEditingUser.email,
        phoneNumber: currEditingUser.phoneNumber,
        roleMatrices: currEditingUser.roleMatrices,
        status: currEditingUser.status,
        role: alreadySelectedRoles,
        sponsor: alreadySelectedSponsors,
      });
    } else {
      setTempUser(initialFormValues);
      setSelectedRoles([]);
      setSelectedSponsors([]);
    }
  }, [drawerType]);

  return (
    <div className="common-flex-row justify-end user-creation-form">
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        width="550"
        title={drawerType === "edit" ? "Edit User" : "Create User"}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmit}
          // submitBtn
          className="form"
        >
          <ReInput label="Name" name="name" type="simple" required />
          <ReInput label="Email ID" name="email" type="email" required />
          <ReInput
            label="Phone Number"
            name="phoneNumber"
            type="number"
            required
          />
          <ReSelect
            label="Sponsor/MAH"
            name="sponsor"
            type="multiple"
            items={allSponsors.map((sp) => {
              return {
                title: sp.name,
                value: sp.id,
                disable: sp.status === 0 || false,
              };
            })}
            onChange={(value: any) => {
              handleSelectChanges("sponsor", value);
            }}
            required
            searchable
          />
          <ReSelect
            label="Role"
            name="role"
            type="multiple"
            items={options}
            onChange={(value: any) => {
              handleSelectChanges("role", value);
            }}
            required
            searchable
          />
          <Form.Item label="Status" rules={[{ required: true }]} required>
            <Switch
              checked={tempUser.status === 1}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => {
                setTempUser((prevData) => ({
                  ...prevData,
                  status: checked ? 1 : 0,
                }));
              }}
            />
          </Form.Item>
          <UserRoleMatrix
            selectedSponsors={selectedSponsors}
            selectedRoles={selectedRoles}
            setTempUser={setTempUser}
            tempUser={tempUser}
          />
          <div className="common-flex-row add-btns">
            <Button type="primary" htmlType="submit">
              {drawerType === "create" ? "Submit" : "Update"}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Cancel
            </Button>
          </div>
        </ReusableForm>
      </Drawer>
    </div>
  );
};
