import { createSlice } from "@reduxjs/toolkit";
import { GetCaseStats } from "./Helpers/GetCasesStats";
import { GetCategorizationStats } from "./Helpers/GetCategorizationStats";

const initialState: any = {
    allCasesStats: {},
    allCategorizationStats: {},
    loading: true,
    error: "",
};

const DashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(GetCaseStats.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetCaseStats.fulfilled, (state, action) => {
            state.allCasesStats = action.payload;
            state.loading = false;
        });
        builder.addCase(GetCaseStats.rejected, (state, action) => {
            state.error = "Something went wrong";
            state.loading = false;
        });

        builder.addCase(GetCategorizationStats.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetCategorizationStats.fulfilled, (state, action) => {
            state.allCategorizationStats = action.payload;
            state.loading = false;
        });
        builder.addCase(GetCategorizationStats.rejected, (state, action) => {
            state.error = "Something went wrong";
            state.loading = false;
        });
    },
});

export default DashboardSlice.reducer;
