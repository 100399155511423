import { createAsyncThunk } from "@reduxjs/toolkit";
import { categorization } from "../../../API/Masters/Categorization/Categorization";


const getAllCategorization = createAsyncThunk(
  "ingredient/getAllCategorization",
  async () => {
    try {
      const res = await categorization.getAll()
      return res.data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllCategorization };
