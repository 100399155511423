import styled from "styled-components";

export const Container = styled.div``;
export const SubContainer = styled.div`
  padding: 1rem;
  height: calc(100vh - 58px);
  overflow-y: scroll;
  padding-bottom: 2rem;
  background-color: var(--bg);
`;
