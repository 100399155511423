import { Header, SideMenu } from "../../../Components";
import MedraTable from "../../../Components/ControlledVocabularies/MedraTable/MedraTable";

import * as Styles from "./MedraStyles";

function Medra() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer  className="subContainer">
          <MedraTable/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default Medra;
