import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getUsers } from "../../../slices/User/Helpers/GetAllUsers";
import { UserCreationForm } from "../../Drawers/UserCreationForm";
import * as Styles from "./ManageUsersStyles";
import { API_URL } from "../../../API/Config";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../../ControlledVocabularies/common";

function ManageUser() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [currEditingUser, setCurrEditingUser] = useState({});
  const [userDrawer, setUserDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (userDrawer.visibility === false) {
      setFlag(prevValue=>!prevValue);
    }
  }, [userDrawer]);

  const columns = [
    {
      title: "",
      width: 40,
      render: (record: any) => {
        return (
          <>
            {selectedTableRow.length !== 0 ? (
              ""
            ) : (
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCurrEditingUser(record);
                    setUserDrawer({
                      visibility: true,
                      type: "edit",
                    });
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 170,
    },
    {
      title: "Email ID",
      dataIndex: "email",
      width: 250,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];

  return (
    <Styles.Container>
      <UserCreationForm
        drawerType={userDrawer.type}
        visibility={userDrawer.visibility}
        setVisibility={setUserDrawer}
        currEditingUser={currEditingUser}
      />
      <ReTable
        name="allUsers"
        title="All Users"
        columns={columns}
        refresh={flag}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "id",
        }}
        uniView={{
          uniView: "user",
          viewName: "allUsers",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10
          }
        }}
        exportOption={{
          csv: {},
        }}
        headerButtons={[
          {
            title: "Add New",
            icon: <UserAddOutlined />,
            onClick: () => {
              setUserDrawer({
                visibility: true,
                type: "create"
              })
            },
          },
        ]}
      />
    </Styles.Container>
  );
}

export default ManageUser;
