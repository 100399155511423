import { Header, SideMenu } from "../../Components";
import ProfileDetailCard from "../../Components/Profile/ProfileDetails";

import * as Styles from "./ProfileStyles";

function Profile() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="profile-container">
          <ProfileDetailCard/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default Profile;
