import { BaseAPI } from "../../Common";
import { ICreateProduct, IUpdateProduct } from "./ProductInterfaces";

class Product extends BaseAPI {
  create(payload: ICreateProduct): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(productId: string, payload: IUpdateProduct): Promise<any> {
    return this.makeRequest(`update/${productId}`, "patch", payload);
  }

  getAll(name: string): Promise<any> {
    return this.makeRequest(`getMany`, "post", { name });
  }

  getProduct(id: string): Promise<any> {
    return this.makeRequest(`search/${id}`, "get");
  }
}

export const product = new Product("product");
