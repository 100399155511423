import moment from "moment";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { useEffect, useState } from "react";
import { Button, Space, Form } from "antd";
import { convertDateInReadableFormat, truncate } from "../../Healper/Methods";
import { Uniview } from "../../API/Uniview";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { API_URL } from "../../API/Config";
import { makeFirstLetterCapital } from "../../unity-ui-common/Methods/Methods";
import * as styles from "./ImportLogsStyles";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";

function ImportLogs() {
  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [logsTableData, setLogsTableData] = useState<any>([]);
  const [searchBtnLoading, setSearchBtnLoading] = useState<boolean>(false);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(0);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [generatingBtnLoading, setGeneratingBtnLoading] =
    useState<boolean>(false);
  const [importFilterQuery, setImportFilterQuery] = useState<any>({
    type: { "=": 5 },
    entityType: { "=": "All Imports" },
    createdAt: {
      ">": moment(new Date())
        .subtract(1, "month")
        .date(new Date().getDate()),
      "<": new Date(),
    },
  });

  const importLogsColumns = [
    {
      title: "Date of Import",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (date: any) => {
        return convertDateInReadableFormat(date);
      },
    },
    {
      title: "Day 0",
      key: "meta",
      dataIndex: "meta",
      render: (meta: string, data: any) => {
        const date = JSON.parse(meta).dateOfSearch;
        return date;
      },
    },
    {
      title: "Imported By",
      dataIndex: "owner",
      key: "owner",
      render: (data: any) => {
        return makeFirstLetterCapital(data.name);
      },
    },
    {
      title: "Source",
      key: "entityName",
      dataIndex: "entityName",
      render: (entityName: any) => {
        const text =
          entityName === "localLiterature" ? "Local Literature" : entityName;
        return makeFirstLetterCapital(text);
      },
    },
    {
      title: "Imported File Name",
      key: "meta",
      dataIndex: "meta",
      render: (meta: string) => {
        const importedCases = JSON.parse(meta).importedFileName;
        return importedCases;
      },
    },
    {
      title: "Number of Records",
      key: "meta",
      dataIndex: "meta",
      render: (meta: string) => {
        const importedCases = JSON.parse(meta).importedCases;
        return importedCases;
      },
    },
    {
      title: "Search Strategy",
      dataIndex: "searchStrategy",
      key: "searchStrategy",
      render: (searchStrategy: string) => {
        const text = searchStrategy ? searchStrategy : "-";
        return text;
      },
    },
  ];

  const handleFilterSubmit = async (values: any) => {
    const { date } = values;
    let data: any = {};

    data["startDate"] = moment(date[0]._d).format("DD-MM-YYYY");
    data["endDate"] = moment(date[1]._d).format("DD-MM-YYYY");
    data["selectedRowKeys"] = selectedRowKeys;

    let uniview;

    if (isGenerate) {
      uniview = new Uniview("import-engine", "import");
      setGeneratingBtnLoading(true);
      const res = await uniview.generatePdf(
        importFilterQuery,
        { orderBy: { createdAt: "DESC" } },
        "Import Log",
        "pdf",
        data
      );
      if (res.statusCode === 200) {
        ReNotification({
          description: "Your Report is Generating",
          placement: "bottomLeft",
          type: "success",
        });
      } else {
        ReNotification({
          description: "Something Went Wrong",
          placement: "bottomLeft",
          type: "error",
        });
      }
    }
    else {
      uniview = new Uniview("audit-logs", "import");
      setSearchBtnLoading(true);
      const option = {
        limit: 10,
        orderBy: { createdAt: "DESC" },
      };
      setImportFilterQuery((pre: any) => {
        return {
          ...pre,
          createdAt: {
            ">": moment(date[0]._d).format('YYYY-MM-DD')+'T00:00:00.000Z',
            "<": moment(date[1]._d).format('YYYY-MM-DD')+'T23:59:59.999Z'
          },
        };
      });
      const res = await uniview.fetchData(
        {
          ...importFilterQuery,
          createdAt: {
            ">": moment(date[0]._d).format('YYYY-MM-DD')+'T00:00:00.000Z',
            "<": moment(date[1]._d).format('YYYY-MM-DD')+'T23:59:59.999Z'
          },
        },
        option
      );
      if (res.status) {
        setLogsTableData(res.data);
        setPaginationPageSize(res.count);
      }
    }

    setGeneratingBtnLoading(false);
    setIsGenerate(false);
    setSearchBtnLoading(false);
  };
  
  const getAllImportLog = async (filter: any) => {
    const uniview = new Uniview("audit-logs", "import");
    const option = {
      limit: 10,
      orderBy: { createdAt: "DESC" },
    };
    const res = await uniview.fetchData(filter, option);
    if (res.status) {
      setLogsTableData(res.data);
      setPaginationPageSize(res.count);
    }
  };

  const handleFilterReset = () => {
    const query = importFilterQuery;
    delete query.createdAt;
    getAllImportLog(query);
  };

  useEffect(() => {
    form.setFieldsValue({
      date: [
        moment(new Date()).subtract(1, "month").date(new Date().getDate()),
        moment(new Date())
      ]
    });
  }, []);

  return (
    <styles.SubContainer>
      <ReusableForm
        formInstance={form}
        onSubmit={handleFilterSubmit}
        formStyles={{ marginBottom: "0.5rem" }}
      >
        <div className="formIitems">
          <ReDatePicker
            label="Date"
            name="date"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            featureDates
            width="100%"
            required
          />
          <Form.Item noStyle>
            <Space
              direction="horizontal"
              style={{ width: "7rem", marginTop: "5px" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={searchBtnLoading}
              >
                Search
              </Button>
              <Button htmlType="reset" onClick={handleFilterReset}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </div>
      </ReusableForm>

      <ReTable
        columns={importLogsColumns}
        data={logsTableData}
        name="importLog"
        title="Import Log"
        showTableStats
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: "checkbox",
          setSelectedRowKeys: setSelectedRowKeys,
          rowKey: "id",
        }}
        pagination={{
          total: paginationPageSize,
        }}
        headerButtons={[
          {
            title: "Export PDF",
            type: "primary",
            onClick: () => {
              setIsGenerate(true);
              form.submit();
            },
            loading: generatingBtnLoading,
          },
        ]}
        columnOptions={{
          sorting: {
            columns: ["createdAt", "owner"],
          },
          filter: {
            columns: [],
          },
        }}
        uniView={{
          uniView: "audit-logs",
          viewName: "import",
          apiUrl: API_URL,
          filter: importFilterQuery,
          option: {
            orderBy: {
              key: "createdAt",
              order: "DESC",
            },
            limit: 10
          },
        }}
      />
    </styles.SubContainer>
  );
}

export default ImportLogs;
