import Styled from "styled-components";

export const Body = Styled.div`
    display: grid;
    gap: 1rem;
    .section{
        margin-bottom: 1.5rem;
    }
    .heading{
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 0.25rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid  rgba(0,0,0,0.15);
    }
    .transAndAi{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .qualityCheckFrequency{
        .ant-form-item{
            margin: 0;
        }
        .optional{
            margin-top: 0.75rem;
            width: 63%;
            .ant-picker{
                width: 100%;
            }
        }
    }
    .qualityCheck{
        .qcState{
            position: relative;
            margin-block: 10px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            .ant-form-item{
                margin-bottom: 0;
            }
            .categoriesSelect{
                flex: 0 0 55%;
            }
        }
    }
    .notification{
        .ant-form-item{
            margin-bottom: 0.25rem;
        }
        .addSectionBtn{
            margin-top: 0.5rem;
        }
        .removeSectionBtn{
            margin-bottom: 0.5rem;
        }
    }
    
    .workflow {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        margin-bottom: 0.5rem;
        .ant-form-item{
            margin: 0;
            width: 75px;
        }
    }
`;