import { Button, Drawer, Select, Space, Tag, Tooltip } from "antd";
import * as Styles from "./CasesDraweStyles";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";
import DuplicateDrawer from "../DuplicateDrawer/DuplicateDrawer";
import { EyeOutlined, LinkOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../app/hooks";
import TranslationComparison from "../TranslationComparison/TranslationComparison";
import { Link } from "react-router-dom";
import WorkflowForm from "../WorkflowForm/WorkflowForm";
import {
  convertDateInReadableFormat,
  truncate,
} from "../../../Healper/Methods";
import {
  SourceMappingByValue,
  StageMappingByValue,
} from "../../../Healper/Mappings";
import CaseDetailView from "./CaseDetailView";

export const CasesDrawer = (props: {
  visibility: boolean;
  setVisibility: Function;
  type: string;
  width?: string;
  defaultTabId?: string;
}) => {
  const { visibility, type, setVisibility, width, defaultTabId } = props;
  const { currentAccessingCase } = useAppSelector((store) => store.cases);

  const onClose = () => {
    setVisibility({
      visibility: false,
    });
  };


  return (
    <Styles.Container>
      <Drawer
        className="drawer"
        placement="right"
        onClose={onClose}
        open={visibility}
        width={width}
        title={currentAccessingCase?.id}
      >
        <CaseDetailView
          visibility={visibility}
          setVisibility={setVisibility}
          type={type}
          width={width}
          defaultTabId={defaultTabId}
        />
      </Drawer>
    </Styles.Container>
  );
};
