import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Header, SideMenu } from "../../../Components";
import ActiveIngredientDrawer from "../../../Components/Master/ActiveIngredientDrawer/ActiveIngredientDrawer";
import * as Styles from "./ActiveIngredientStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../../../Components/ControlledVocabularies/common";
import { API_URL } from "../../../API/Config";

function ActiveIngredient() {
  const [activeIngredientDrawer, setActiveIngredientsDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [currentEditingIngredient, setCurrentEditingIngredient] = useState({});
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [flag, setFlag] = useState(false);
  
  const activeIngredientColumn = [
    {
      title: "",
      width: 40,
      render: (record: any) => {
        return (
          <Tooltip title="Edit Ingredient">
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentEditingIngredient(record);
                setActiveIngredientsDrawer({
                  visibility: true,
                  type: "edit",
                });
              }}
            />
          </Tooltip>
        );
      },
    },

    {
      title: "Ingredient Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Product ATC Code",
      dataIndex: "ATCCode",
      key: "ATCCode",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];
  useEffect(() => {
    if (activeIngredientDrawer.visibility === false) {
      setFlag(prevValue => !prevValue);
    }
  }, [activeIngredientDrawer]);

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ActiveIngredientDrawer
            drawerType={activeIngredientDrawer.type}
            visibility={activeIngredientDrawer.visibility}
            setVisibility={setActiveIngredientsDrawer}
            currentEditingIngredient={currentEditingIngredient}
          />
          <ReTable
            name="activeIngredients"
            title="Active Ingredients"
            columns={activeIngredientColumn}
            refresh={flag}
            scroll={{
              x: 800,
              y: 500,
            }}
            rowSelection={{
              selectedRowKeys: selectedTableRow,
              type: "checkbox",
              setSelectedRowKeys: setSelectedTableRow,
              rowKey: "id",
            }}
            columnOptions={{
              sorting: {
                columns: ["name", "ATCCode"],
              },
              filter: {
                columns: ["name", "ATCCode"],
              },
            }}
            headerButtons={[
              {
                title: "Add New",
                type: "primary",
                icon: <UserAddOutlined />,
                onClick: () => {
                  setActiveIngredientsDrawer({
                    visibility: true,
                    type: "create",
                  });
                },
              },
            ]}
            uniView={{
              uniView: "masterData",
              viewName: "activeIngredient",
              apiUrl: API_URL,
              option: {
                orderBy: {
                  key: "name",
                  order: "ASC",
                },
                limit: 10
              },
            }}
            exportOption={{
              csv: {},
            }}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default ActiveIngredient;
