export const booleanColumnFilterItems = [
    {
        text: "Yes",
        value: true,
    },
    {
        text: "No",
        value: false,
    },
]

export const duplicateColumnFilterItems = [
    {
        text: "Yes",
        value: true,
    },
    {
        text: "No",
        value: false,
    },
]