import Styled from "styled-components";

export const Container = Styled.div`
    padding: 0 1rem;
    height: 100vh;
    overflow-y:scroll;
    padding-bottom:10rem;
    .qc-radio-btn{
        display: flex;
        align-items: center;
        gap: 7px;
        p {
            font-weight: 500;
            margin: 0;
        }
        margin-block: 10px;
    }
    .tabBar{
        >.ant-tabs-nav{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            padding-inline: 16px;
            background-color: #fff;
        }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 15px;
    }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    .aiDataPreview{
        margin-bottom: 0.5rem;
    }
    .inTwoColumn{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }
    .addSectionBtn{
        margin-top: 0.5rem;
    }
    .removeSectionBtn{
        margin-bottom: 0.5rem;
    }
    .imeAndDme{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: 0.5rem;
    }
    .ageAndTitle{
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        gap: 0.5rem;
        .title{
            margin-top: 6px;
            width: 8rem
        }
    }
    .ftp-form{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 20px;
        .ant-form-item{
            margin-bottom: 10px;
        }
        .ant-divider{
            margin-block: 5px;
        }
    }
`;