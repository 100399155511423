import Styled from 'styled-components';

export const Container = Styled.div``;
export const SubContainer = Styled.div`
    width: 100%;
    .filter{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .form-wrapper{
        background-color: #ffffff;
        padding: 1.5rem 1rem 0.5em;
    }
    .bothTable{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
`;