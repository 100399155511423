export const getUrlParamString = () => {
  let query = window.location.search;
  query = query.replace(/^\?/, "");
  const index = query.indexOf("PaginationData=");
  return query.substring(index + "PaginationData=".length);
};

export function ArrayToCSV(headers: any, data: any, filename: any) {
  let csv = '"' + headers.join('","') + '"\n';
  data.forEach((row: any) => {
    if (!row.length) return;
    for (let i in row) row[i] = String(row[i]).replace(/"/g, '""');
    csv += '"' + row.join('","') + '"';
    csv += "\n";
  });
  const anchor = document.createElement("a");
  anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  anchor.target = "_blank";
  anchor.download = filename;
  anchor.click();
}