import { BaseAPI } from "../../Common";
import { ICreateLabel,IUpdateLabel } from "./Interface";

class LabelAssessment extends BaseAPI {
  create(payload: ICreateLabel): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(labelName: string, payload: IUpdateLabel): Promise<any> {
    return this.makeRequest(`update/${labelName}`, "patch", payload);
  }

  getAll(): Promise<any> {
    return this.makeRequest(`getMany`);
  }

}

export const labelAssessment = new LabelAssessment("label-assessment");
