import { Form,  Modal,  } from "antd";
import * as Styles from "./CreateVersionModalStyles";
import { useEffect,  } from "react";
import { importEngine } from "../../../API/ImportEngine";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReDatePicker from "../../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import moment from "moment";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function CreateVersionModal(props: {
  visibility: boolean;
  setVisibility: Function;
  setRadioBtnValue: Function;
  recordId?: string;
}) {
  const { visibility, setVisibility, setRadioBtnValue, recordId } = props;
  const [form] = Form.useForm();

  useEffect(()=>{
    form.setFieldsValue({
      caseId: recordId || '',
    })
  },[recordId]);

  async function handleSubmit(values :any) {
    const payload = {
      dateOfSearch: moment(values.dateOfSearch).format('DD-MMM-YYYY')
    };
    const res = await importEngine.createVersion(values.caseId, payload);
    if (res?.status) {
      ReNotification({
        description: res?.message || "New Version is Created Successfully",
        type: "success",
      });  
      setVisibility(false);
    } else {
      ReNotification({
        description: res?.message || "Failed to Created New Vesion.",
        type: "error",
      });  
      setVisibility(false);
    }
  }

  return (
    <Styles.Container>
      <Modal
        title="Create New Version"
        open={visibility}
        onOk={()=>{form.submit()}}
        okText="Create"
        closable={false}
        onCancel={() => {
          setVisibility(false);
          setRadioBtnValue(1);
          form.resetFields(['dateOfSearch']);
        }}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleSubmit}
        >
          <ReInput
            name='caseId'
            label="Record ID"
            type="simple"
            placeholder="Record id"
            required
          />
          <ReDatePicker
            label="Day 0"
            name="dateOfSearch"
            dateFormat="DD-MMM-YYYY"
            featureDates
            type="simple"
            width="100%"
            required
          />
        </ReusableForm>
      </Modal>
    </Styles.Container>
  );
}

export default CreateVersionModal;
