import { createAsyncThunk } from "@reduxjs/toolkit";
import { auditlogs } from "../../../API/AuditLog";

const GetAuditLogs = createAsyncThunk(
    "audit-logs/GetAuditLogs",
    async () => {
        try {
            const data = await auditlogs.getAllAuditLogs()
            return data;
        } catch (err) {
            console.log("error");
        }
    }
);

export { GetAuditLogs };