import styled from "styled-components";

export const Container = styled.div`
    .manual{
        border: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        font-size: 1rem;
        margin-bottom: 10px;
        cursor: pointer;
        transition: 0.25s ease-in-out;
        padding: 10px;
        :hover{
            scale: 1.005;
            border-color: #DD2515;
            color: #DD2515;
        }
        svg{
            font-size: 1.1rem;
        }
    }
    .pdf-viewer{
        cursor: pointer;
        position: fixed;
        top: -9.5%;
        bottom: -9.5%;
        left: -2px;
        right: 0;
        z-index: 99999999999;
    }
    .pdf-close-btn{
        position: fixed;
        top: 10px;
        left: 10px;

        z-index: 99999999999;
    }
`;