import { createSlice } from "@reduxjs/toolkit";
import { PermissionTypes } from "../../Components/Types/TableColumn.types";
import { getAllPermission } from "./Helpers/GetAllPermission";

interface PermissionState {
  allPermissions: PermissionTypes[];
  loading: boolean;
  error: string;
}

const initialState: PermissionState = {
  allPermissions: [],
  loading: true,
  error: "",
};

const RolesSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getAllPermission.pending, (state) => {
            state.loading = true;
          });
          builder.addCase(getAllPermission.fulfilled, (state, action) => {
            state.allPermissions = action.payload;
          });
          builder.addCase(getAllPermission.rejected, (state, action) => {
            state.error = "Something went wrong";
          });
        }
});

export default RolesSlice.reducer;
