import { BaseAPI } from "../Common";

class ControlledVocabularies extends BaseAPI {
  getManyCountries(): Promise<any> {
    return this.makeRequest(`country/getMany`);
  }
  getManyDosageForms(): Promise<any> {
    return this.makeRequest(`dosageForms/getMany`);
  }
  getManyOutcome(): Promise<any> {
    return this.makeRequest(`outcome/getMany`);
  }
  getManyRouteOfAdministration(): Promise<any> {
    return this.makeRequest(`route-of-administration/getMany`);
  }
  getManyUnitOfMeasurement(): Promise<any> {
    return this.makeRequest(`unit-of-measurement/getMany`);
  }
  getManySeriousness(): Promise<any> {
    return this.makeRequest(`seriousness/getMany`);
  }
  getManyIME(): Promise<any> {
    return this.makeRequest(`important-medical-event/getMany`);
  }
  getManyDME(): Promise<any> {
    return this.makeRequest(`designated-medical-event/getMany`);
  }
  getManyMedra(query?: string): Promise<any> {
    const url = query ? `medra/getMany?query=${query}` : "medra/getMany";
    return this.makeRequest(url);
  }
  searchMedraCoding(lltCode: any): Promise<any> {
    return this.makeRequest(`search/${lltCode}`);
  }
}

export const controlledVocabularies = new ControlledVocabularies(
  "controlled-vocabularies"
);
