import moment from "moment";
import { convertDate } from "../Utils/Util";

export const getTableColumnFilterItems = (data: any, key: string) => {
  const items: string[] = [];

  const arrayTypeKeys = [
    "sitesToBeNotified",
    "sitesNotified",
    "sitesAcknowledged",
    "sitesNotifiedToIRB",
  ];
  const dateKeys = [
    "lrd",
    "created_at",
    "sent_on",
    "acknowledged_on",
    "irb_notified_on",
    "sentOn",
    "acknowledgedOn",
    "irbNotifiedOn",
  ];

  if (dateKeys.includes(key)) {
    data.forEach((rc: any) => {
      if (rc[key]) {
        if (
          !items.includes(
            convertDate(new Date(rc[key]), { timeRequired: false })
          )
        )
          items.push(convertDate(new Date(rc[key]), { timeRequired: false }));
      } else if (!items.includes("-")) items.push("-");
    });
  } else if (arrayTypeKeys.includes(key)) {
    data.forEach((rc: any) => {
      if (!items.includes(rc[key].length)) items.push(rc[key].length);
    });
  } else if (key === "type") {
    data.forEach((rc: any) => {
      let itemValue;
      if (rc.type === 100) {
        itemValue = rc.type_value;
      } else {
        itemValue = `${rc.type}-day SUSAR`;
      }
      if (!items.includes(itemValue)) items.push(itemValue);
    });
  } else {
    data.forEach((rc: any) => {
      if (!items.includes(rc[key])) items.push(rc[key]);
    });
  }
  return items.map((value: any) => {
    return {
      text: value,
      value: value,
    };
  });
};

// export const CategorizationTableFilter=
export const formatDate = (dateString: string): string => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [year, month, day] = dateString.split("-").map(Number);

  return `${day} ${months[month - 1]} ${year}`;
};

export const supportIdGenerator = () => {
  return Math.random().toString(36).substr(2, 9);
};

export function truncate(str: any, maxLength: any) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + ".....";
  } else {
    return str;
  }
}

export function convertDateInReadableFormat(date: string, time?: boolean, format?: string) {
  if (!date) return ""
  try {
    const defaultFormate = format ? format : "DD-MMM-YYYY"
    const dateValue = moment(date).utc().format(defaultFormate)
    const timeValue = moment(date).utc().format("HH:mm:ss")
    return time ? `${dateValue}, ${timeValue}` : `${dateValue}`
  }
  catch (err) {
    return "";
  }
}

export function disabledFutureDate(current: any) {
  return current && current > moment().endOf('day');
}

export function convertToCamelCase(str: string) {
  const words = str.toLowerCase().split(' ');

  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  return camelCaseWords.join('');
}

export function capitalizeSubstring(str: string, substring: string) {
  return str.replace(new RegExp(substring, 'gi'), match => match.toUpperCase());
}


export function convertToSimpleCase(str: string) {
  // Use regex to split the camel case string into words
  const words = str.split(/(?=[A-Z])/);

  // Join the words with a space separator
  const simpleCaseString = words.join(' ');

  return simpleCaseString.toLowerCase();
}


export const getRandomDarkColor = (prevGeneratedColors?: string[]) => {
  const minColorValue = 50; // Adjust this value to set the minimum color component value
  const maxColorValue = 200; // Adjust this value to set the maximum color component value
  let newColor = "";
  if (prevGeneratedColors) {
    do {
      const r = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
      const g = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
      const b = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
      newColor = `rgb(${r}, ${g}, ${b})`;
    } while (prevGeneratedColors.includes(newColor));
  }
  else {
    const r = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
    const g = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
    const b = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
    newColor = `rgb(${r}, ${g}, ${b})`;
  }

  return newColor;
};

export function removeDuplicatesFromArray(arr: string[]) {
  let unique: string[] = [];
  arr.forEach((ele) => {
    if (unique.indexOf(ele) === -1) {
      unique.push(ele);
    }
  })
  unique.sort();
  return unique;
}

export function capitalizeWordsExceptExceptions(str: string, exceptions: any) {
  const words = str.split(" ");
  const capitalizedWords = words.map((word) => {
    const lowerCaseWord = word.toLowerCase();
    if (exceptions.includes(lowerCaseWord)) {
      return lowerCaseWord;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  return capitalizedWords.join(" ");
}


export const sortRecordsBasedOnIds = (arrayToSort: any[]) => {
  if (!arrayToSort || arrayToSort?.length === 0) return arrayToSort;
  let sortedArray: any = [];
  sortedArray = arrayToSort?.sort((a: any, b: any) => {
    const [prefixA, middleA, suffixA] = a.id.split("-");
    const [prefixB, middleB, suffixB] = b.id.split("-");

    if (middleA !== middleB) {
      return parseInt(middleA) - parseInt(middleB);
    } else if (prefixA !== prefixB) {
      return prefixA.localeCompare(prefixB);
    } else {
      return parseInt(suffixA) - parseInt(suffixB);
    }
  });

  return sortedArray;
}

export const formatNumberDecimal = (number: string) => {
  if (Number.isInteger(number)) {
    return number;
  } else {
    return parseFloat(number).toFixed(2);
  }
}

export const selectRandomItemsFromAnArray = (array: any[], numberOfItemsToSelect: number): any[] => {
  const length = array.length;
  const selectedIndices = new Set();

  if (numberOfItemsToSelect >= length) {
    return array.slice();
  }

  while (selectedIndices.size < numberOfItemsToSelect) {
    const randomIndex = Math.floor(Math.random() * length);
    selectedIndices.add(randomIndex);
  }

  return Array.from(selectedIndices).map((index: any) => array[index]);
}


export const determineAgeGroup = (age: number, unit: string) => {
  if ((!age && age !== 0) || !unit) return null;

  const ageUnitsInMilliseconds: any = {
    years: 365 * 24 * 60 * 60 * 1000,
    months: 30 * 24 * 60 * 60 * 1000,
    days: 24 * 60 * 60 * 1000,
    weeks: 7 * 24 * 60 * 60 * 1000,
    hours: 60 * 60 * 1000,
    minutes: 60 * 1000
  };

  const ageGroups = [
    { value: 1, title: "Neonate", minAge: 0, maxAge: 1 * ageUnitsInMilliseconds.months },
    { value: 2, title: "Infant", minAge: 1 * ageUnitsInMilliseconds.months, maxAge: 24 * ageUnitsInMilliseconds.months },
    { value: 3, title: "Child", minAge: 24 * ageUnitsInMilliseconds.months, maxAge: 12 * ageUnitsInMilliseconds.years },
    { value: 4, title: "Adolescent", minAge: 12 * ageUnitsInMilliseconds.years, maxAge: 18 * ageUnitsInMilliseconds.years },
    { value: 5, title: "Adult", minAge: 18 * ageUnitsInMilliseconds.years, maxAge: 65 * ageUnitsInMilliseconds.years },
    { value: 6, title: "Elderly", minAge: 65 * ageUnitsInMilliseconds.years, maxAge: Infinity }
  ];

  const ageInMilliseconds = age * ageUnitsInMilliseconds[unit.toLowerCase()];
  
  for (let i = 0; i < ageGroups.length; i++) {
    if (ageInMilliseconds >= ageGroups[i].minAge && ageInMilliseconds < ageGroups[i].maxAge) {
      return ageGroups[i];
    }
  }

  return null;
}