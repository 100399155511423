import { BaseAPI } from "./Common";

export interface IAuditLogSearchPayload {
    entityType?: string,
    operationType?: string,
    type?: number,
    caseId?: string,
}

class AuditLog extends BaseAPI {
    async getAuditLogs(payload?: IAuditLogSearchPayload) {
        let searchUrl;
        searchUrl = "find?"
        if (payload) {
            for (const [key, value] of Object.entries(payload)) {
                if (value !== undefined) {
                    searchUrl += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
                }
            }
        }
        return await this.makeRequest(searchUrl).then((response) => {
            return response
        })
    }

    async getAllAuditLogs() {
        return await this.makeRequest("find").then((response) => {
            return response
        });
    }

    async getQcBatchAuditLogs(id: string) {
        return await this.makeRequest(`qcBatchAuditLogs/${id}`).then((response) => {
            return response
        });
    }
}

export const auditlogs = new AuditLog("audit-logs")