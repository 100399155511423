export const GenderMappingByValue = (value: number) => {
  switch (value) {
    case 0:
      return "male";
    case 1:
      return "female";
    case 2:
      return "other";
    case 3:
      return "unknown";
    default:
      return "-";
  }
};
export const GenderMappingByTitle = (title: string) => {
  switch (title) {
    case "male":
      return 0;
    case "female":
      return 1;
    case "other":
      return 2;
    case "unknown":
      return 3;
    default:
      return -1;
  }
};

export const AgeGroupMappingByValue = (value: any) => {
  switch (value) {
    case 0:
      return "foetus";
    case 1:
      return "neonate";
    case 2:
      return "infant";
    case 3:
      return "child";
    case 4:
      return "adolescent";
    case 5:
      return "adult";
    case 6:
      return "elderly";
    default:
      return "-";
  }
};
export const AgeGroupMappingByTitle = (title: string) => {
  switch (title) {
    case "foetus":
      return 0;
    case "neonate":
      return 1;
    case "infant":
      return 2;
    case "child":
      return 3;
    case "adolescent":
      return 4;
    case "adult":
      return 5;
    case "elderly":
      return 6;
    default:
      return -1;
  }
};

export const SourceMappingByValue = (value: string) => {
  switch (value) {
    case "pubmed":
      return "PubMed";
    case "embase":
      return "Embase";
    case "localLiterature":
      return "Local Literature";
    default:
      return "Local Literature";
  }
};

export const StageMappingByValue = (data: string | number) => {
  switch (data) {
    case 0:
      return {
        text: "WF 1",
        color: "yellow",
      };
    case 1:
      return {
        text: "WF 2",
        color: "blue",
      };
    case 2:
      return {
        text: "Processed",
        color: "green",
      };
    case 3:
      return {
        text: "QC",
        color: "orange",
      };
    default:
      return {
        text: "Not Assigned",
        color: "red",
      };
  }
};

export const KPIStatusMapping = {
  "No Data": "default",
  "Late": "red",
  "On Time": "green",
};

export const YesNoFilterItems = [
  {
    text: "Yes",
    value: true,
  },
  {
    text: "No",
    value: false,
  },
];
export const IsDuplicateFilter = [
  {
    text: "Yes",
    value: 1,
  },
  {
    text: "No",
    value: 0,
  },
  {
    text: "Potentially Yes",
    value: 2,
  },
];

export const CategorizationTableFilter = [
  {
    text: "Valid ICSR",
    value: 0,
  },

  {
    text: "Potential ICSR",
    value: 1,
  },

  {
    text: "Non-Relevant Publication",
    value: 2,
  },

  {
    text: "Potentially Relevant for Safety",
    value: 3,
  },
];

export const CategorizationFilter = (data: number) => {
  switch (data) {
    case 0:
      return "Valid ICSR";
    case 1:
      return "Potential ICSR";
    case 2:
      return "Non-Relevant Publication";
    case 3:
      return "Potentially Relevant for Safety";
    case 4:
      return "Aggregate Report Relevant";
    case 5:
      return "Potentially Relevant for Signaling"
    case 6:
      return "Product Quality Complaint";
    default:
      return "-";
  }
};


export const CategorizationMapping = (data: string) => {
  switch (data) {
    case "Valid ICSR":
      return 0;
    case "Potential ICSR":
      return 1;
    case "Non-Relevant Publication":
      return 2;
    case "Potentially Relevant for Safety":
      return 3;
    case "Aggregate Report Relevant":
      return 4;
    case "Potentially Relevant for Signaling":
      return 5;
    case "Product Quality Complaint":
      return 6;
    default:
      return "NA";
  }
};

export const StatusEnumToValueMapping = (data: string | number) => {
  const index = typeof data === 'string' ? parseInt(data) : data;
  const statusEnum = ["REQUIRED", "SENT", "RECEIVED"];
  return statusEnum[index];
};

export const StatusValueToEnumMapping = (data: string) => {
  switch (data) {
    case "REQUIRED":
      return 0;
    case "SENT":
      return 1;
    case "RECEIVED":
      return 2;
    default:
      return "NA";
  }
};

export const StatusEnumToValueDisplay = (data: string | number) => {
  const index = typeof data === 'string' ? parseInt(data) : data;
  const statusEnum = [
    {
      color: 'red',
      text: 'Required'
    },
    {
      color: 'yellow',
      text: 'Sent'
    },
    {
      color: 'green',
      text: 'Received'
    },
    {
      color: 'default',
      text: 'NA'
    }
  ];
  return statusEnum[index] || statusEnum[3];
};

export const CategorizationDisplay = (data: number) => {
  switch (data) {
    case 0:
      return {
        text: "Valid ICSR",
        color: "#a3e635",
      };

    case 1:
      return {
        text: "Potential ICSR",
        color: "#8b5cf6",
      };

    case 2:
      return {
        text: "Non-Relevant Publication",
        color: "#34d399",
      };

    case 3:
      return {
        text: "Potentially Relevant for Safety",
        color: "#d4b106",
      };
    case 4:
      return {
        text: "Aggregate Report Relevant",
        color: "#d4b106",
      };
    case 5:
      return {
        text: "Potentially Relevant for Signaling",
        color: "#d4b106",
      };
    case 6:
      return {
        text: "Product Quality Complaint",
        color: "#d4b106",
      };

    default:
      return {
        text: "-",
        color: "#020617",
      };
  }
};

export const GenderFilter = (gender: any) => {
  switch (gender) {
    case "male":
      return 0;
    case "female":
      return 1;
    case "other":
      return 2;
    case "unknown":
      return 3;
    default:
      return 3;
  }
};



export const AgeGroupFilter = (data: any) => {
  switch (data) {
    case "foetus":
      return 0;
    case "neonate":
      return 1;
    case "infant":
      return 2;
    case "child":
      return 3;
    case "adolescent":
      return 4;
    case "adult":
      return 5;
    case "elderly":
      return 6;
    default:
      return "";
  }
};

export const DuplicateCaseTableDisplay = (data: any) => {
  // 0 , 1, 2
  switch (data) {
    case 0:
      return {
        text: "No",
        color: "red",
        label: "No",
        value: 0,
      };
    case 1:
      return {
        text: "Yes",
        color: "green",
        label: "Yes",
        value: 1,
      };
    case 2:
      return {
        text: "Potential",
        color: "yellow",
        label: "Potential",
        value: 2,
      };
    default:
      return {
        text: "-",
        color: "",
      };
  }
};

export const YesNoTableDisplay = (data: any) => {
  switch (data) {
    case true:
      return {
        text: "Yes",
        color: "green",
        value: true,
      };

    case false:
      return {
        text: "No",
        color: "red",
        value: false,
      };

    default:
      return {
        text: "-",
        color: "",
      };
  }
};

export const StageTableFilter = [
  {
    text: "WF 1",
    value: 0,
  },
  {
    text: "WF 2",
    value: 1,
  },
  {
    text: "Processed",
    value: 2,
  },
  {
    text: "QC",
    value: 3,
  },
];

export const daysOfWeek = [
  { value: 'sunday', title: 'Sunday' },
  { value: 'monday', title: 'Monday' },
  { value: 'tuesday', title: 'Tuesday' },
  { value: 'wednesday', title: 'Wednesday' },
  { value: 'thursday', title: 'Thursday' },
  { value: 'friday', title: 'Friday' },
  { value: 'saturday', title: 'Saturday' }
];

export const numbersOneToTwentyEight = [
  { value: 1, title: '1st of Every Month' },
  { value: 2, title: '2nd of Every Month' },
  { value: 3, title: '3rd of Every Month' },
  { value: 4, title: '4th of Every Month' },
  { value: 5, title: '5th of Every Month' },
  { value: 6, title: '6th of Every Month' },
  { value: 7, title: '7th of Every Month' },
  { value: 8, title: '8th of Every Month' },
  { value: 9, title: '9th of Every Month' },
  { value: 10, title: '10th of Every Month' },
  { value: 11, title: '11th of Every Month' },
  { value: 12, title: '12th of Every Month' },
  { value: 13, title: '13th of Every Month' },
  { value: 14, title: '14th of Every Month' },
  { value: 15, title: '15th of Every Month' },
  { value: 16, title: '16th of Every Month' },
  { value: 17, title: '17th of Every Month' },
  { value: 18, title: '18th of Every Month' },
  { value: 19, title: '19th of Every Month' },
  { value: 20, title: '20th of Every Month' },
  { value: 21, title: '21st of Every Month' },
  { value: 22, title: '22nd of Every Month' },
  { value: 23, title: '23rd of Every Month' },
  { value: 24, title: '24th of Every Month' },
  { value: 25, title: '25th of Every Month' },
  { value: 26, title: '26th of Every Month' },
  { value: 27, title: '27th of Every Month' },
  { value: 28, title: '28th of Every Month' }
];


export const qcBatchStatus: any = {
  'Not Assigned': 'red',
  'In Progress': 'gold',
  'Qc Pending': 'blue',
  'Qc In Progress': 'purple',
  'QC Not Required': 'lime',
  'Completed': 'green',
}

export const qcBatchStatusAvatar: any = {
  'Not Assigned': '#FF4122 ',
  'In Progress': '#F7B800',
  'Qc Pending': '#ADD8E6',
  'Qc In Progress': '#9C39FF',
  'QC Not Required': '#38CF38',
  'Completed': '#088408',
}

export const qcBatchStatusIcons: any = {
  'Not Assigned': 0,
  'In Progress': 1,
  'Qc Pending': 2,
  'Qc In Progress': 1,
  'QC Not Required': 2,
  'Completed': 2,
}