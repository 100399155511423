import { BaseAPI } from "../../Common";
import { ICreateCategorization, IUpdateCategorization } from "./Interface";
 

class Categorization extends BaseAPI {
  create(payload: ICreateCategorization): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(categoryId: string, payload: IUpdateCategorization): Promise<any> {
    return this.makeRequest(`update/${categoryId}`, "patch", payload);
  }

  getAll() {
    return this.makeRequest(`getMany`);
  }

}

export const categorization = new Categorization("categorization");
