import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";

const getAllDosageForms = createAsyncThunk(
  "controlledVocabularies/getAllDosageForms",
  async () => {
    try {
      const data = await controlledVocabularies.getManyDosageForms()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllDosageForms };
