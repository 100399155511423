import { Form, Drawer, Switch } from "antd";
import { useEffect, useState } from "react";
import { categorization } from "../../../API/Masters/Categorization/Categorization";
import { useAppDispatch } from "../../../app/hooks";
import { createCategorization } from "../../../slices/Categorization/Helpers/createCategorization";
import { getAllCategorization } from "../../../slices/Categorization/Helpers/getAllCategorization";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function CategorizationDrawer(props: {
  drawerType: string;
  visibility: boolean;
  setVisibility: Function;
  currentEditingCategory?: any;
}) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { drawerType, visibility, setVisibility, currentEditingCategory } = props;
  const [switchStatus, setSwitchStatus] = useState(1);

  const onClose = () => {
    setVisibility({
      visibility: false,
      drawerType: "create",
    });
    setSwitchStatus(1);
    form.resetFields();
  };

  const submitFormHandler = async(values:any) => {
    const payload = {
      name: values.name,
      status: switchStatus,
    }
    let res;
    if (drawerType === "create") {
      res = await categorization.create(payload);
    }
    else {
      res = await categorization.update(currentEditingCategory.id, payload);
    }
    if(res?.id){
      ReNotification({
        description: `Categorization ${drawerType === "create" ? "Created" : "Updated"} Successfully`,
        type: "success",
      });
    }
    dispatch(getAllCategorization());
    onClose();
  };

  useEffect(() => {
    if (drawerType === "edit") {
      form.setFieldsValue({
        name: currentEditingCategory.name,
      })
      setSwitchStatus(currentEditingCategory.status);
    }
  }, [drawerType]);

  return (
    <div>
      <Drawer 
        placement="right" 
        onClose={onClose} 
        open={visibility}
        title={`${drawerType === "edit" ? "Update" : "Create"} Category`}
        >
        <ReusableForm
          formInstance={form}
          onSubmit={submitFormHandler}
          submitBtn
        >
          <ReInput
            label="Name"
            name="name"
            required
            type="simple"
          />
          <Form.Item label="Status" rules={[{ required: true }]}>
            <Switch
              checked={switchStatus === 1}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => {
                setSwitchStatus(checked ? 1 : 0);
              }}
            />
          </Form.Item>
        </ReusableForm>
      </Drawer>
    </div>
  );
}

export default CategorizationDrawer;
