import { Header, SideMenu } from "../../../Components";
import ImportDataForm from "../../../Components/Records/ImportDataForm/ImportDataForm";
import ImportLogs from "../../../Components/ImportLogs/ImportLogs";
import * as Styles from "./ImportStyles";

function ImportData() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ImportDataForm />
          <ImportLogs />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default ImportData;
