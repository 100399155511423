import { useEffect, useState } from 'react';
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { SelectOutlined } from '@ant-design/icons';

export default function AttachementList(props: {
    allFileData: any
}) {
    const { allFileData } = props
    const [tableData, setTableData] = useState<any[]>([]);

    useEffect(() => {
        setTableData([allFileData.attachment, ...allFileData.ftpAttacment || [], ...allFileData.translateAttacment || []]);
    }, [allFileData])

    const attachmentListTableColumn = [
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (data: string) => {
                switch (data) {
                    case "ftp":
                        return 'Full Text Publication File';
                    case "translate":
                        return 'Translation File';
                    case "attachment":
                        return 'Attachment File';
                    default:
                        return "-";
                }
            }
        },
        {
            title: "URL",
            dataIndex: "url",
            key: "url",
            render: (data: string) => {
                return <a href={data} target='_blank'>Open File <SelectOutlined /></a>
            }
        },
    ];



    return (
        <>
            {tableData.length > 0 && <ReTable
                name="attachmentList"
                title="Attachment List"
                columns={attachmentListTableColumn}
                data={tableData}
            />}
        </>
    )
}
