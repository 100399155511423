import { BaseAPI } from "../Common";
import { IRole } from './Interfaces';

class Role extends BaseAPI {
    create(payload: IRole): Promise<any> {
        return this.makeRequest(`create`, "post", payload)
    }

    update(roleId: string, payload: IRole): Promise<any> {
        return this.makeRequest(`${roleId}`, "patch", payload)
    }

    getAll(): Promise<any> {
        return this.makeRequest(`all`)
    }

    getRole(roleId: string): Promise<any> {
        return this.makeRequest(`${roleId}`)
    }
    getRoleAndPermission(roleId: string, switchWorkspace: boolean = false, tenantId = ""): Promise<any> {
        const userData = JSON.parse(sessionStorage.getItem("user") || '{}');
        const tenantData = JSON.parse(sessionStorage.getItem("selectedWorkSpace") || '{"name": "", "id": ""}')
        let id = ''
        if (tenantId) {
            id = tenantId
        }
        else {
            id = tenantData.id
        }

        userData.permissions = userData.permissions || []
        return this.makeRequest(`${roleId}?switch=${switchWorkspace}&tenantId=${id}`, "get").then(response => {
            if (response) {
                if (response?.permissions) {
                    let permission = response.permissions.map((permission: any) => {
                        return permission.type || ""
                    })
                    userData.permissions.push(...permission)
                    sessionStorage.setItem("user", JSON.stringify(userData));
                }

                return true
            }
        })

    }

    getPermissions(roleId: string): Promise<any> {
        return this.makeRequest(`permissions`)
    }
}

export const role = new Role("role");
