import { Tag } from "antd";
import { useState } from "react";
import * as Styles from "./RouteAdministrationStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { API_URL } from "../../../API/Config";

function RouteOfAdministrationTable() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);

  const administrationColumn = [
    {
      title: "Route of Administration",
      dataIndex: "label",
    },
    {
      title: "Current Flag",
      dataIndex: "currentFlag",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];

  return (
    <Styles.SubContainer>
      <ReTable
        name="routeOfAdministration"
        title="Route of Administration"
        columns={administrationColumn}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "code",
        }}
        uniView={{
          uniView: "controlled-vocabularies",
          viewName: "routeOfAdministration",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "code",
              order: "ASC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
      />
    </Styles.SubContainer>
  );
}

export default RouteOfAdministrationTable;
