import Styled from 'styled-components';

export const MainWrapper = Styled.div`
  .record-view-heading{
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem 1vw;
  }
  .component-wrapper{
    padding: 1rem 1vw;
    padding-top: 0;
  }
`;