import { createAsyncThunk } from "@reduxjs/toolkit";
import { categorization } from "../../../API/Masters/Categorization/Categorization";

const createCategorization = createAsyncThunk(
  "categorization/createCategorization",
  async (categorizationData:any) => {
    try {
      const data = await categorization.create(categorizationData)
      return data;
      
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { createCategorization };
