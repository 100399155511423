import { Select } from "antd";

export const subCategorization = [
  "Lack of Efficacy",
  "Overdose",
  "Misuse",
  "Abuse",
  "Special Populations",
  "Off Label Use",
  "Medication Error",
  "New Benefit Risk Assessment",
  "Other",
];

export const ageGroupItems = [
  {
    value: 0,
    title: "Foetus",
  },
  {
    value: 1,
    title: "Neonate",
  },
  {
    value: 2,
    title: "Infant",
  },
  {
    value: 3,
    title: "Child",
  },
  {
    value: 4,
    title: "Adolescent",
  },
  {
    value: 5,
    title: "Adult",
  },
  {
    value: 6,
    title: "Elderly",
  },
];

export const CommonCategorizationOptions = [
  {
    value: 0,
    title: "Valid ICSR"
  },
  {
    value: 1,
    title: "Potential ICSR"
  },
  {
    value: 2,
    title: "Non-Relevant Publication"
  },
  {
    value: 3,
    title: "Potentially Relevant for Safety"
  },
  {
    value: 4,
    title: "Aggregate Report Relevant"
  },
  {
    value: 5,
    title: "Potentially Relevant for Signaling"
  },

  {
    value: 6,
    title: "Product Quality Complaint"
  },
];

export const CommonSubCategorizationOptions = [
  {
    value: "Lack of Efficacy",
    title: "Lack of Efficacy"  
  },
  {
    value: "Overdose",
    title: "Overdose"  
  },
  {
    value: "Misuse",
    title: "Misuse"  
  },
  {
    value: "Abuse",
    title: "Abuse"  
  },
  {
    value: "Special Populations",
    title: "Special Populations"  
  },
  {
    value: "Off Label Use",
    title: "Off Label Use"  
  },
  {
    value: "Medication Error",
    title: "Medication Error"  
  },
  {
    value: "New Benefit Risk Assessment",
    title: "New Benefit Risk Assessment"  
  },
  {
    value: "Other",
    title: "Other"  
  },
];

export const CommonGenderFieldOptions = [
  {
    value: 0,
    title: "Male"  
  },
  {
    value: 1,
    title: "Female"  
  },
  {
    value: 2,
    title: "Other"  
  },
  {
    value: 3,
    title: "Unknown"  
  }
];

export const CommonAgeGroupFieldOptions = [
  {
    value: 0,
    title: "Foetus",
  },
  {
    value: 1,
    title: "Neonate",
  },
  {
    value: 2,
    title: "Infant",
  },
  {
    value: 3,
    title: "Child",
  },
  {
    value: 4,
    title: "Adolescent",
  },
  {
    value: 5,
    title: "Adult",
  },
  {
    value: 6,
    title: "Elderly",
  },
];

export const CommonSeriousnessField = (allSeriousness: any) => {
  return (
    <Select showSearch mode="multiple" allowClear>
      {allSeriousness?.map((item: any) => {
        const { value, title } = item;
        return <Select.Option value={value}>{title}</Select.Option>;
      })}
    </Select>
  );
};

export const CommonProductNameFeild = (allProduct: any) => {
  return (
    <Select showSearch allowClear>
      {allProduct?.map((item: any) => {
        const { title, value } = item;
        return <Select.Option value={value}>{title}</Select.Option>;
      })}
    </Select>
  );
};

export const CommonCountryOfOriginFeild = (allCountries: any) => {
  return (
    <Select showSearch allowClear>
      {allCountries?.map((co: any) => {
        const { title, value } = co;
        return <Select.Option value={value}>{title}</Select.Option>;
      })}
    </Select>
  );
};