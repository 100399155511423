import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as Styles from "./CaseHistoryStyles";
import { GetCaseHistory } from "../../../slices/AuditLogs/Healper/GetCaseHistory";
import { convertDateInReadableFormat } from "../../../Healper/Methods";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";

function CaseHistory() {
  const dispatch = useAppDispatch();
  const { caseHistory } = useAppSelector((store) => store.auditLogs);
  const { currentAccessingCase } = useAppSelector((store) => store.cases);

  const AuditTableColumn = [
    {
      title: "Action Type",
      key: "operationType",
      dataIndex: "operationType",
      width: 160,
    },
    {
      title: "Action Taken By",
      dataIndex: "owner",
      key: "owner",
      width: 190,
      render: (owner: any) => {
        return owner?.name;
      },
    },
    {
      title: "Action Date and Time",
      dataIndex: "createdAt",
      key: "created_at",
      width: 220,
      render: (date: any) => {
        return (date && convertDateInReadableFormat(date, true)) || "-";
      },
    },
  ];

  useEffect(() => {
    dispatch(GetCaseHistory({ caseId: currentAccessingCase.id }));
  }, [currentAccessingCase]);

  return (
    <ReTable
      name="recordHistory"
      title="Record History"
      columns={AuditTableColumn}
      data={caseHistory}
    />
  );
}

export default CaseHistory;
