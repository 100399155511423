import * as Styles from "./HeaderStyles";
import Logo from "../../Assets/logo.svg";
import { Avatar, Card, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { role } from "../../API/UserManagement/Role";
import { tenant } from "../../API/UserManagement/Tenant";
import { useAppDispatch } from "../../app/hooks";
import { updateSubMenuActiveId } from "../../slices/SideMenu/SideMenuSlices";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { handleUserLogin } from "../../slices/Session/Session";
import { removeDuplicatesFromArray } from "../../Healper/Methods";

function Header() {
  const navigate = useNavigate();
  const { Meta } = Card;
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [switchModalOpen, setSwitchModalOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState<any>([]);
  const [roleData, setRoleData] = useState([]);
  const [dataId, setDataId] = useState();
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    JSON.parse(
      sessionStorage.getItem("selectedWorkSpace") || '{"name": "", "id": ""}'
    )
  );

  const showSwitchModal = (id: any) => {
    setSwitchModalOpen(true);
    setDataId(id);
  };

  const handleLogoutConfirmClicks = () => {
    setIsModalOpen(false);
    handleLogoutClick();
  };

  const handleLogoutClick = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("tenantRole");
    sessionStorage.removeItem("selectedWorkSpace");
    sessionStorage.removeItem("tenantData");
    localStorage.clear();
    dispatch(updateSubMenuActiveId([]));
    navigate("/login");
  };

  const handleButtonClick = () => {
    handleWorkspaceChange(dataId);
    setSwitchModalOpen(false);
  };

  const onClose = () => {
    setShowProfile(false);
  };

  const userDetails = JSON.parse(sessionStorage.getItem("user") || "{}");
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user") || "{}");

    if (Object.keys(userData).length > 0) {
      const uniqueWorkspaces = Object.values(
        userData?.user?.tenants?.reduce((acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        }, {})
      );
      setWorkspaces(uniqueWorkspaces);
    }

    setRoleData(userData?.user?.roleMatrices);
  }, []);

  const useDetectClick = (ref: any, handlerFunc: () => void) => {
    useEffect(() => {
      const checkIfClickOutside = (e: any) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
          handlerFunc();
        }

        document.addEventListener("mousedown", checkIfClickOutside);

        return () => {
          document.removeEventListener("mousedown", checkIfClickOutside);
        };
      };
    }, [ref, handlerFunc]);
  };

  useDetectClick(ref, () => setShowProfile(false));

  const { Option } = Select;

  const handleWorkspaceChange = async (id: any) => {
    const workSpace = workspaces.filter((data: any) => data.id === id);

    setSelectedWorkspace(workSpace[0]);
    // window.location.reload()

    let tenantRole = JSON.parse(sessionStorage.getItem("tenantRole") || "{}");

    let switchWorkspace = false;

    if (tenantRole && tenantRole.roles) {
      switchWorkspace = true;
      await role.getRoleAndPermission("NA", switchWorkspace, workSpace[0].id);
    }

    let rolesData: any =
      roleData.find((x: { tenant: any }) => x.tenant == id) || [];

    rolesData.permission = removeDuplicatesFromArray(rolesData.permission);

    sessionStorage.setItem("tenantRole", JSON.stringify(rolesData));

    await role.getRoleAndPermission(rolesData.roles.join(','), false, workSpace[0].id);

    if (rolesData && rolesData.roles && rolesData.roles.length) {
      navigate("/");
    } else {
      ReNotification({
        description: "There is no role attached to this user",
        type: "error",
      });
    }
    sessionStorage.setItem("selectedWorkSpace", JSON.stringify(workSpace[0]));

    const selectedWorkspaceInSession =
      sessionStorage.getItem("selectedWorkSpace");

    if (selectedWorkspaceInSession) {
      const tenantData = JSON.parse(
        sessionStorage.getItem("selectedWorkSpace") || "{}"
      );
      const id: string = tenantData?.id; // Accessing the 'id' field using dot notation

      if (id) {
        try {
          const tenantSessionData = await tenant.getTenant(id);
          sessionStorage.setItem(
            "tenantData",
            JSON.stringify(tenantSessionData)
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
    dispatch(handleUserLogin({
      selectedTenantId: workSpace[0].id,
      selectedTenantName: workSpace[0].name,
      selectedTenantRoles: rolesData.roles,
      userPermissions: rolesData.permission
    }));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Styles.Container>
      <img src={Logo} alt="logo" />

      <div className="options">
        <Modal
          open={isModalOpen}
          onOk={handleLogoutConfirmClicks}
          okText="Yes"
          onCancel={() => {
            setIsModalOpen(false);
          }}
          centered
        >
          Are you sure you want to logout?
        </Modal>

        <Modal
          open={switchModalOpen}
          onOk={handleButtonClick}
          okText="Yes"
          onCancel={() => {
            setSwitchModalOpen(false);
          }}
          centered
        >
          Are you sure you want to switch the workspace?
        </Modal>

        <div>
          <div>
            <Select
              className="my-select"
              placeholder="Workspace"
              onChange={(workspaceId: any) => {
                showSwitchModal(workspaceId);
              }}
              defaultValue={selectedWorkspace.name}
              value={selectedWorkspace.name}
              style={{ width: "100%" }}
            >
              {workspaces.map((workspace: any) => (
                <Option key={workspace.id} value={workspace.id}>
                  {workspace.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Avatar
          icon={<UserOutlined />}
          onClick={() => {
            setShowProfile(true);
          }}
        />

        {showProfile ? (
          <div ref={ref}>
            <Card
              style={{
                position: "absolute",
                right: "0",
                marginRight: "1rem",
                zIndex: "99",
              }}
              actions={[
                <LogoutOutlined
                  label="logout"
                  onClick={() => setIsModalOpen(true)}
                />,
              ]}
            >
              <Meta
                avatar={<UserOutlined />}
                title={userDetails?.name}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <p>{userDetails?.user?.email}</p>
                <p>{userDetails?.user?.status === 1 ? "Active" : "Inactive"}</p>
              </div>
            </Card>
          </div>
        ) : (
          ""
        )}
      </div>
    </Styles.Container>
  );
}

export default Header;
