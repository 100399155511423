import Styled from 'styled-components';

export const Container = Styled.div``;
export const Body = Styled.div` 
    display: grid;
    gap: 1rem;
    .translatedTextHeader{
        display: flex;
        justify-content: space-between;
        h3{
            margin: 0;
        }
    }
`;