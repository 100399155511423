import styled from "styled-components";

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  .form-container{
    background-color: white;
    padding: 0.5rem;
    .form{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.5rem;
      justify-content: space-around;
      align-items: center;
    }
    .submit-btn{
      float: right;
    }
  }
`;