import { useState } from "react";
import { Drawer, Form } from "antd";
import { permission } from "../../../API/UserManagement/Permission";
import { useAppDispatch } from "../../../app/hooks";
import { getAllPermission } from "../../../slices/Permissions/Helpers/GetAllPermission";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import * as Styles from "./ManagePrivilegesStyles";

type TempPermissionsType = {
  name: string;
  description: string;
};

type propsType = {
  visibility: boolean;
  setVisibility: Function;
}

function PrivilegesManagement(props: propsType) {

  const [form] = Form.useForm();
  const { visibility, setVisibility } = props;
  const [tempPermissions, setTempPermissions] = useState<TempPermissionsType>({
    name: "",
    description: "",
  });
  const dispatch = useAppDispatch();

  const flushStateData = () => {
    setTempPermissions({
      name: "",
      description: "",
    });
  };

  const onClose = () => {
    setVisibility(false);
    flushStateData();
  };

  const addPermissions = async () => {
    await permission.create(tempPermissions);
    dispatch(getAllPermission());
    setVisibility(false);
  };

  return (
    <Styles.Container>
      <Drawer placement="right" onClose={onClose} open={visibility}>
        <ReusableForm
          formInstance={form}
          onSubmit={addPermissions}
          submitBtn
        >
          <ReInput
            label="Name"
            name="name"
            required
            type="simple"
          />
          <ReInput
            label="Description"
            name="description"
            required
            type="simple"
          />
        </ReusableForm>
      </Drawer>
    </Styles.Container>
  );
}

export default PrivilegesManagement;