import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createCategorization } from "./Helpers/createCategorization";
import { getAllCategorization } from "./Helpers/getAllCategorization";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";


type CategorizationApiType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: number;
  name: string;
  numid: number
};
interface CategorizationState {
  valueToTitleMapper: any,
  titleToValueMapper: any,
  allCategory: CategorizationApiType[];
  loading: boolean;
  error: string;
  categorizationLoading: boolean,
  createCategorizationLoading: boolean
}

const initialState: CategorizationState = {
  valueToTitleMapper: {},
  titleToValueMapper: {},
  allCategory: [],
  loading: true,
  error: "",
  categorizationLoading: true,
  createCategorizationLoading: true
};

const CategorizationSlice = createSlice({
  name: "categorization",
  initialState,
  reducers: {
    addValueToTitleMapper(state, action: PayloadAction<any>) {
      state.valueToTitleMapper = action.payload
    },
    addTitleToValueMapper(state, action: PayloadAction<any>) {
      state.titleToValueMapper = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllCategorization.pending, (state) => {
      state.categorizationLoading = true;
    });
    builder.addCase(getAllCategorization.fulfilled, (state, action) => {
      state.allCategory = action.payload;
      state.categorizationLoading = false;
    });
    builder.addCase(getAllCategorization.rejected, (state) => {
      state.error = "Something went wrong";
      state.categorizationLoading = false;
    });
    builder.addCase(createCategorization.pending, (state) => {
      state.loading = true;
      state.categorizationLoading = true;
    });
    builder.addCase(createCategorization.fulfilled, (state, action) => {
      state.allCategory = [...state.allCategory, action.payload];
      ReNotification({
        description: "Categorization Created Successfully",
        type: "success",
      });
      state.categorizationLoading = false;
    });
    builder.addCase(createCategorization.rejected, (state) => {
      state.error = "Something went wrong";
      state.categorizationLoading = false;
    });
  },
});

export const { addValueToTitleMapper, addTitleToValueMapper } = CategorizationSlice.actions
export default CategorizationSlice.reducer;
