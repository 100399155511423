import { useState } from "react";
import { Header, SideMenu } from "../../Components";
import AllAuditLog from "../../Components/AuditLogs/AllAuditLog";
import QualityCheckLogs from "../../Components/AuditLogs/QualityCheckLogs";
import ReTab from "../../unity-ui-common/Components/ReTab";
import * as Styles from "./AuditLogsStyles";

function AuditLogs() {

  const [activeTabValue, setActiveTabValue] = useState<string>("1");
  
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ReTab
            activeKey={activeTabValue}
            defaultOpenTab={activeTabValue}
            onChange={(id: string) => setActiveTabValue(id)}
            items={[
              {
                title: "Audit Logs",
                key: '1',
                children: <AllAuditLog />
              },
              {
                title: "Quality Check Logs",
                key: '2',
                children: <QualityCheckLogs />
              }
            ]}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default AuditLogs;
