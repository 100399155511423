import { Form } from "antd";
import { Header, SideMenu } from "../../../Components";
import * as Styles from "./CustomizedReportStyles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GetAllProducts } from "../../../slices/Product/Helpers/GetAllProducts";
import { useState, useEffect } from "react";
import { importEngine } from "../../../API/ImportEngine";
import moment from "moment";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReSelect from "../../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function CustomizedReport() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { allProduct } = useAppSelector((store) => store.product);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values: any) => {
    setSubmitting(true);
    const payload = {
      productName: [values.productName],
      startDate: moment(values.dateRange[0]._d).format("DD-MM-YYYY"),
      endDate: moment(values.dateRange[1]._d).format("DD-MM-YYYY"),
    };
    const res = await importEngine.generateAssesmentReport(
      payload,
      "Customized Report"
    );
    if (res.statusCode === 200) {
      ReNotification({
        description: "Your Report is Generating",
        type: "success",
      });
    } else {
      ReNotification({
        description: "Something Went Wrong",
        type: "error",
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    dispatch(GetAllProducts());
  }, []);

  return (
    <Styles.Container className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <div className="form-wrapper">
            <ReusableForm
              formInstance={form}
              onSubmit={onFinish}
              submitBtnText="Generate"
              submitBtn
              resetBtn
            >
              <div className="filter">
                <ReSelect
                  label="Product Name"
                  name="productName"
                  required
                  items={allProduct.map((pro) => {
                    return {
                      title: pro.name,
                      value: pro.name,
                    };
                  })}
                  searchable
                />
                <ReDatePicker
                  label="Day 0"
                  name="dateRange"
                  type="rangePicker"
                  dateFormat="DD-MMM-YYYY"
                  featureDates
                  width="100%"
                />
              </div>
            </ReusableForm>
          </div>
        </Styles.SubContainer>
      </div>
    </Styles.Container>
  );
}

export default CustomizedReport;
