import styled from "styled-components";

export const Container = styled.div``;
export const SubContainer = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
`;
export const Left = styled.div`
  min-width: 500px;
  .smallCards {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
`;
export const Right = styled.div`
  width: 100%;
  .contact-us-form{
    width: 100%;
    .upload-btn{
      max-width: 300px;
    }
  }
`;
