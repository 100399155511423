import * as Styles from "./SideMenuStyles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  updateActiveId,
  updateSubMenuActiveId,
  setCollapse,
} from "../../slices/SideMenu/SideMenuSlices";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { Link, useLocation } from "react-router-dom";
import { items } from "../../Configs/SideMenuItems";
import { useEffect, useState } from "react";
import PPAndTCModal from "../LoginForm/PPAndTCModal";

function SideMenu() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { activeId, subMenuActiveIds, collapse } = useAppSelector(
    (store) => store.sideMenu
  );
  const { userPermissions } = useAppSelector((store) => store.user);
  const allData = useAppSelector((store) => store.session);
  const [TCAndPPModalProps, setTCAndPPModalProps] = useState<any>({
    visibility: false,
    type: "PP",
  });

  useEffect(() => {
    const pathname = location.pathname;
    let activeMenuItem, activeSubMenuItem;
    items.forEach((menuItem) => {
      if (menuItem?.url === pathname) {
        activeMenuItem = menuItem?.key;
      } else if (menuItem?.subItems) {
        menuItem?.subItems.forEach((subMenuItem) => {
          if (subMenuItem?.url === pathname) {
            activeMenuItem = subMenuItem.key;
            activeSubMenuItem = [menuItem?.key];
          }
        });
      }
    });
    activeMenuItem && dispatch(updateActiveId(activeMenuItem));
    activeSubMenuItem && dispatch(updateSubMenuActiveId(activeSubMenuItem));
  }, [location.pathname]);

  const handleTCAndPPClicks = (type: "TC" | "PP") => {
    setTCAndPPModalProps((pre: any) => {
      return {
        ...pre,
        type: type,
        visibility: true,
      };
    });
  };

  function handlemenuActiveChange(item: any) {
    dispatch(updateActiveId(item.key));
  }

  function handleSubMenuActiveChange(item: any) {
    dispatch(updateSubMenuActiveId(item));
  }

  const toggleCollapsed = () => {
    dispatch(setCollapse(!collapse));
  };

  return (
    <Styles.Container>
      {collapse ? (
        <RightCircleOutlined className="toggleBtn" onClick={toggleCollapsed} />
      ) : (
        <LeftCircleOutlined className="toggleBtn" onClick={toggleCollapsed} />
      )}
      <Menu
        className="menu"
        style={{ transitionDelay: "-0.9s" }}
        selectedKeys={[activeId]}
        mode="inline"
        onClick={(item) => handlemenuActiveChange(item)}
        openKeys={subMenuActiveIds}
        onOpenChange={(openKeys: string[]) =>
          handleSubMenuActiveChange(openKeys)
        }
        inlineCollapsed={collapse}
      >
        {collapse ? (
          <RightCircleOutlined
            className="toggleBtn"
            onClick={toggleCollapsed}
          />
        ) : (
          <LeftCircleOutlined className="toggleBtn" onClick={toggleCollapsed} />
        )}
        {items.map((item: any) => {
          if (item.subItems) {
            const filteredSubItems = item.subItems.filter((subItem: any) => {
              if (subItem.priviliges && subItem.priviliges.length > 0) {
                return subItem.priviliges.some((privilege: any) =>
                  allData.userPermissions.includes(privilege)
                );
              }
              return true;
            });

            if (filteredSubItems.length > 0) {
              return (
                <SubMenu key={item.key} icon={item.Icon} title={item.title}>
                  {filteredSubItems.map((subItem: any) => {
                    if (
                      !subItem.priviliges ||
                      subItem.priviliges.some((privilege: any) =>
                        allData.userPermissions.includes(privilege)
                      )
                    ) {
                      return (
                        <Menu.Item key={subItem.key}>
                          <Link to={subItem.url || ""}>{subItem.title}</Link>
                        </Menu.Item>
                      );
                    }
                    return null;
                  })}
                </SubMenu>
              );
            }
          } else {
            if (
              !item.priviliges ||
              item.priviliges.some((privilege: any) =>
                allData.userPermissions.includes(privilege)
              )
            ) {
              return (
                <Menu.Item key={item.key} icon={item.Icon}>
                  <Link to={item.url || ""}>{item.title}</Link>
                </Menu.Item>
              );
            }
          }
          return null;
        })}
      </Menu>
      {!collapse && (
        <Styles.MenuFooter>
          <h6>Product by Soterius</h6>
          <h6>Version 3.1.0</h6>
          <Styles.FooterLinks>
            <Link
              to=""
              onClick={() => {
                handleTCAndPPClicks("TC");
              }}
            >
              <h6>Terms & Conditions</h6>
            </Link>
            <span style={{ margin: "0 5px" }}>|</span>
            <Link
              to=""
              onClick={() => {
                handleTCAndPPClicks("PP");
              }}
            >
              <h6>Privacy Policy</h6>
            </Link>
          </Styles.FooterLinks>
        </Styles.MenuFooter>
      )}
      <PPAndTCModal
        visibility={TCAndPPModalProps.visibility}
        setVisibility={setTCAndPPModalProps}
        type={TCAndPPModalProps.type}
      />
    </Styles.Container>
  );
}

export default SideMenu;
