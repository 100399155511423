import { useEffect, useState } from "react";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../common";
import { API_URL } from "../../../API/Config";
import * as Styles from "./CountriesStyles";

function CountriesTable() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);

  const columns = [
    {
      title: "Country",
      dataIndex: "name",
    },
    {
      title: "Code",
      dataIndex: "alpha2",
    },
  ];

  return (
    <Styles.SubContainer>
      <ReTable
        name="country"
        title="Country"
        columns={columns}
        uniView={{
          uniView: "controlled-vocabularies",
          viewName: "country",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10
          },
        }}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "alpha2",
        }}
        exportOption={{
          csv: {},
        }}
      />
    </Styles.SubContainer>
  );
}

export default CountriesTable;
