import styled from "styled-components";

export const SubContainer = styled.div`
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-img {
    width: 12rem;
    object-fit: contain;
  }
`;
export const WorkSpaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

export const SelectContainer = styled.div`
  background-color: white;
  width: 400px;
  padding: 24px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  .select-heading{
    text-align: center;
  }
`;
