import * as Styles from "./ReportFormStyles";
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/antd";
import FormUiSchema from "./FormUiSchema";
import FormSchema from "./FromSchemaAutoGenerated.json";
import { useState } from "react";
import {
  ObjectFieldTemplate,
  ArrayFieldTemplate,
} from "./CustomTemplates/CustomTemplates";

function ReportForm() {
  const [formData, setFormData] = useState(null);
  const handleSubmit = (e) => {
    setFormData(e.formData);
  };
  return (
    <Styles.Container>
      <Form
        className="jsonForm"
        schema={FormSchema}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        validator={validator}
        uiSchema={FormUiSchema}
        showErrorList={false}
        formData={formData}
        templates={{
          ArrayFieldTemplate,
          ObjectFieldTemplate,
        }}
      />
    </Styles.Container>
  );
}

export default ReportForm;
