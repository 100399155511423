import { Header, SideMenu } from "../../../Components";
import RouteOfAdministrationTable from "../../../Components/ControlledVocabularies/RouteOfAdministration/RouteAdministrationTable";

import * as Styles from "./RoutesOfAdministrationStyles";

function RouteOfAdministration() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <RouteOfAdministrationTable/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default RouteOfAdministration;
