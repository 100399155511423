import { BaseAPI } from "../../Common";
import { ICreateActiveIngredient, IUpdateActiveIngredient } from "./ActiveIngredientInterface";

class ActiveIngredient extends BaseAPI {
  create(payload: ICreateActiveIngredient): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(ingredientId: string, payload: IUpdateActiveIngredient): Promise<any> {
    return this.makeRequest(`update/${ingredientId}`, "patch", payload);
  }

  getAll(): Promise<any> {
    return this.makeRequest(`getMany`);
  }

}

export const ingredient = new ActiveIngredient("active-ingredient");
