import Styled from "styled-components";

export const Container = Styled.div``;
export const SubContainer = Styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-inline: 15px;
  }
  .form{
    background-color:#ffffff;
    padding: 1rem;
  }
  .textAreasFields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  .selectColumns {
    margin: 0 0 2rem 0;
    .checkBoxes{
      display: grid;
      width: fit-content;
    }
  }
`;