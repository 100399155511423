import * as Styles from "./PPAndTCModalStyles";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { PrivacyPolicyTemplate, TermsAndConditionsTemplate } from "./Constant";
import { useEffect, useRef } from "react";

function PPAndTCModal(props: any) {
  const { visibility, setVisibility, type } = props;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const attr = document.getElementsByClassName('ant-modal-body');
    if(attr.length){
      attr[0].scrollTop  = 0;
    }
  }, [visibility]);

  return (
    <ReModal
      visibility={visibility}
      width={1200}
      title={`${type === "TC" ? "Terms & Conditions" : "Privacy Policy"}`}
      onCancel={() => {
        setVisibility((pre: any) => {
          return {
            ...pre,
            visibility: false,
          };
        });
      }}
      footer={false}
    >
      <Styles.Body>
        <div className="" ref={divRef}>
          {type === "PP" ? PrivacyPolicyTemplate : TermsAndConditionsTemplate}
        </div>
      </Styles.Body>
    </ReModal>
  );
}

export default PPAndTCModal;