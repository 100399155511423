import { createAsyncThunk } from "@reduxjs/toolkit";
import { importEngine } from "../../../API/ImportEngine";
import { ICreateWorkflowOne } from "../../../API/ImportInterface";

const workFLowOneApi = createAsyncThunk(
  "cases/workFLowOneApi",
  async ({
    caseData,
    caseId,
  }: {
    caseData: ICreateWorkflowOne;
    caseId: number;
  }) => {

    try {
      const data = await importEngine.createWorkFlowOne(caseData, caseId);

      return data;
    } catch (err) {
      console.log("error");
    }
  }
);

export { workFLowOneApi };
