import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";

const getAllIme = createAsyncThunk(
  "controlledVocabularies/getAllIme",
  async () => {
    try {
      const data = await controlledVocabularies.getManyIME()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllIme };
