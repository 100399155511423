import styled from 'styled-components';

export const SubContainer = styled.div`
  width: 100%;
`;

export const ModalContainer = styled.div`
.xmlErrorTable {  
        .ant-table-title,
        .ant-pagination {
            display: none;
        }

    }
`;

