import { Form, Drawer, Switch } from "antd";
import { useEffect, useState } from "react";
import { labelAssessment } from "../../../API/Masters/LabelAssessment/LabelAssessment";
import { useAppDispatch } from "../../../app/hooks";
import { createLabelAssessment } from "../../../slices/LabelAssessment/Helpers/createLabelAssessment";
import { getAllLabelAssessment } from "../../../slices/LabelAssessment/Helpers/getAllLabelAssessment";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";

function LabelAssesmentDrawer(props: {
  drawerType: string;
  visibility: boolean;
  setVisibility: Function;
  currentEditingLabel?: any;
}) {
  const [form] = Form.useForm();
  const { drawerType, visibility, setVisibility, currentEditingLabel } = props;
  const dispatch = useAppDispatch();
  const [switchStatus, setSwitchStatus] = useState(1);

  const onClose = () => {
    setVisibility({
      visibility: false,
      drawerType: "create",
    });
    setSwitchStatus(1);
    form.resetFields();
  };

  const submitFormHandler = (values: any) => {
    const payload = {
      name: values.name,
      status: switchStatus
    };
    if (drawerType === "create") {
      dispatch(createLabelAssessment(payload));
    } else if (drawerType === "edit") {
      labelAssessment.update(currentEditingLabel.id, payload);
    }
    ReNotification({
      description: `Label Assessment ${drawerType === "create" ? "Updated" : "Created"} Successfully.`,
      type: "success",
    });
    dispatch(getAllLabelAssessment());
  };

  useEffect(() => {
    if (drawerType === "edit") {
      form.setFieldsValue({
        name: currentEditingLabel.name,
      })
      setSwitchStatus(currentEditingLabel.status);
    }
  }, [drawerType]);

  return (
    <div>
      <Drawer 
        placement="right" 
        onClose={onClose} 
        open={visibility} 
        title={`${drawerType === "edit" ? "Update" : "Create"} Label Assessment`}>
        <ReusableForm
          formInstance={form}
          onSubmit={submitFormHandler}
          submitBtn
        >
          <ReInput
            label="Name"
            name="name"
            required
            type="simple"
          />
          <Form.Item label="Status" rules={[{ required: true }]}>
            <Switch
              checked={switchStatus === 1}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => {
                setSwitchStatus(checked ? 1 : 0);
              }}
            />
          </Form.Item>
        </ReusableForm>
      </Drawer>
    </div>
  );
}

export default LabelAssesmentDrawer;