import QcCofigurationDrawer from "./QcCofigurationDrawer/QcCofigurationDrawer";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import {
  EditOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Space, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { SponserCreationForm } from "../../Drawers/SponserCreationForm";
import { API_URL } from "../../../API/Config";
import { fetchCVTableData } from "../../ControlledVocabularies/common";
import * as Styles from "./ManageSponsorsStyles";

function ManageSponsors() {

  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [currEditingSponor, setCurrEditingSponor] = useState({});
  const [sponsorDrawer, setSponsorDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [qcDrawerVisibility, setQcDrawerVisibility] = useState<boolean>(false);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (sponsorDrawer.visibility === false) {
      setFlag(prevValue=>!prevValue);
    }
  }, [sponsorDrawer]);

  const columns = [
    {
      title: "",
      width: 60,
      render: (record: any) => {
        return (
          <>
            {selectedTableRow.length !== 0 ? (
              ""
            ) : (
              <Space size="middle">
                <Tooltip title="Edit">
                  <EditOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCurrEditingSponor(record);
                      setSponsorDrawer({
                        visibility: true,
                        type: "edit",
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title="configuration">
                  <SettingOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setQcDrawerVisibility(true);
                      setCurrEditingSponor(record);
                    }}
                  />
                </Tooltip>
              </Space>
            )}
          </>
        );
      },
    },
    {
      title: "Sponsor/MAH Name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: "Sponsor/MAH Unit",
      width: 200,
      dataIndex: "abbreviation",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      width: 250,
    },
    {
      title: "Country",
      width: 200,
      dataIndex: "country",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];

  return (
    <Styles.Container>
      <SponserCreationForm
        drawerType={sponsorDrawer.type}
        visibility={sponsorDrawer.visibility}
        setVisibility={setSponsorDrawer}
        currEditingSponor={currEditingSponor}
        setCurrEditingSponor={setCurrEditingSponor}
      />
      <QcCofigurationDrawer
        visibility={qcDrawerVisibility}
        setVisibility={setQcDrawerVisibility}
        currEditingSponor={currEditingSponor}
        setCurrEditingSponor={setCurrEditingSponor}
      />
      <ReTable
        name="allSponsorsMAHs"
        title="All Sponsors/MAHs"
        columns={columns}
        refresh={flag}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "id",
        }}
        uniView={{
          uniView: "tenant",
          viewName: "all",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
        headerButtons={[
          {
            title: "Add New",
            icon: <UserAddOutlined />,
            onClick: () => {
              setSponsorDrawer({
                visibility: true,
                type: "create"
              })
            },
          },
        ]}
      />
    </Styles.Container>
  );
}

export default ManageSponsors;
