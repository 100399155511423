export const ageTitlesItems = [
    "years",
    "months",
    "days",
    "weeks",
    "hours",
    "minute"
];

export const ageGroupItems = [
    {
        value: 0,
        title: "Foetus",
    },
    {
        value: 1,
        title: "Neonate",
    },
    {
        value: 2,
        title: "Infant",
    },
    {
        value: 3,
        title: "Child",
    },
    {
        value: 4,
        title: "Adolescent",
    },
    {
        value: 5,
        title: "Adult",
    },
    {
        value: 6,
        title: "Elderly",
    },
];

export const subCategorization = [
    "Lack of Efficacy",
    "Overdose",
    "Misuse",
    "Abuse",
    "Special Populations",
    "Off Label Use",
    "Medication Error",
    "New Benefit Risk Assessment",
    "Other",
];

export const IMEAndDMEOptions = [
    {
        label: "Yes",
        value: true
    },
    {
        label: "No",
        value: false
    }
]