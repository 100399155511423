import { useEffect, useState } from "react";
import * as Styles from "./ImeTableStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../common";
import { API_URL } from "../../../API/Config";

function ImeTable() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);

  const columns = [
    {
      title: "MedDra Code",
      dataIndex: "code",
    },
    {
      title: "PT Name",
      dataIndex: "PTName",
    },
    {
      title: "SOC Name",
      dataIndex: "SOCName",
    },
  ];

  return (
    <Styles.SubContainer>
      <ReTable
        name="ime"
        title="IME"
        columns={columns}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "code",
        }}
        uniView={{
          uniView: "controlled-vocabularies",
          viewName: "importantMedicalEvent",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "PTName",
              order: "ASC",
            },
            limit: 10
          },
        }}
        exportOption={{
          csv: {},
        }}
      />
    </Styles.SubContainer>
  );
}

export default ImeTable;
