import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as Styles from "./TranslationComparisonStyles";
import { Button, Drawer, Input } from "antd";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";
import { importEngine } from "../../../API/ImportEngine";
import { addCurrentAccessingCase } from "../../../slices/Record/RecordSlices";

function TranslationComparison(props: {
  visibility: boolean;
  setVisibility: Function;
  type: string;
}) {
  const { visibility, setVisibility, type } = props;
  const { currentAccessingCase } = useAppSelector((store) => store.cases);
  const { isTranslated } = currentAccessingCase;
  const dispatch = useAppDispatch();

  const [textEdit, setTextEdit] = useState(false);
  const [textForTranslation, setTextForTranslation] = useState<string>("");
  const [orignalText, setOrignalText] = useState<string>("");
  const [translatedText, setTranslatedText] = useState<string>("");

  const handleSaveClick = async () => {
    setTextEdit(false);
    const payload: any = {};
    if (type === "title") {
      payload["articleTitle"] = textForTranslation;
    } else {
      payload["abstract"] = textForTranslation;
    }

    const res = await importEngine.UpdateCase(currentAccessingCase.id, payload);
    if (res?.status) {
      setTranslatedText(textForTranslation);

      // updating global state value
      dispatch(
        addCurrentAccessingCase({
          ...currentAccessingCase,
          ...payload,
        })
      );
      ReNotification({
        description: "Sucessfully Translated",
        type: "success",
      });
    } else {
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
    }
  };

  useEffect(() => {
    const { sourceArticleTitle, articleTitle, sourceAbstract, abstract } =
      currentAccessingCase;
    if (type === "title") {
      setOrignalText(sourceArticleTitle);
      setTranslatedText(articleTitle);
      setTextForTranslation(articleTitle);
    } else if (type === "abstract") {
      setOrignalText(sourceAbstract);
      setTranslatedText(abstract);
      setTextForTranslation(abstract);
    }
  }, [visibility]);

  return (
    <Styles.Container>
      <Drawer
        className="drawer"
        title="Translation Comparison"
        width={800}
        placement="right"
        onClose={() => {
          setVisibility({
            visibility: false,
            type: "",
          });
        }}
        open={visibility}
      >
        <Styles.Body
          style={{ gridTemplateColumns: isTranslated ? "1fr 1fr" : "" }}
        >
          <div>
            <h3>Original</h3>
            <p>{orignalText}</p>
          </div>
          {isTranslated && (
            <div>
              <div
                className="translatedTextHeader"
                style={{ marginBottom: textEdit ? "0.5rem" : "" }}
              >
                <h3>Translated</h3>
                <div>
                  {textEdit && (
                    <Button
                      type="link"
                      onClick={() => {
                        setTextEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {isTranslated && (
                    <Button
                      type="link"
                      onClick={() => {
                        setTextEdit(true);
                        textEdit ? handleSaveClick() : setTextEdit(true);
                      }}
                    >
                      {textEdit ? "Save & Translate" : "Edit"}
                    </Button>
                  )}
                </div>
              </div>
              {isTranslated && textEdit ? (
                <Input.TextArea
                  value={textForTranslation}
                  placeholder="Text for Translation"
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  allowClear
                  onChange={(e) => {
                    setTextForTranslation(e.target.value);
                  }}
                />
              ) : (
                <p>{translatedText}</p>
              )}
            </div>
          )}
        </Styles.Body>
      </Drawer>
    </Styles.Container>
  );
}

export default TranslationComparison;
