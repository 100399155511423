import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";

const getAllSeriousness = createAsyncThunk(
  "controlledVocabularies/getAllSeriousness",
  async () => {
    try {
      const data = await controlledVocabularies.getManySeriousness()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllSeriousness };
