import { useAppSelector } from "../app/hooks";

export const GetRoleName = (id: string, roleData: any) => {
  return roleData.find((data: any) => data?.id === id)?.name;
};

export const GetRoleId = (name: string, roleData: any) => {
  return roleData?.find((data: any) => data?.name === name)?.id;
};

export const GetRoleNameForColumn = (id: string) => {
  const { allRoles } = useAppSelector((store) => store.roles);
  return allRoles.find((data: any) => data?.id === id)?.name;
};
