import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Header, SideMenu } from "../../../Components";
import LabelAssesmentDrawer from "../../../Components/Master/LabelAssessmentDrawer/LabelAssessmentDrawer";
import * as Styles from "./LabelAssessmentStyles";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { fetchCVTableData } from "../../../Components/ControlledVocabularies/common";
import { API_URL } from "../../../API/Config";

function LabelAssessment() {
  const [labelAssesmentDrawer, setLabelAssesmentDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [currentEditingLabel, setCurrentEditingLabel] = useState({});
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(0);

  const labelAssessmentColumn = [
    {
      title: "",
      width: 40,
      render: (record: any) => {
        return (
          <Tooltip title="Edit Label">
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentEditingLabel(record);
                setLabelAssesmentDrawer({
                  visibility: true,
                  type: "edit",
                });
              }}
            />
          </Tooltip>
        );
      },
    },

    {
      title: "Label Assessment",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];

  useEffect(() => {
    fetchCVTableData({
      setLoading: setTableLoading,
      uniView: "masterData",
      viewName: "labelAssessment",
      setTableData,
      setPaginationPageSize,
      shortByKey: "name",
    });
  }, []);

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <LabelAssesmentDrawer
            drawerType={labelAssesmentDrawer.type}
            visibility={labelAssesmentDrawer.visibility}
            setVisibility={setLabelAssesmentDrawer}
            currentEditingLabel={currentEditingLabel}
          />
          <ReTable
            name="labelAssessment"
            title="Label Assessment"
            columns={labelAssessmentColumn}
            data={tableData}
            loading={tableLoading}
            scroll={{
              x: 800,
              y: 500,
            }}
            rowSelection={{
              selectedRowKeys: selectedTableRow,
              type: "checkbox",
              setSelectedRowKeys: setSelectedTableRow,
              rowKey: "id",
            }}
            columnOptions={{
              sorting: {
                columns: ["name"],
              },
              filter: {
                columns: ["name"],
              },
            }}
            headerButtons={[
              {
                title: "Add New",
                type: "primary",
                icon: <UserAddOutlined />,
                onClick: () => {
                  setLabelAssesmentDrawer({
                    visibility: true,
                    type: "create",
                  });
                },
              },
            ]}
            pagination={{
              total: paginationPageSize,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            uniView={{
              uniView: "masterData",
              viewName: "labelAssessment",
              apiUrl: API_URL,
              option: {
                orderBy: {
                  key: "name",
                  order: "DESC",
                },
                limit: 10
              },
            }}
            exportOption={{
              csv: {},
            }}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default LabelAssessment;
