import { auth } from './Auth';

class RefreshToken {
    // static refreshTokenTime: number = 2000;
    static refreshTokenTime: number = 60 * 60 * 1000;       // Interval Timer to repeat request (15 min)
    static refreshTokenTimerHandler: number = 0;

    static startInterval() {
        if (this.refreshTokenTimerHandler !== 0) {
            return;
        }
        this.refreshTokenTimerHandler = window.setInterval(
            auth.refreshToken,
            this.refreshTokenTime
        );
    }

    static clearTimer() {
        clearInterval(this.refreshTokenTimerHandler);

        this.refreshTokenTimerHandler = 0;
    }
};

export default RefreshToken;