import { createAsyncThunk } from "@reduxjs/toolkit";
import { labelAssessment } from "../../../API/Masters/LabelAssessment/LabelAssessment";

const getAllLabelAssessment = createAsyncThunk(
  "ingredient/getAllLabelAssessment",
  async () => {
    try {
      const data = await labelAssessment.getAll()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllLabelAssessment };
