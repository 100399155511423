import { createAsyncThunk } from "@reduxjs/toolkit";
import {ingredient} from "../../../API/Masters/ActiveIngredient/ActiveIngredient"

const getAllIngredient = createAsyncThunk(
  "ingredient/GetAllIngredient",
  async () => {
    try {
      const data = await ingredient.getAll()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllIngredient };
