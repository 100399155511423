import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  color: gray;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 1px 4px -1px grey; */
`;
