import { Header, SideMenu } from "../../../Components";
import DMETable from "../../../Components/ControlledVocabularies/DmeTable/DmeTable";

import * as Styles from "./DmeStyles";

function DME() {
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <DMETable/>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default DME;
