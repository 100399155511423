import { createAsyncThunk } from "@reduxjs/toolkit";
import { labelAssessment } from "../../../API/Masters/LabelAssessment/LabelAssessment";

const createLabelAssessment = createAsyncThunk(
  "product/createLabelAssessment",
  async (labelData:any) => {
    try {
      const data = await labelAssessment.create(labelData)
      return data;
      
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { createLabelAssessment };
