import { Routes, Route, useLocation } from "react-router-dom";
import RoutesData, { RoutesDataType } from "./Configs/RoutesData";
import { useEffect, useState } from "react";
import { auth } from "./API/Auth";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { addUserPermissions } from "./slices/User/UserSlices";
import { initializeState, loadState } from "./sessionstorage";
import {
  addTitleToValueMapper,
  addValueToTitleMapper,
} from "./slices/Categorization/CategoizationSlices";
import { categorization } from "./API/Masters/Categorization/Categorization";
import { getRandomDarkColor } from "./Healper/Methods";
import { saveState } from "./localstorage";
import { store } from "./app/store";
import TimerManager from "./TimerManager";
import { ExpiryModal } from "./Components";

function App() {
  const dispatch = useAppDispatch();
  const allData = useAppSelector((store) => store.session);
  const location = useLocation();
  const currentPath = location.pathname;

  const [routes, setRoutes] = useState<RoutesDataType[]>([]);
  const [timerInstance, setTimerInstance] = useState<TimerManager | null>(null);
  const [showLogoutWarningModal, setShowLogoutWarningModal] = useState<boolean>(false);

  const onHandleShowModal = () => {
    setShowLogoutWarningModal(true);
  };

  store.subscribe(() => {
    saveState({
      session: store.getState().session,
    });
  });

  const getFilteredRoutes = (permissions: any) => {
    const routes = RoutesData.filter((route: any) => {
      if (route.hasOwnProperty("privilages")) {
        return route.privilages.some((privilege: any) =>
          permissions?.includes(privilege)
        );
      } else {
        return route;
      }
    });
    return routes;
  };

  const onHandleLogoutClick = async () => {
    await auth.logout();
    window.location.href = "/";
  };

  const onHandleLogout = async () => {
    await auth.logout();
    sessionStorage.setItem("unauth", "true");
    window.location.href = "/login";
  };

  useEffect(() => {
    const unsubscribe = !loadState()?.session
      ? store.subscribe(() => {
        initializeState({
          session: store.getState().session,
        });
      })
      : () => { };
    setTimerInstance(
      TimerManager.getInstance(onHandleShowModal, onHandleLogout)
    );
    return () => {
      unsubscribe();
      timerInstance?.clearTimer();
    };
  }, []);

  useEffect(() => {
    const timerInstance = TimerManager.getInstance(onHandleShowModal, onHandleLogout);

    if (["/login", "/workspace"].some((path) => currentPath.includes(path))) {
      timerInstance?.clearTimer();
    } else {
      timerInstance?.resetTimer();
    }
  }, [currentPath])

  const createCategorizationMapper = async () => {
    const res = await categorization.getAll();
    if (res.status) {
      let enumToTitleMapper: any = {};
      let titleToEnumMapper: any = {};
      let allColors: string[] = [];
      res.data.forEach((item: any) => {
        const color = getRandomDarkColor(allColors);
        allColors.push(color)
        enumToTitleMapper[item.numid] = {
          text: item.name,
          color: color,
        };
        titleToEnumMapper[item.name] = item.numid;
      });
      dispatch(addValueToTitleMapper(enumToTitleMapper));
      dispatch(addTitleToValueMapper(titleToEnumMapper));
    }
  };

  useEffect(() => {
    setRoutes(getFilteredRoutes(allData.userPermissions));
  }, [allData]);

  // fetch user privilages from session storage everytime when app reload
  useEffect(() => {
    const privileges = auth.getUsersPrivileges();
    dispatch(addUserPermissions(privileges));
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated()) {
      createCategorizationMapper();
    }
  }, []);

  return (
    <>
      <ExpiryModal
        setShowLogoutWarningModal={setShowLogoutWarningModal}
        onHandleLogout={onHandleLogoutClick}
        showLogoutWarningModal={showLogoutWarningModal}
        timerInstance={timerInstance}
      />
      <Routes>
        {routes.map((ele, idx) => {
          const { element, path } = ele;
          return <Route element={element} path={path} />;
        })}
      </Routes>
    </>
  );
}

export default App;
