import { createSlice } from "@reduxjs/toolkit";
import { createActiveIngredient } from "./Helpers/createActiveIngredient";
import { getAllIngredient } from "./Helpers/getAllIngredients";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

type IngredientType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: number;
  uniqueID: string;
  name: string;
  ATCCode: string;
};
interface ActiveIngredientState {
  allIngredient: IngredientType[];
  error: string;
  ingredientLoading: boolean,
  createloading: boolean
}

const initialState: ActiveIngredientState = {
  allIngredient: [],
  ingredientLoading: true,
  error: "",
  createloading: true
};

const ActiveIngredientSlice = createSlice({
  name: "ingredient",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllIngredient.pending, (state) => {
      state.ingredientLoading = true;
    });
    builder.addCase(getAllIngredient.fulfilled, (state, action) => {
      state.allIngredient = action.payload;
      state.ingredientLoading = false;
    });
    builder.addCase(getAllIngredient.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.ingredientLoading = false;
    });
    builder.addCase(createActiveIngredient.pending, (state) => {
      state.createloading = true;
    });
    builder.addCase(createActiveIngredient.fulfilled, (state, action) => {
      state.allIngredient = [...state.allIngredient, action.payload]
      ReNotification({
        description: "Active-Ingredient Created Successfully",
        type: "success",
      });
      state.createloading = false;
    });
    builder.addCase(createActiveIngredient.rejected, (state, action) => {
      state.error = "Something went wrong";
      ReNotification({
        description: "Something went wrong",
        type: "error",
      });
      state.createloading = false;
    });
  },
});

export default ActiveIngredientSlice.reducer;
