import { Uniview } from "../../API/Uniview";

export const workFlowTypes = [
    {
        title: "Workflow 1 And Not Assigned",
        value: "workflowOne"
    },
    {
        title: "Workflow 2",
        value: "workflowTwo"
    },
    {
        title: "Quality Check",
        value: "qc"
    },
]

export const getOwnerFIlterItems = async () => {
    let option: any = {
        orderBy: {},
        selectFields: "userName",
        groupBy: "userName",
        filters: true,
    };

    const dataFetcher = new Uniview("import-engine", "getAll");
    const res = await dataFetcher.fetchData({}, option);
    if (res.status) {
        return res.data.map((fil: any) => {
            return {
                value: fil["userName"],
                text: fil["userName"] === null ? "Not Assigned" : fil["userName"],
            };
        })
    }
};