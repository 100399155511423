import ReDrawer from '../../unity-ui-common/Components/ReDrawer'
import moment from 'moment'
import { Badge, Button, Card, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { StageMappingByValue, qcBatchStatus, qcBatchStatusAvatar, qcBatchStatusIcons } from '../../Healper/Mappings'
import { auditlogs } from "../../API/AuditLog";
import { CalendarFilled, CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, NumberOutlined, DatabaseFilled, CloseSquareFilled } from '@ant-design/icons'
import { useAppSelector } from '../../app/hooks'
import { Link } from 'react-router-dom'
import { formatNumberDecimal, sortRecordsBasedOnIds } from '../../Healper/Methods'
import { UniviewService } from '../../unity-ui-common/Classes/Uniview'
import { API_URL } from '../../API/Config'
import { ReNotification } from '../../unity-ui-common/Components/ReNotification'
import * as Styles from "./BatchDetailDrawerStyles";

interface IBatchDetailDrawerProps {
    qcDrawerData: any,
    visibility: boolean,
    setVisibility: (arg1: boolean) => void
}

function BatchDetailDrawer({ qcDrawerData, visibility, setVisibility }: IBatchDetailDrawerProps) {

    const { valueToTitleMapper, titleToValueMapper } = useAppSelector((store) => store.categorization);

    const qcIconsArray = [<CloseCircleFilled />, <ClockCircleFilled />, <CheckCircleFilled />];
    const initialStatCardData = [
        {
            key: 0,
            icon: <NumberOutlined />,
            data: qcDrawerData?.batch_number,
            color: "#6b6565a3",
            label: "Batch ID",
        },
        {
            key: 1,
            icon: <CalendarFilled />,
            data: moment(qcDrawerData?.batch_import).format("DD-MM-YYYY hh:mm A"),
            color: "#F7A598",
            label: "Import Date",
        },
        {
            key: 2,
            icon: qcIconsArray[qcBatchStatusIcons?.[qcDrawerData?.batch_status]] || <></>,
            data: qcDrawerData?.batch_status,
            avatarColor: qcBatchStatusAvatar?.[qcDrawerData?.batch_status],
            color: qcBatchStatus?.[qcDrawerData?.batch_status],
            label: "Status",
        }
    ];

    const [recordsData, setRecordData] = useState<any[]>([]);
    const [statCardData, setStatCardData] = useState<any[]>([]);

    useEffect(() => {
        if (!qcDrawerData) return;
        onDrawerOpen();
        setStatCardData(initialStatCardData);
    }, [qcDrawerData])

    const onDrawerOpen = async () => {
        const id = qcDrawerData?.batch_number;
        const status = qcDrawerData?.batch_status;
        const data = qcDrawerData?.batch_data;
        let tempRecordsData: any = [];
        let allQcRecordData: any = []
        if (status === "In Progress" || status === "Qc Pending" || status === "Not Assigned" || status === "QC Not Required") {
            tempRecordsData.push({ title: "Records Imported", selectedRecordsIds: data, categorisation: false });
        }
        else {
            const res = await auditlogs.getQcBatchAuditLogs(id);
            setStatCardData([...initialStatCardData, {
                key: 3,
                icon: <DatabaseFilled />,
                data: moment(res?.createdAt).utcOffset("+05:30").format("DD-MMM-YYYY hh:mm A") + ` GMT +5:30`,
                color: "#83C8E4",
                label: "Processing Date",
            }])
            Object.keys(res?.valueAfter)?.forEach((categorisationName: any) => {
                const selectedRecordsIds = res.valueAfter[categorisationName]?.selectedRecordsIds;
                const notSelectedRecordsIds = res.valueAfter[categorisationName]?.notSelectedRecordsIds;
                const selectedRecordsData = selectedRecordsIds.map((id: string) => {
                    return data?.find((record: any) => record.id === id)
                });
                const notSelectedRecordsData = notSelectedRecordsIds.map((id: string) => {
                    return data?.find((record: any) => record.id === id)
                });
                const percentage = (selectedRecordsData?.length / (selectedRecordsData?.length + notSelectedRecordsData?.length)) * 100;
                tempRecordsData.push({ title: categorisationName, categorisation: true, selectedRecordsIds: selectedRecordsData, notSelectedRecordsIds: notSelectedRecordsData, percentage: percentage || 0 });
                allQcRecordData.push(...selectedRecordsIds, ...notSelectedRecordsIds)
            });
            allQcRecordData = allQcRecordData && allQcRecordData.filter((val: string, i: number) => allQcRecordData.indexOf(val) === i)
            let allNonSelectedQcRecords: string[] = [];
            allNonSelectedQcRecords = data.filter((record: any) => !allQcRecordData.includes(record.id));
            if (allNonSelectedQcRecords.length) {
                tempRecordsData.push({ title: "Records Not Applicable For Quality Check", categorisation: false, selectedRecordsIds: allNonSelectedQcRecords, percentage: allNonSelectedQcRecords.length * 100 / data.length || 0 })
            }
        }
        setRecordData(tempRecordsData);
    }

    const downloadBatchdata = async () => {
        const payload = {
            batchData: qcDrawerData?.batch_data,
            batchNumber: qcDrawerData?.batch_number,
            importDate: qcDrawerData?.batch_import,
            status: qcDrawerData?.batch_status,
        }
        const uniview = new UniviewService("import-engine", "generatepdf", API_URL);
        const res = await uniview.generatePdf(
            {},
            {},
            "Batch Record Details",
            "qcBatchAuditLogs",
            payload
        );
        if (res.statusCode === 200) {
            ReNotification({
                description: "File Downloaded Successfully.",
                placement: "bottomLeft",
                type: "success",
            });
        } else {
            ReNotification({
                description: "Error in File Download.",
                placement: "bottomLeft",
                type: "error",
            });
        }
    }

    return (
        <ReDrawer
            width={520}
            title={
                <div className='heading' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>Batch Records Detail</div>
                    {qcDrawerData?.batch_status === "Qc In Progress" || qcDrawerData?.batch_status === "Completed" ?
                        <Button type='primary' onClick={downloadBatchdata}>Download Data</Button>
                        : <></>}
                </div>
            }
            visibility={visibility}
            onCancel={() => { setVisibility(false) }}
        >
            <Styles.Container>
                {
                    statCardData?.map(info => (
                        <Card
                            style={{ marginBottom: "0.8rem", }}
                            bodyStyle={{
                                padding: "0.5rem",
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                                borderLeft: `5px solid ${info.color || "black"}`,
                            }}
                        >
                            <div style={{ backgroundColor: info.avatarColor || info.color, fontSize: "1.5rem", color: "whitesmoke", width: "50px", height: "50px", borderRadius: "100%", display: "grid", placeItems: "center" }}>{info.icon}</div>
                            <div>
                                <h3>{info.label}</h3>
                                <p style={{ margin: 0 }}>{info.data}</p>
                            </div>
                        </Card>
                    ))
                }
                {recordsData?.length ? recordsData?.map((section: any) => {
                    return (
                        <Badge.Ribbon text={section?.percentage || section?.percentage === 0 ? `${formatNumberDecimal(section?.percentage)} %` : `${section?.selectedRecordsIds?.length}`} color={valueToTitleMapper[titleToValueMapper?.[section?.title]]?.color || "#FF4D4F"}>
                            <Card title={section?.title} style={{ marginTop: "10px" }}>
                                {section?.selectedRecordsIds?.length ?
                                    <>
                                        {section?.categorisation &&
                                            <p>
                                                <b>Records Selected:</b> {section?.selectedRecordsIds?.length > 20 &&
                                                    <>{section?.selectedRecordsIds?.length}</>}
                                            </p>
                                        }
                                        {!section?.categorisation || section?.selectedRecordsIds?.length < 20 ?
                                            <div className="record-container">
                                                {section?.selectedRecordsIds?.length ?
                                                    sortRecordsBasedOnIds(section?.selectedRecordsIds)?.map((record: any) =>
                                                        <Link to={`/view-record/${record?.id}`} target="_blank">
                                                            <Tag style={{ marginBottom: '7px' }} color={StageMappingByValue(record?.stage)?.color}>{record?.id}</Tag>
                                                        </Link>
                                                    )
                                                    :
                                                    <>No Records Selected</>
                                                }
                                            </div>
                                            :
                                            <></>
                                        }
                                        {section?.categorisation &&
                                            <>
                                                <p style={{ marginTop: '5px' }}>
                                                    <b>Records Not Selected:</b> {section?.notSelectedRecordsIds?.length > 20 && <>{section?.notSelectedRecordsIds?.length}</>}
                                                </p>
                                                {section?.notSelectedRecordsIds?.length < 20 ?
                                                    <div className="record-container">
                                                        {section?.notSelectedRecordsIds?.length ?
                                                            sortRecordsBasedOnIds(section?.notSelectedRecordsIds)?.map((record: any) =>
                                                                <Link to={`/view-record/${record?.id}`} target="_blank">
                                                                    <Tag style={{ marginBottom: '7px' }} color={StageMappingByValue(record?.stage)?.color}>{record?.id}</Tag>
                                                                </Link>
                                                            )
                                                            :
                                                            <>All Records Were Selected</>
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        }
                                    </>
                                    :
                                    <div className='no-record-error'>
                                        {/* <div className="icon"><CloseSquareFilled /></div> */}
                                        <div>No record found with {section?.title}</div>
                                    </div>
                                }
                            </Card>
                        </Badge.Ribbon>
                    )
                }) : <></>}
            </Styles.Container>
        </ReDrawer>
    )
}

export default BatchDetailDrawer