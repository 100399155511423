import { Header, SideMenu } from "../../../Components";
import CountriesTable from "../../../Components/ControlledVocabularies/Countries/CountriesTable";
import * as Styles from "./CountriesStyle";

function Countries() {
    
  return (
    <div className="container">
    <Header />
    <div className="page-wrapper">
      <SideMenu />
      <Styles.SubContainer className="subContainer">
        <CountriesTable/>
      </Styles.SubContainer>
    </div>
  </div>
  );
}

export default Countries;
