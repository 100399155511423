import { Button, Form } from "antd";
import { Header, SideMenu } from "../../Components";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { useEffect, useState } from "react";
import { API_URL } from "../../API/Config";
import moment from "moment";
import {
  StatusDropDown,
  columnData
} from "./data";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import { CasesDrawer } from "../../Components/Records/CasesDrawer/CasesDrawer";
import { importEngine } from "../../API/ImportEngine";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addCurrentAccessingCase } from "../../slices/Record/RecordSlices";
import { StatusValueToEnumMapping } from "../../Healper/Mappings";
import * as Styles from "./FTPStyles";

function FTP() {
  const dispatch = useAppDispatch();
  const { valueToTitleMapper } = useAppSelector(
    (store) => store.categorization
  );
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [filter, setFilter] = useState({
    ftpRequestStatus: {
      "=": ["0", "1", "2"],
    },
    createdAt: {
      ">": moment(new Date())
        .subtract(1, "month")
        .date(new Date().getDate()),
      "<": new Date(),
    },
  });
  const [submitDisabled, setSubmitDisabled] = useState({
    ftp: false,
    translate: false,
  });
  const [allCasesDrawer, setAllCasesDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (valueToTitleMapper) {
      getRecordsData();
      form.setFieldsValue({
        dateRange: [
          moment(new Date()).subtract(1, "month").date(new Date().getDate()),
          moment(new Date()),
        ],
        ftpRequest: ["REQUIRED", "SENT", "RECEIVED"],
        translationRequest: [],
      });
      columnData[0].render = (id: string) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleViewCaseDetailClicks(id);
            }}
          >
            {id}
          </Button>
        );
      };
      columnData[3].render = (categorization: any) => {
        const newData = categorization.map((ele: number) => {
          const data = valueToTitleMapper[ele];
          return <p style={{ color: data?.color }}>{data?.text}</p>;
        });
        return newData;
      };
    }
  }, [valueToTitleMapper]);

  const handleViewCaseDetailClicks = async (id: string) => {
    const res = await importEngine.getCaseData(id);
    dispatch(addCurrentAccessingCase(res[0]));
    setAllCasesDrawer({
      visibility: true,
      type: "edit",
    });
  };

  const getRecordsData = async (filterProp?: any) => {
    if (filterProp && Object.keys(filterProp)?.length) {
      setFilter(filterProp);
      setFlag(prevValue => !prevValue);
    }
  };

  const createNewTableData = (data: any[]) => {
    const tableData = data.map((record: any) => {
      return {
        id: record?.id || "-",
        source: record?.source || "-",
        dateOfSearch: record?.dateOfSearch
          ? moment(record?.dateOfSearch).format("DD-MMM-YYYY")
          : "-",
        categorization: record?.categorization || "-",
        citation: record?.citation || "-",
        fullTextLink: record?.fullTextLink || "-",
        ftpRequestStatus: [0, 1, 2].includes(record?.ftpRequestStatus)
          ? record?.ftpRequestStatus.toString()
          : "-",
        dateFtpSent: record?.dateFtpSent && record?.dateFtpSent !== '-'
          ? moment(record?.dateFtpSent).format("DD-MMM-YYYY")
          : "-",
        dateFtpReceived: record?.dateFtpReceived && record?.dateFtpReceived !== '-'
          ? moment(record?.dateFtpReceived).format("DD-MMM-YYYY")
          : "-",
        translationRequestStatus: [0, 1, 2].includes(
          record?.translationRequestStatus
        )
          ? record?.translationRequestStatus.toString()
          : "-",
        dateTranslationSent: record?.dateTranslationSent && record?.dateTranslationSent !== '-'
          ? moment(record?.dateTranslationSent).format("DD-MMM-YYYY")
          : "-",
        dateTranslationReceived: record?.dateTranslationReceived && record?.dateTranslationReceived !== '-'
          ? moment(record?.dateTranslationReceived).format("DD-MMM-YYYY")
          : "-",
      };
    });
    return tableData;
  };

  const onChange = (values: any) => {
    if (values?.ftpRequest?.length === 0) {
      setSubmitDisabled({
        ...submitDisabled,
        ftp: true,
      });
    } else if (values?.ftpRequest?.length > 0) {
      setSubmitDisabled({
        ...submitDisabled,
        ftp: false,
      });
    } else if (values?.translationRequest?.length === 0) {
      setSubmitDisabled({
        ...submitDisabled,
        translate: true,
      });
    } else if (values?.translationRequest?.length > 0) {
      setSubmitDisabled({
        ...submitDisabled,
        translate: false,
      });
    }
  };

  const onSubmit = (values: any) => {
    let filterForAPI: any = {
      ...(values?.dateRange
        ? {
          createdAt: {
            ">": values?.dateRange[0]._d,
            "<": values?.dateRange[1]._d,
          },
        }
        : {}),
    };
    if (values?.ftpRequest?.length > 0) {
      let statusArr = values?.ftpRequest.map((value: string) => {
        return StatusValueToEnumMapping(value).toString();
      });
      filterForAPI.ftpRequestStatus = { "=": statusArr };
    }
    if (values?.translationRequest?.length > 0) {
      let statusArr = values?.translationRequest.map((value: string) => {
        return StatusValueToEnumMapping(value).toString();
      });
      filterForAPI.translationRequestStatus = { "=": statusArr };
    }
    getRecordsData(filterForAPI);
  };

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <CasesDrawer
            type={allCasesDrawer.type}
            visibility={allCasesDrawer.visibility}
            setVisibility={setAllCasesDrawer}
            width="1400"
            defaultTabId="7"
          />
          <div>
            <ReusableForm
              formInstance={form}
              onSubmit={onSubmit}
              onChange={onChange}
              className="form-container"
            >
              <div className="form">
                <ReDatePicker
                  label="Date Range"
                  name="dateRange"
                  type="rangePicker"
                  dateFormat="DD-MMM-YYYY"
                  featureDates
                  width="100%"
                />
                <ReSelect
                  label="FTP Requests"
                  name="ftpRequest"
                  items={StatusDropDown}
                  type="multiple"
                  searchable
                />
                <ReSelect
                  label="Translation Requests"
                  name="translationRequest"
                  items={StatusDropDown}
                  type="multiple"
                  searchable
                />
              </div>
              <Button
                className="submit-btn"
                type="primary"
                htmlType="submit"
                disabled={submitDisabled.ftp && submitDisabled.translate}
              >
                Search
              </Button>
            </ReusableForm>
          </div>
          <div>
            <ReTable
              showTableStats
              name="ftpTranslationRequests"
              title="FTP/Translation Requests"
              columns={columnData}
              data={tableData}
              refresh={flag}
              scroll={{
                x: 2900,
                y: 500,
              }}
              rowSelection={{
                selectedRowKeys: selectedTableRow,
                type: "checkbox",
                setSelectedRowKeys: setSelectedTableRow,
                rowKey: "id",
              }}
              uniView={{
                uniView: "import-engine",
                viewName: "ftp",
                apiUrl: API_URL,
                returnResponse: (res: any) => {
                  setTableData(createNewTableData(res.data));
                },
                option: {
                  orderBy: {
                    key: "id",
                    order: "DESC",
                  },
                  limit: 10
                },
                filter: filter,
              }}
              exportOption={{
                csv: {
                  disabled: tableData.length == 0,
                },
              }}
            />
          </div>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default FTP;
