import { createSlice } from "@reduxjs/toolkit";
import { RolesTypes } from "../../Components/Types/TableColumn.types";
import { getAllRoles } from "./Helpers/GetAllRoles";

interface RolesState {
  allRoles: RolesTypes[];
  loading: boolean;
  error: string;
}

const initialState: RolesState = {
  allRoles: [],
  loading: true,
  error: "",
};

const RolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.allRoles = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllRoles.rejected, (state, action) => {
      state.error = "Something went wrong";
      state.loading = false;
    });
  },
});

export default RolesSlice.reducer;
