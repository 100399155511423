
import { Header, SideMenu } from "../../../Components";
import DosageFormTable from "../../../Components/ControlledVocabularies/DosageFormTable/DosageFormTable";


import * as Styles from "./DosageFormStyles";

function DosageForms() {
 
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <DosageFormTable />
          </Styles.SubContainer>
      </div>
    </div>
  );
}

export default DosageForms;
