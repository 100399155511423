import styled from "styled-components";

export const RemoveSection = styled.div`
  display: grid;
  grid-template-columns: 20fr 1fr;
  column-gap: 10px;
  align-items: baseline;
`;

export const AddAnotherSection = styled.div`
  display: flex;
  justify-content: end;
`;

export const SubObject = styled.div``;