import styled from "styled-components";

export const Container = styled.div`
  width: 35rem;
  background-color: #ffffff;
  padding: 1rem;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Body = styled.div`
  margin-top: 1rem;
  .bodyForm {
    display: grid;
    grid-auto-flow: row;
    gap: 2rem;
  }
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0;
  }
`;
export const Right = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* column-gap: 1rem; */
  .formItem {
    margin: 5px;
    h4{
      margin: 0;
      color: gray;
    }
    p {
      margin: 0;
    }
  }
`;
