import { createAsyncThunk } from "@reduxjs/toolkit";
import { importEngine } from "../../../API/ImportEngine";

const GetCategorizationStats = createAsyncThunk(
    "dashboard/GetCategorizationStats",
    async () => {
        try {
            const data = await importEngine.GetCategorizationStats()
            return data;
        } catch (err) {
            console.log("error");
        }
    }
);

export { GetCategorizationStats };