export const makeFirstLetterCapital = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const makeDecimalValueSmaller = (num: string, toFixed: number) => {
  return parseFloat(parseFloat(num).toFixed(toFixed));
};

export const convertCamelToSimpleCase = (text: string) => {
  return (
    text.charAt(0).toUpperCase() +
    text
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .trim()
  );
};

export function convertSimpleToCamelCase(str: string) {
  const words = str.toLowerCase().split(' ');

  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  return camelCaseWords.join('');
}

export const makeApiRequest = async (
  url: string,
  method: string,
  headers: any,
  payload?: any
) => {
  try {
    let body = payload;
    body = JSON.stringify(payload);
    const res = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
      credentials: "include",
    });
    const data = await res?.json();
    if (data.statusCode == 401 || data.statusCode == 403) {
      sessionStorage.setItem("unauth", "true");
      window.location.href = "/";
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const generateRandomNumbers = () => {
  return Math.floor(Math.random() * 10000000000);
};

export const modifyData = (
  arrayOfObjects: any[],
  undefinedValuesTo: string | undefined,
  nullValuesTo: string | undefined,
  emptyStringValuesTo: string | undefined
) => {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    const obj = arrayOfObjects[i];
    const keys = Object.keys(obj);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (undefinedValuesTo && obj[key] === undefined) {
        obj[key] = undefinedValuesTo;
      } else if (nullValuesTo && obj[key] === null) {
        obj[key] = nullValuesTo;
      } else if (emptyStringValuesTo && obj[key] === "") {
        obj[key] = emptyStringValuesTo;
      }
    }
  }
  return arrayOfObjects;
};

export const replaceValuesWithDash = (arr: any) => {
  return arr.map((value: any) => {
    if (value === "" || value === null || value === undefined) {
      return "-";
    }
    return value;
  });
};

interface ParsedData {
  type: "years" | "months" | "days";
  data: string;
  operation: string;
}

export const getDataFromDate = (inputString: string): ParsedData[] => {
  const parts: string[] = inputString.split('/');
  const result: ParsedData[] = [];
  parts.forEach(part => {
      const [type, value]: any[] = part.split('_');
      const [data, operation]: string[] = value.split('_');
      result.push({
          type: type,
          data: Math.abs(parseInt(data)).toString(),
          operation: operation === '+' ? 'plus' : 'minus'
      });
  });
  return result;
}

export function isDifferent(obj1: any, obj2: any): boolean {

  const isObject = (obj: any) => obj && typeof obj === 'object';

  if (!isObject(obj1) || !isObject(obj2)) {
      return obj1 !== obj2;
  }

  if (isObject(obj1) !== isObject(obj2)) {
      return true;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
      return true;
  }

  for (let key of obj1Keys) {
      if (!obj2.hasOwnProperty(key) || isDifferent(obj1[key], obj2[key])) {
          return true;
      }
  }

  return false;
}