import Styled from "styled-components";

export const Container = Styled.div``;

export const DrawerContainer = Styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: hidden;
  height: 85vh;
`;

export const InformationContainer = Styled.div`
  /* height: 100vh; */
  overflow-y:scroll;
  padding-right: 1rem;
  padding-bottom:15rem;
  ::-webkit-scrollbar {
    width: 5px;
  }
  p{
    font-size:14px;
  }
.heading{
    font-weight:500;
    font-size:14px;
    margin: 0;
    color:gray;
  }
  .heading-container{
    display: grid;
    grid-template-columns:1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
}
`;

export const FormFeilds = Styled.div`
  display: flex;
  flex-direction:column;
  .translationIcon{
    font-size: 1rem;
    padding-left: 0.5rem;
  }
  .tags{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .attachemnt-container{
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    .attachemnt-card{
      text-align: center;
      .attachemnt-name{
        font-size: 12px;
      }
    }
  }
`;
export const FormContainer = Styled.div`
  height: 89vh;
  overflow:scroll;
  padding-bottom:3rem;
`;
