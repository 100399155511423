import { useEffect, useState } from 'react'
import ReTable from '../../../unity-ui-common/Components/ReTable/ReTable'
import * as Styles from "./AllRecordsStyles";
import { Tag } from 'antd';

function ExportXMLErrorTable({ errorObj }: any) {

    const [tableData, setTableData] = useState([]);

    const columnData = [
        {
            title: "Field Name",
            dataIndex: "keyValue",
            key: "keyValue",
            width: 250,
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            width: 400,
        },
        {
            title: "Stored Value",
            dataIndex: "currentValue",
            key: "currentValue",
            width: 250,
            render: (value: any) => {
                if (value === null || value === undefined) {
                    return <Tag>Null</Tag>;
                }
                else if (value === '') {
                    return "--";
                }
                else {
                    return value;
                }
            }
        },
    ];

    useEffect(() => {
        if (Object.keys(errorObj)?.length) {
            setTableData(errorObj.data);
        }
    }, [errorObj]);

    return (
        <Styles.ModalContainer>
            <ReTable
                name="xmlError"
                columns={columnData}
                data={tableData}
                className='xmlErrorTable'
            />
        </Styles.ModalContainer>
    )
}

export default ExportXMLErrorTable;