import Styled from 'styled-components';

export const Container = Styled.div``;
export const SubContainer = Styled.div`
    width: 100%;
    .form{
        .resultTable {
            margin-top: 0.5rem;
        }
    }
    .checkBoxes{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0.5rem;
    }
    .categorization{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
    }
`;