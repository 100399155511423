import Styled from 'styled-components';

export const SubContainer = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;    
    padding-left: 0.8rem;
    .heading-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .workflow-mode-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        .workflow-selector{
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
    .ant-input-number-group-addon{
        padding: 0;
    }
    .work-allocation-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .unilist{
            background-color: white;
        }
        .list-wrapper{
            width: 90%;
            max-width: 1000px;
        }
        .list-header,
        .list-item{
            display: grid;
            grid-template-columns: 1fr 0.8fr 0.8fr;
            >*{
                display: flex;
                justify-content: center;
            }
            .minus-symbol{
                background-color: #DD2515;
            }
            .add-symbol{
                background-color: #59B259;
            }
            .heading{
                font-size: 1rem;
                font-weight: 500;
            }
            .symbol{
                cursor: pointer;
                color: white;
                width: 30px;
                outline: none;
                border: none;
                height: 30px;
                transition: 0.2s ease; /* Transition for smooth scaling animation */
            }

            .symbol:active {
                fiiter: brightness(2);
                transform: scale(0.95); /* Scale the button down slightly on click */
            }
        }
        .button-wrapper{
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }
    .stats-cont{
        position: fixed;
        bottom: 0.5rem;
        right: 0.5rem;
        span{
            padding-left: 0.5rem;
            font-weight: 500;
            &:first-of-type{
                padding-left: 0;
            }
        }
        .ant-scroll-number-only{
            padding: 0;
            *{
                padding: 0;
            }
        }
    }
`;
export const Tables = Styled.div``;