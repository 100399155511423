import { Space } from "antd";
import moment, { Moment } from "moment";
import ReSelect from "./ReSelect";
import { getDataFromDate } from "../../Methods/Methods";
import ReDatePicker from "./ReDatePicker";

interface IReDatePickerDropDown {
    date: [Moment | null, Moment | null] | null;
    setDate: Function;
    selectedOption: string;
    setSelectedOption: Function;
    dropDownItems: {
        value: string;
        title: string;
        disable?: boolean;
    }[];
    defaultValue: string;
    allowClear?: boolean;
}

function ReDatePickerDropDown(props: IReDatePickerDropDown) {

    const { date, setDate, selectedOption, setSelectedOption, dropDownItems, defaultValue, allowClear } = props;

    const handleDateChange = (dates: any) => {
        setDate(dates);
    };

    const handleDateFilter = (filterType: string) => {
        const currentDate = moment();
        setSelectedOption(filterType);
        let startDate: Moment | null = null;
        let endDate: Moment | null = null;
        const dateData = filterType.includes("custom") ? filterType?.split("_")[1] : getDataFromDate(filterType);
        if (Array.isArray(dateData)) {
            startDate = currentDate;
            dateData?.forEach(data => {
                if (data.operation === "minus") {
                    startDate = moment(startDate).subtract(data.data, data.type);
                }
                else if (data.operation === "plus") {
                    startDate = moment(startDate).add(data.data, data.type);
                }
            })
            endDate = currentDate;
        }
        else if (typeof dateData === "string") {
            switch (dateData) {
                case "quarter":
                    endDate = currentDate.subtract(1, "quarter").endOf("quarter");
                    startDate = endDate.clone().startOf("quarter");
                    break;
                default:
                    break;
            }
        }
        setDate([startDate, endDate]);
    };

    return (
        <Space direction="horizontal">
            <ReSelect
                items={dropDownItems}
                label=""
                name="selectForRangePicker"
                onChange={handleDateFilter}
                value={selectedOption}
                defaultValue={defaultValue}
                style={{
                    width: "150px"
                }}
            />
            <ReDatePicker
                label=""
                type="rangePicker"
                onChange={handleDateChange}
                value={date}
                disabledDate={(current: any) =>
                    current && current > moment().endOf("day")
                }
                allowClear={allowClear || false}
                disable={selectedOption !== "custom"}
            />
        </Space>
    );
}

export default ReDatePickerDropDown;
