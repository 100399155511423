import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";

const getAllDme = createAsyncThunk(
  "controlledVocabularies/getAllDme",
  async () => {
    try {
      const data = await controlledVocabularies.getManyDME()
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllDme };
