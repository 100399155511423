import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReCheckBox from "../../../unity-ui-common/Components/ReFormFields/ReCheckbox";
import ReDatePicker from "../../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReUpload from "../../../unity-ui-common/Components/ReFormFields/ReUpload";
import { Form, Select } from "antd";
import { useState, useEffect } from "react";
import { caseTypes, sourceTypes } from "./Constant";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllCountries } from "../../../slices/ControlledVocabularies/Helpers/getAllCountries";
import { file } from "../../../API/File";
import { importEngine } from "../../../API/ImportEngine";
import { convertDateInReadableFormat } from "../../../Healper/Methods";
import { tenant } from "../../../API/UserManagement/Tenant";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";
import * as Styles from "./ImportDataFormStyles";

function ImportDataForm() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm(); // form instance
  const { allCountries } = useAppSelector(
    (store) => store.controlledVocabularies
  );

  const [caseTypeValue, setCaseTypeValue] = useState<number>(0);
  const [sourceTypeValue, setSourceTypeValue] = useState<number>(0);
  const [searchFileList, setSearchFileList] = useState<any>([]);
  const [searchFileData, setSearchFileData] = useState<any>({});
  const [evidanceFileList, setEvidanceFileList] = useState<any>([]);
  const [evidanceFileData, setEvidanceFileData] = useState<any>({});
  const [currentWorkspace, setCurrentWorkspace] = useState<any>();

  const handleFormChanges = (changedValues: any, allValues: any) => {
    // console.log(allValues);
  };

  const handleFormSubmit = async (values: any) => {
    let res: any;

    values["tenantId"] = currentWorkspace.id;
    if (values.searchFile) {
      values.sourceFile = searchFileData;
      delete values.searchFile;
    }
    if (values.evidanceFile) {
      values.evidanceFile = evidanceFileData;
    }
    if (values.dateOfSearch) {
      values.dateOfSearch = convertDateInReadableFormat(values.dateOfSearch._d);
    }

    if (sourceTypeValue !== 2) {
      // when case is emabse and pubmed
      const source = sourceTypeValue === 0 ? "pubmed" : "embase";
      res = await importEngine.importRecords(source, values);
    } else {
      // when case is local literature
      res = await importEngine.importRecords("localLiterature", values);
    }

    if (res.status) {
      ReNotification({
        header: "Import Data",
        description: res.message,
        type: "success",
      });
    } else {
      ReNotification({
        header: "Import Data",
        description: res.message,
        type: "error",
      });
    }
    setUploadDataEmpty();
    window.location.reload();
  };

  const setUploadDataEmpty = () => {
    setEvidanceFileData({});
    setEvidanceFileList([]);
    setSearchFileData({});
    setSearchFileList([]);
  };

  // search file
  const onBeforeSearchFileUpload = async (fileData: any) => {
    const filenameArr = fileData["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    setSearchFileList([fileData]);
    await file.remoteUploadFile(res.url, fileData);

    setSearchFileData({
      fileUID: fileData.uid,
      id: res.id,
      filename: fileName,
      file_type,
    });
  };
  const handleSearchFileRemove = (fileData: any) => {
    const index = searchFileList.indexOf(fileData);
    const newFileList = searchFileList.slice();
    newFileList.splice(index, 1);
    setSearchFileList(newFileList);
    setSearchFileData(null);
  };

  // evidance file
  const onBeforeEvidanceFileUpload = async (fileData: any) => {
    const filenameArr = fileData["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    setEvidanceFileList([fileData]);
    await file.remoteUploadFile(res.url, fileData);

    setEvidanceFileData({
      fileUID: fileData.uid,
      id: res.id,
      filename: fileName,
      file_type,
    });
  };
  const handleEvidanceFileRemove = (fileData: any) => {
    const index = evidanceFileList.indexOf(fileData);
    const newFileList = evidanceFileList.slice();
    newFileList.splice(index, 1);
    setEvidanceFileList(newFileList);
    setEvidanceFileData(null);
  };

  const getTenantData = async (id: string) => {
    const res = await tenant.getTenant(id);
    form.setFieldsValue({
      translation: res?.translate,
      ai: res?.aiCategorization,
    });
  };

  useEffect(() => {
    dispatch(getAllCountries());
    setCurrentWorkspace(
      JSON.parse(sessionStorage.getItem("selectedWorkSpace") || "")
    );
  }, []);

  useEffect(() => {
    if (currentWorkspace) {
      getTenantData(currentWorkspace?.id);
    }
  }, [currentWorkspace]);

  return (
    <Styles.Container>
      <Styles.Controls>
        <Select
          className="control"
          defaultValue={caseTypeValue}
          onChange={(value: any) => {
            setCaseTypeValue(value);
          }}
        >
          {caseTypes.map((ca: any) => {
            return <Select.Option value={ca.value}>{ca.title}</Select.Option>;
          })}
        </Select>
        <Select
          className="control"
          defaultValue={sourceTypeValue}
          onChange={(value: any) => {
            setSourceTypeValue(value);
            setUploadDataEmpty();
          }}
        >
          {sourceTypes.map((ca: any) => {
            return <Select.Option value={ca.value}>{ca.title}</Select.Option>;
          })}
        </Select>
      </Styles.Controls>
      <Styles.Form>
        <ReusableForm
          formInstance={form}
          onChange={handleFormChanges}
          onSubmit={handleFormSubmit}
          submitBtn
          submitBtnText="Import"
          formStyles={{ padding: "1rem" }}
        >
          {sourceTypeValue !== 2 && (
            <div className="twoColumn">
              <div className="twoColumn">
                <ReCheckBox label="Translation" name="translation" disable />
                <ReCheckBox label="AI Categorization" name="ai" disable />
              </div>
              <ReDatePicker
                label="Day 0"
                name="dateOfSearch"
                dateFormat="DD-MMM-YYYY"
                featureDates
                type="simple"
                width="100%"
                required
              />
              <div className="twoColumn">
                <div>
                  <ReUpload
                    errorMessage="Please Enter The Source File"
                    label="Import Type"
                    accept={sourceTypeValue === 0 ? ".txt" : ".csv"}
                    BtnTitle="Import File"
                    name="searchFile"
                    required
                    onBeforeUpload={onBeforeSearchFileUpload}
                    onRemove={handleSearchFileRemove}
                    fileListMaxCount={1}
                    fileList={searchFileList}
                  />
                  <div className="fileType">
                    File Type: {sourceTypeValue === 0 ? ".txt" : ".csv"}
                  </div>
                </div>
                <div>
                  <ReUpload
                    errorMessage="Please Enter The Evidence File"
                    label="Source"
                    accept=".pdf"
                    BtnTitle="Other Attachments"
                    name="evidanceFile"
                    onBeforeUpload={onBeforeEvidanceFileUpload}
                    onRemove={handleEvidanceFileRemove}
                    fileListMaxCount={1}
                    fileList={evidanceFileList}
                  />
                  <div className="fileType">File Type: .pdf</div>
                </div>
              </div>
              <ReInput
                label="Search Strategy"
                name="searchStrategy"
                type="textArea"
              />
            </div>
          )}
          {sourceTypeValue === 2 && (
            <div className="twoColumn sourceTypeValue2">
              <ReCheckBox label="Translation" name="translation" disable />
              <ReCheckBox label="AI Categorization" name="ai" disable />
              <ReDatePicker
                label="Day 0"
                name="dateOfSearch"
                required
                dateFormat="DD-MMM-YYYY"
                featureDates
                type="simple"
                width="100%"
              />
              <ReInput label="Author" name="authors" required type="simple" />
              <ReInput
                label="Publication Citation"
                name="publicationCitation"
                required
                type="simple"
              />
              <ReInput
                label="Publication ID"
                name="publicationID"
                type="simple"
              />
              <ReInput
                label="Publication Title"
                name="publicationTitle"
                type="simple"
                required
              />
              <ReSelect
                label="Country of Origin"
                name="countryOfOrigin"
                required
                searchable
                items={allCountries?.map((cou: any) => {
                  return {
                    value: cou.name,
                    title: cou.name,
                  };
                })}
              />
              <ReInput label="Full Text Link" name="fullTextLink" type="url" />
              <div>
                <ReUpload
                  errorMessage="Please Enter Your Evidence File"
                  accept=".pdf"
                  label="Source"
                  BtnTitle="Other Attachments"
                  name="evidanceFile"
                  onBeforeUpload={onBeforeEvidanceFileUpload}
                  onRemove={handleEvidanceFileRemove}
                  fileListMaxCount={1}
                  fileList={evidanceFileList}
                />
                <div className="fileType">File Type: .pdf</div>
              </div>
              <ReInput
                label="Abstract"
                name="abstract"
                type="textArea"
                textAreaOptions={{
                  rowSize: 10,
                  textAreaResize: true,
                }}
              />
            </div>
          )}
        </ReusableForm>
      </Styles.Form>
    </Styles.Container>
  );
}

export default ImportDataForm;
